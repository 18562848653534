import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '@/axios'
import config from '@/constant/config'
import { toResponse } from '@/utils/common'

const { api } = config

export const getCartItems = createAsyncThunk(
  'cart/getCartItems',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(api.cart.base)
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const getCartItem = createAsyncThunk(
  'cart/getCartItem',
  async (sku, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(api.cart.detail(sku))
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const countCartItems = createAsyncThunk(
  'cart/countCartItems',
  async (_, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axios.get(api.cart.count)
      dispatch(setCount(data.data))
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const addToCart = createAsyncThunk(
  'cart/addToCart',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(api.cart.base, body)
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const updateQuantity = createAsyncThunk(
  'cart/updateQuantity',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axios.put(api.cart.base, body)
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const updateDownPayment = createAsyncThunk(
  'cart/updateDownPayment',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axios.put(api.cart.setDownPayment, body)
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const deleteFromCart = createAsyncThunk(
  'cart/deleteFromCart',
  async (skus, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete(api.cart.base, {
        data: { skus }
      })
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const cartSlice = createSlice({
  name: 'cart',
  initialState: {
    count: 0
  },
  reducers: {
    setCount(state, actions) {
      state.count = actions.payload
    }
  }
})

export const countSelector = state => state.cart.count

export const { setCount } = cartSlice.actions

export default cartSlice.reducer