import React, { useState } from 'react'
import logo from '@/assets/logo.png'
import { AiOutlineShoppingCart } from 'react-icons/ai'
import { IoMdSearch } from 'react-icons/io'
import { useNavigate, createSearchParams } from 'react-router-dom'
import { countSelector } from '@/store/slices/cart'
import { useSelector } from 'react-redux'
import config from '@/constant/config'
import useResponsive from '@/hooks/responsive'

const { path } = config

const BaseHeader = ({ searchKeywordState = [], children }) => {
  const navigate = useNavigate()

  const [keywordProp, setKeywordProp] = searchKeywordState

  const { isDesktop } = useResponsive()
  const cartCount = useSelector(countSelector)
  const [searchKeyword, setSearchKeyword] = useState(keywordProp ? keywordProp : '')

  const onKeyDown = e => {
    if (e.key !== 'Enter') return

    if (setKeywordProp) {
      setKeywordProp(searchKeyword)
      return
    }

    if (searchKeyword) {
      navigate({
        pathname: path.search,
        search: createSearchParams({
          search: searchKeyword
        }).toString()
      })
    }
  }

  return (
    <section className="sticky top-0 z-20">
      <div className="relative px-5 py-3 flex justify-between items-center bg-yellow-multi lg:py-4 lg:justify-start">
        {children}
 
        <img
          src={logo}
          alt="logo"
          className="w-16 lg:w-20 lg:ml-10 lg:cursor-pointer"
          onClick={() => navigate(path.base)}
        />

        {
          isDesktop && (
            <div className="w-1/3 px-2 py-1.5 border border-black-multi flex items-center ml-auto">
              <IoMdSearch className="text-2xl" />
              <input
                type="text"
                placeholder="Search for products"
                className="bg-yellow-multi outline-none text-sm ml-1.5 w-full placeholder-gray-multi"
                value={searchKeyword}
                onChange={e => setSearchKeyword(e.target.value)}
                onKeyDown={onKeyDown}
              />
            </div>
          )
        }

        <button
          type="button"
          className="relative lg:ml-auto"
          onClick={() => navigate(path.cart)}
        >
          <AiOutlineShoppingCart className="text-xl lg:text-2xl" />
          {
            !!cartCount && (
              <div className="absolute -right-1 -bottom-1 h-3 w-3 lg:h-3.5 lg:w-3.5 bg-red-multi rounded-full text-white-primary text-[8.5px] flex justify-center items-center">
                {cartCount}
              </div>
            )
          }
        </button>
      </div>

      <div className="w-full h-[1.2px] bg-black-multi" />
    </section>
  )
}

export default BaseHeader