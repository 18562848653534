import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '@/axios'
import config from '@/constant/config'
import { toResponse } from '@/utils/common'

const { api } = config

export const subscribeNewsletter = createAsyncThunk(
  'newsletter/subscribeNewsletter',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(api.newsletter.subscribe, body)
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const newsletterSlice = createSlice({
  name: 'newsletter',
  initialState: {},
  reducers: {}
})

export default newsletterSlice.reducer