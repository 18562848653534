import React, { useEffect, useState } from 'react'
import { HiPencilAlt } from 'react-icons/hi'
import { FaTimes, FaCheck } from 'react-icons/fa'
import { AiFillCloseCircle } from 'react-icons/ai'
import { getWishlistProducts, deleteWishlist } from '@/store/slices/wishlist'
import { addToCart, countCartItems } from '@/store/slices/cart'
import { useDispatch } from 'react-redux'
import toast from 'react-hot-toast'
import InfiniteScroll from 'react-infinite-scroll-component'
import { motion, AnimatePresence } from 'framer-motion'
import useLoader from '@/hooks/loader'
import useAuthentication from '@/hooks/authentication'
import useResponsive from '@/hooks/responsive'

import TitleHeader from '@/components/TitleHeader'
import ProductCard from '@/components/ProductCard'
import ProductCardLoader from '@/components/ProductCardLoader'
import GeneralHeader from '@/components/GeneralHeader'

const WishlistPage = () => {
  const dispatch = useDispatch()

  const { isDesktop } = useResponsive()

  const [products, setProducts] = useState([])
  const [productPage, setProductPage] = useState({})
  const [isFetchingProduct, setIsFetchingProduct] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [selectedProducts, setSelectedProducts] = useState([])

  const { unauthorized } = useAuthentication()
  const [, setIsLoading] = useLoader()

  useEffect(() => {
    fetchWishlistProducts(1, false)
  }, [])

  useEffect(() => {
    if (!isEditing) {
      setSelectedProducts([])
    }
  }, [isEditing])

  const fetchWishlistProducts = async (page, append = true) => {
    try {
      setIsFetchingProduct(true)

      const { data, paging } = await dispatch(getWishlistProducts({
        page,
        size: isDesktop ? 12 : 10
      })).unwrap()

      if (append) {
        setProducts([...products, ...data])
      } else {
        setProducts(data)
      }

      setProductPage(paging)

      setIsFetchingProduct(false)
    } catch(error) {
      unauthorized(error, () => {
        toast.error('Something went wrong, please try again')
      })
    }
  }

  const productLoader = () => {
    const size = isDesktop ? 12 : 4
    return [...new Array(size)].map((_, i) => <ProductCardLoader key={i} />)
  }

  const handleSelectedProductsChange = sku => {
    if (selectedProducts.some(p => p === sku)) {
      setSelectedProducts(selectedProducts.filter(p => p !== sku))
    } else {
      setSelectedProducts([
        ...selectedProducts,
        sku
      ])
    }
  }

  const onClickProduct = () => {
    if (isEditing) {
      return handleSelectedProductsChange
    }
    return null
  }

  const removeFromWishlist = async () => {
    if (!selectedProducts.length) {
      setIsEditing(false)
      return
    }

    try {
      setIsLoading(true)

      await dispatch(deleteWishlist(selectedProducts))
      setIsEditing(false)
      setIsLoading(false)

      toast.success('Products removed from wishlist')

      setProducts([])
      await fetchWishlistProducts(1, false)

    } catch(error) {
      unauthorized(error, () => {
        setIsLoading(false)
        toast.error('Something went wrong, please try again')
      })
    }
  }

  const doAddToCart = async () => {
    if (!selectedProducts.length) {
      setIsEditing(false)
      return
    }

    try {
      setIsLoading(true)

      await dispatch(addToCart({
        items: selectedProducts.map(sku => ({
          sku,
          quantity: 1
        }))
      })).unwrap()

      await dispatch(deleteWishlist(selectedProducts))

      setIsEditing(false)

      setProducts([])
      await fetchWishlistProducts(1, false)

      setIsLoading(false)

      toast.success('Products added to cart')

      await dispatch(countCartItems()).unwrap()
    } catch(error) {
      unauthorized(error, () => {
        setIsLoading(false)
        toast.error('Something went wrong, please try again')
      })
    }
  }

  return (
    <div className="flex flex-col min-h-screen">
      {
        isDesktop ? (
          <GeneralHeader />
        ) : (
          <TitleHeader
            title="Wishlist"
            slot={() => !!products.length && (
              <button
                type="button"
                className="ml-auto text-lg"
                onClick={() => setIsEditing(prev => !prev)}
              >
                {
                  isEditing ? (
                    <FaTimes />
                  ) : (
                    <HiPencilAlt />
                  )
                }
              </button>
            )}
          />
        )
      }

      <section className="bg-white-multi grow p-5 relative flex flex-col lg:py-8 lg:px-20">
        {
          isDesktop && (
            <div className="flex items-center justify-between mb-6">
              <div className="font-semibold text-xl">
                Wishlist
              </div>

              {
                isEditing ? (
                  <div className="flex gap-x-3 w-2/5 xl:w-1/3">
                    <button
                      type="button"
                      className="button-secondary"
                      onClick={() => setIsEditing(prev => !prev)}
                    >
                      Finish Editing
                    </button>
                    <button
                      type="button"
                      className="button-primary dark"
                      onClick={doAddToCart}
                    >
                      Add to Cart
                    </button>
                    <button
                      type="button"
                      className="button-primary"
                      onClick={removeFromWishlist}
                    >
                      Remove
                    </button>
                  </div>
                ) : (
                  <button
                    type="button"
                    className="flex items-center gap-x-3"
                    onClick={() => setIsEditing(prev => !prev)}
                  >
                    <HiPencilAlt />
                    Edit Wishlist
                  </button>
                )
              }
            </div>
          )
        }

        {
          !isFetchingProduct && !products.length && (
            <div className="flex flex-col justify-center items-center grow">
              <AiFillCloseCircle className="text-[7rem] text-gray-multi mb-2" />
              <div>
                You don't have any items in your wishlist
              </div>
            </div>
          )
        }

        {
          !isFetchingProduct && !!products.length && (
            <InfiniteScroll
              className="product-container"
              dataLength={products.length}
              next={() => fetchWishlistProducts(productPage.page + 1 || 1)}
              hasMore={products.length < productPage.totalItem}
              loader={productLoader()}
            >
              {
                products.map(product => (
                  <div
                    key={product.sku}
                    className="relative"
                  >
                    <ProductCard
                      id={product.sku}
                      name={product.displayName}
                      brand={product.brand}
                      price={product.price.offer}
                      originalPrice={product.price.original}
                      images={product.images}
                      availability={product.availability}
                      stock={product.stock}
                      hidden={product.hidden}
                      onClick={onClickProduct()}
                    />

                    {
                      selectedProducts.some(p => p === product.sku) && (
                        <div
                          className="absolute top-0 left-0 w-full h-full bg-yellow-multi bg-opacity-50 flex justify-center items-center"
                          onClick={() => handleSelectedProductsChange(product.sku)}
                        >
                          <FaCheck className="text-[5rem] text-gray-multi" />
                        </div>
                      )
                    }
                  </div>
                ))
              }
            </InfiniteScroll>
          )
        }
      </section>

      <AnimatePresence mode="wait">
        {
          !isDesktop && isEditing && (
            <motion.section
              className="sticky bottom-0 left-0 p-4 flex gap-x-3 bg-white-multi border-t border-black-multi"
              animate={{
                y: 0,
                opacity: 1
              }}
              initial={{
                y: 30,
                opacity: 0
              }}
              exit={{
                y: 30,
                opacity: 0
              }}
              transition={{

              }}
            >
              <button
                type="button"
                className="button-primary dark"
                onClick={doAddToCart}
              >
                Add to Cart
              </button>
              <button
                type="button"
                className="button-primary"
                onClick={removeFromWishlist}
              >
                Remove
              </button>
            </motion.section>
          )
        }
      </AnimatePresence>
    </div>
  )
}

export default WishlistPage