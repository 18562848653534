import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { FaFacebookSquare } from 'react-icons/fa'
import { BsGoogle, BsInfoCircleFill } from 'react-icons/bs'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { login, setUser, loginInfo } from '@/store/slices/user'
import { countCartItems } from '@/store/slices/cart'
import config from '@/constant/config'
import toast from 'react-hot-toast'
import useLoader from '@/hooks/loader'
import useResponsive from '@/hooks/responsive'
import loginIllustration from '@/assets/login-illustration.png'

import GeneralHeader from '@/components/GeneralHeader'
import TitleHeader from '@/components/TitleHeader'
import Footer from '@/components/Footer'

const { path, api } = config

const validationSchema = yup.object().shape({
  email: yup.string().email().required(),
  password: yup.string().required()
})

const LoginPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()
  const [, setIsLoading] = useLoader()

  const { isDesktop } = useResponsive()

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  })

  useEffect(() => {
    const getLoginInfo = async () => {
      try {
        await dispatch(loginInfo()).unwrap()
        navigate(path.base)
      } catch(err) {
      }
    }
    getLoginInfo()
  }, [])

  const getCartItemsCount = async () => {
    await dispatch(countCartItems()).unwrap()
  }

  const createPasswordToast = () => {
    const toastId = toast.custom((t) => (
      <div
        className={`${
          t.visible ? 'animate-enter' : 'animate-leave'
        } max-w-md w-full bg-white-multi border border-black-multi text-xs pointer-events-auto p-3 flex items-center`}
      >
        <BsInfoCircleFill className="text-2xl text-blue-500 mr-3" />
        <div>
          Your account doesn't have a password yet. Please create one by clicking
          <button
            type="button"
            className="ml-1 underline text-blue-500"
            onClick={() => {
              navigate(path.verifyResetPassword)
              toast.dismiss(toastId)
            }}
          >
            here
          </button>
        </div>
      </div>
    ), {
      duration: 6000,
      className: 'text-xs',
      icon: <BsInfoCircleFill className="text-lg text-blue-500" /> 
    })
  }

  const doLogin = async ({ email, password }) => {
    try {
      setIsLoading(true)

      await dispatch(login({ email, password })).unwrap()
      const { data } = await dispatch(loginInfo()).unwrap()

      await getCartItemsCount()

      dispatch(setUser(data))

      setIsLoading(false)

      const page = searchParams.get('page')
      navigate(page ? window.atob(page) : path.base)
    } catch(err) {
      setIsLoading(false)
      if (err.data.error === 'NO_PASSWORD_YET') {
        createPasswordToast()
        return
      }
      if (err.status === 401) {
        toast.error('Invalid username or password')
        return
      }
      toast.error('Something went wrong, please try again')
    }
  }

  const doGoogleLogin = async () => {
    try {
      window.open(api.googleLogin, '_self')
    } catch(error) {
    }
  }

  const doFacebookLogin = async () => {
    try {
      window.open(api.facebookLogin, '_self')
    } catch(error) {
    }
  }

  return (
    <div className="flex flex-col h-screen">
      {
        isDesktop ? (
          <GeneralHeader />
        ) : (
          <TitleHeader
            title="Log In"
            backUrl={path.base}
          />
        )
      }

      <section className="bg-white-multi grow flex">
        {
          isDesktop && (
            <div className="w-1/2 flex justify-center items-center">
              <img
                src={loginIllustration}
                className="w-96"
                alt="illustration"
              />
            </div>
          )
        }

        <form
          onSubmit={handleSubmit(doLogin)}
          className="w-full p-5 flex flex-col h-full lg:py-8 lg:px-20 lg:w-1/2"
        >
          {
            isDesktop && (
              <div className="lg:text-2xl lg:font-semibold lg:mb-8">
                Log In
              </div>
            )
          }

          <div className="mb-4">
            <div className="font-semibold mb-1.5">
              Email
            </div>
            <input
              type="text"
              placeholder="Enter your email address"
              className="input-primary"
              {...register('email')}
            />
            {
              errors.email && (
                <small className="text-red-multi italic">
                  Incorrect email
                </small>
              )
            }
          </div>

          <div>
            <div className="font-semibold mb-1.5">
              Password
            </div>
            <input
              type="password"
              placeholder="Enter your password"
              className="input-primary"
              {...register('password')}
            />
            {
              errors.password && (
                <small className="text-red-multi italic">
                  Invalid password
                </small>
              )
            }
          </div>

          <button
            className="my-2 text-left"
            type="button"
            onClick={() => navigate(path.verifyResetPassword)}
          >
            <small className="text-gray-400 underline">
              Forgot your password?
            </small>
          </button>

          {
            isDesktop && (
              <div className="mb-8 mt-4 self-start flex flex-col items-start justify-start">
                <div>
                  <button
                    type="submit"
                    className="button-primary lg:px-8"
                  >
                    Log In
                  </button>
                </div>
                <div className="text-xs justify-center mb-2 flex lg:text-sm lg:justify-start lg:mt-3 lg:mb-0">
                  Don't have account yet?
                  <button
                    className="ml-1 underline"
                    type="button"
                    onClick={() => navigate(path.register)}
                  >
                    Sign up here
                  </button>
                </div>
              </div>
            )
          }

          <div className="flex flex-col my-8">
            <div className="flex items-center">
              <div className="w-1/3 bg-black-multi" style={{ height: '1px' }} />

              <div className="text-xs w-1/3 text-center">
                Log in with
              </div>

              <div className="w-1/3 bg-black-multi" style={{ height: '1px' }} />
            </div>

            <div className="flex w-1/3 my-4 mx-auto items-center justify-evenly text-lg">
              <button
                type="button"
                onClick={doFacebookLogin}
              >
                <FaFacebookSquare />
              </button>

              <button
                type="button"
                onClick={doGoogleLogin}
              >
                <BsGoogle />
              </button>
            </div>
          </div>

          {
            !isDesktop && (
              <div className="mt-auto">
                <div className="text-xs justify-center mb-2 flex">
                  Don't have account yet?
                  <button
                    className="ml-1 underline"
                    type="button"
                    onClick={() => navigate(path.register)}
                  >
                    Sign up here
                  </button>
                </div>

                <button
                  type="submit"
                  className="button-primary"
                >
                  Log In
                </button>
              </div>
            )
          }
        </form>
      </section>

      {
        isDesktop && <Footer />
      }
    </div>
  )
}

export default LoginPage