import React from 'react'
import { numberFormatter } from '@/utils/common'
import { Link } from 'react-router-dom'
import config from '@/constant/config'

const { path } = config

const AVAILABILITY_STYLES = {
  WAITING_LIST: {
    text: 'Waiting List',
    background: 'bg-blue-multi'
  },
  READY_STOCK: {
    text: 'Ready Stock',
    background: 'bg-green-multi'
  },
  PREORDER: {
    text: 'Pre-order',
    background: 'bg-orange-multi'
  },
  LATE_PREORDER: {
    text: 'Late Pre-order',
    background: 'bg-red-multi'
  }
}

const ProductCard = ({
  id,
  name,
  brand,
  images,
  price,
  originalPrice,
  availability,
  stock,
  hidden,
  onClick,
  className
}) => {

  const pdpUrl = () => {
    const encodedName = encodeURIComponent(name)
    return path.product.replace(':name', encodedName)
      .replace(':sku', id)
  }

  const isOutOfStock = () => {
    return stock !== undefined && stock <= 0
  }

  const getProductStatus = () => {
    if (isOutOfStock()) {
      return (
        <div className="text-white-primary py-1.5 px-2 font-semibold text-[0.65rem] bg-gray-multi">
          Out of Stock
        </div>
      )
    }

    if (hidden) {
      return (
        <div className="text-white-primary py-1.5 px-2 font-semibold text-[0.65rem] bg-gray-multi">
          Product Unavailable
        </div>
      )
    }

    return (
      <div className={`text-white-primary py-1.5 px-2 font-semibold text-[0.65rem] ${AVAILABILITY_STYLES[availability].background}`}>
        {AVAILABILITY_STYLES[availability].text}
      </div>
    )
  }

  const content = () => (
    <div className="w-full flex flex-col h-full">
      <div className="p-2 flex flex-col h-full">
        <div className="relative">
          <img
            className="object-contain w-full aspect-square"
            src={images[0]}
          />
          {
            (isOutOfStock() || hidden) && (
              <div className="absolute inset-0 bg-white-primary opacity-50" />
            )
          }
        </div>

        <div
          className="text-sm font-semibold line-clamp-2 my-2 lg:text-base"
          title={name}
        >
          {name}
        </div>

        <div className="mt-auto text-xs text-gray-multi-3 line-clamp-1">
          {brand.name}
        </div>

          <div className="text-red-multi font-semibold text-sm lg:font-bold lg:text-base">
            {numberFormatter(price, 'Rp')}
          </div>

          {
            originalPrice > price && (
              <div className="text-gray-multi-3 text-[13px] line-through">
                {numberFormatter(originalPrice, 'Rp')}
              </div>
            )
          }
      </div>

      <div className="mt-auto">
        {getProductStatus()}
      </div>
    </div>
  )

  return onClick ? (
    <div
      onClick={() => onClick(id)}
      className={'bg-white-multi border border-black-multi flex flex-col relative h-full ' + className}
    >
      {content()}
    </div>
  ) : (
    <Link
      to={pdpUrl()}
      className={'bg-white-multi border border-black-multi flex flex-col relative h-full ' + className}
    >
      {content()}
    </Link>
  )
}

export default ProductCard