import React, { useEffect, useState } from 'react'
import config from '@/constant/config'
import { useDispatch } from 'react-redux'
import { getCategories } from '@/store/slices/category'
import { getBrands } from '@/store/slices/brand'
import { getSeries } from '@/store/slices/series'
import { getCharacters } from '@/store/slices/character'
import { useForm, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import debounce from '@/utils/debouncer'
import * as yup from 'yup'

import Checkbox from '@/components/Checkbox'
import NumberInput from '@/components/NumberInput'
import SortedAlphabetFilterModal from '@/components/SortedAlphabetFilterModal'

const { availabilities } = config.product

const validationSchema = yup.object().shape({
  availability: yup.array().of(yup.string()).notRequired(),
  category: yup.array().of(yup.string()).notRequired(),
  brand: yup.array().of(yup.string()).notRequired(),
  series: yup.array().of(yup.string()).notRequired(),
  character: yup.array().of(yup.string()).notRequired(),
  minPrice: yup.string().notRequired().when('maxPrice', ([maxPrice], field) => {
    if (maxPrice) {
      return field.test('validMinPrice', 'Invalid min price', minPrice => {
        return !minPrice || +minPrice < +maxPrice
      })
    }
    return field.notRequired()
  }),
  maxPrice: yup.string().notRequired()
})

const Container = ({
  title,
  children,
  noLine = false,
  showCounter,
  count
}) => (
  <section className="relative">
    <div className="flex items-center w-full mb-3">
      <div className="text-base font-semibold">
        {title}
      </div>

      {
        showCounter && (
          <div className="ml-2.5 w-4 h-4 flex items-center justify-center bg-yellow-multi rounded-full text-xs font-semibold">
            {count}
          </div>
        )
      }
    </div>

    {children}

    {
      !noLine && (
        <div className="w-full h-[1px] bg-gray-multi mt-5 mb-3" />
      )
    }
  </section>
)

const ProductSearchFilterDesktop = ({ filters, setFilters }) => {
  const dispatch = useDispatch()

  const [categories, setCategories] = useState([])
  const [brands, setBrands] = useState([])
  const [series, setSeries] = useState([])
  const [characters, setCharacters] = useState([])
  const [validMinPrice, setValidMinPrice] = useState(false)
  const [expandCategories, setExpandCategories] = useState(false)
  const [expandBrands, setExpandBrands] = useState(false)
  const [expandSeries, setExpandSeries] = useState(false)
  const [expandCharacters, setExpandCharacters] = useState(false)

  const {
    register,
    control,
    reset,
    trigger,
    setValue
  } = useForm({
    resolver: yupResolver(validationSchema),
    shouldFocusError: false,
    defaultValues: {
      availability: [],
      category: [],
      brand: [],
      series: [],
      character: []
    }
  })

  const selectedCategories = useWatch({
    control,
    name: 'category'
  })

  const selectedBrands = useWatch({
    control,
    name: 'brand'
  })

  const selectedSeries = useWatch({
    control,
    name: 'series'
  })

  const selectedCharacters = useWatch({
    control,
    name: 'character'
  })

  const selectedAvailabilities = useWatch({
    control,
    name: 'availability'
  })

  const maxPrice = useWatch({
    control,
    name: 'maxPrice'
  })

  const minPrice = useWatch({
    control,
    name: 'minPrice'
  })

  useEffect(() => {
    debounce(apply, 500, 'apply-filter-desktop')
  }, [
    selectedAvailabilities,
    maxPrice,
    minPrice,
    selectedCategories,
    selectedBrands,
    selectedSeries,
    selectedCharacters
  ])

  useEffect(() => {
    reset({
      availability: filters.availability,
      category: filters.category,
      brand: filters.brand,
      series: filters.series,
      character: filters.character,
      minPrice: filters.minPrice,
      maxPrice: filters.maxPrice
    })
    fetchCategories()
    fetchBrands()
    fetchSeries()
    fetchCharacters()
  }, [])

  const fetchCategories = async () => {
    const { data } = await dispatch(getCategories()).unwrap()
    setCategories(data)
  }

  const fetchBrands = async () => {
    const { data } = await dispatch(getBrands()).unwrap()
    setBrands(data)
  }

  const fetchSeries = async () => {
    const { data } = await dispatch(getSeries()).unwrap()
    setSeries(data)
  }

  const fetchCharacters = async () => {
    const { data } = await dispatch(getCharacters()).unwrap()
    setCharacters(data)
  }

  const sortDescendingByTruthyValues = (currentElement, nextElement) => {
    if (currentElement.selected && !nextElement.selected) {
      return -1
    }
    if (!currentElement.selected && nextElement.selected) {
      return 1
    }
    return 0
  }

  const highlightedCategories = () => {
    return categories.map(c => ({ ...c, selected: selectedCategories.includes(c.id) }))
      .sort(sortDescendingByTruthyValues)
      .slice(0, 10)
  }

  const highlightedBrands = () => {
    return brands.map(b => ({ ...b, selected: selectedBrands.includes(b.id) }))
      .sort(sortDescendingByTruthyValues)
      .slice(0, 10)
  }

  const highlightedSeries = () => {
    return series.map(s => ({ ...s, selected: selectedSeries.includes(s.id) }))
      .sort(sortDescendingByTruthyValues)
      .slice(0, 10)
  }

  const highlightedCharacters = () => {
    return characters.map(c => ({ ...c, selected: selectedCharacters.includes(c.id) }))
      .sort(sortDescendingByTruthyValues)
      .slice(0, 10)
  }

  const apply = async () => {
    const isValidMinPrice = await trigger('minPrice')
    setValidMinPrice(isValidMinPrice)

    if (!isValidMinPrice) {
      return
    }

    setFilters && setFilters({
      availability: selectedAvailabilities,
      category: selectedCategories,
      brand: selectedBrands,
      series: selectedSeries,
      character: selectedCharacters,
      minPrice: minPrice,
      maxPrice: maxPrice
    })
  }

  const resetFilter = () => {
    reset({
      availability: [],
      category: [],
      brand: [],
      series: [],
      character: [],
      minPrice: null,
      maxPrice: null
    })
  }

  return (
    <div>
      <div className="flex justify-between items-center">
        <div className="font-semibold text-xl mb-4">
          Filter
        </div>

        <button
          type="button"
          className="text-sm"
          onClick={resetFilter}
        >
          Reset
        </button>
      </div>

      <form>
        <Container title="Availability">
          {
            availabilities.map(availability => (
              <div
                key={availability.key}
                className="mb-2.5"
              >
                <Checkbox
                  label={availability.value}
                  id={availability.key}
                  value={availability.key}
                  {...register('availability')}
                />
              </div>
            ))
          }
        </Container>

        <Container
          title="Category"
          showCounter={selectedCategories.length > 0}
          count={selectedCategories.length}
        >
          {
            expandCategories && (
              <SortedAlphabetFilterModal
                title="Categories"
                items={categories}
                initialItems={selectedCategories}
                onClose={() => setExpandCategories(false)}
                onApply={selected => {
                  setValue('category', selected)
                  setExpandCategories(false)
                }}
              />
            )
          }
          {
            highlightedCategories().map(category => (
              <div
                key={category.id}
                className="mb-2.5"
              >
                <Checkbox
                  label={category.name}
                  id={category.id}
                  value={category.id}
                  {...register('category')}
                />
              </div>
            ))
          }
          <button
            type="button"
            className="text-yellow-multi-2"
            onClick={() => setExpandCategories(true)}
          >
            See all
          </button>
        </Container>

        <Container
          title="Brand"
          showCounter={selectedBrands.length > 0}
          count={selectedBrands.length}
        >
          {
            expandBrands && (
              <SortedAlphabetFilterModal
                title="Brands"
                items={brands}
                initialItems={selectedBrands}
                onClose={() => setExpandBrands(false)}
                onApply={selected => {
                  setValue('brand', selected)
                  setExpandBrands(false)
                }}
              />
            )
          }
          {
            highlightedBrands().map(brand => (
              <div
                key={brand.id}
                className="mb-2.5"
              >
                <Checkbox
                  label={brand.name}
                  id={brand.id}
                  value={brand.id}
                  {...register('brand')}
                />
              </div>
            ))
          }
          <button
            type="button"
            className="text-yellow-multi-2"
            onClick={() => setExpandBrands(true)}
          >
            See all
          </button>
        </Container>

        <Container
          title="Series"
          showCounter={selectedSeries.length > 0}
          count={selectedSeries.length}
        >
          {
            expandSeries && (
              <SortedAlphabetFilterModal
                title="Series"
                items={series}
                initialItems={selectedSeries}
                onClose={() => setExpandSeries(false)}
                onApply={selected => {
                  setValue('series', selected)
                  setExpandSeries(false)
                }}
              />
            )
          }
          {
            highlightedSeries().map(s => (
              <div
                key={s.id}
                className="mb-2.5"
              >
                <Checkbox
                  label={s.name}
                  id={s.id}
                  value={s.id}
                  {...register('series')}
                />
              </div>
            ))
          }
          <button
            type="button"
            className="text-yellow-multi-2"
            onClick={() => setExpandSeries(true)}
          >
            See all
          </button>
        </Container>

        <Container
          title="Character"
          showCounter={selectedCharacters.length > 0}
          count={selectedCharacters.length}
        >
          {
            expandCharacters && (
              <SortedAlphabetFilterModal
                title="Characters"
                items={characters}
                initialItems={selectedCharacters}
                onClose={() => setExpandCharacters(false)}
                onApply={selected => {
                  setValue('character', selected)
                  setExpandCharacters(false)
                }}
              />
            )
          }
          {
            highlightedCharacters().map(character => (
              <div
                key={character.id}
                className="mb-2.5"
              >
                <Checkbox
                  label={character.name}
                  id={character.id}
                  value={character.id}
                  {...register('character')}
                />
              </div>
            ))
          }
          <button
            type="button"
            className="text-yellow-multi-2"
            onClick={() => setExpandCharacters(true)}
          >
            See all
          </button>
        </Container>

        <Container
          title="Price"
          noLine
        >
          {
            !validMinPrice && (
              <div className="text-red-multi mb-3 text-xs">
                Min. price must be less than max. price
              </div>
            )
          }
          <div className="grid grid-cols-2 gap-3 w-full text-sm">
            <div>
              <div>
                Min. Price
              </div>

              <div className="flex items-center mt-2">
                <div>
                  Rp
                </div>

                <NumberInput
                  name="minPrice"
                  control={control}
                  separator="."
                  className="mx-3 py-1.5 w-full bg-white-multi text-sm outline-none"
                />
              </div>
              <div className="w-full h-[0.5px] bg-gray-multi mt-1.5" />
            </div>

            <div>
              <div>
                Max. Price
              </div>

              <div className="flex items-center mt-2">
                <div>
                  Rp
                </div>

                <NumberInput
                  name="maxPrice"
                  control={control}
                  separator="."
                  className="mx-3 py-1.5 w-full bg-white-multi text-sm outline-none"
                />
              </div>
              <div className="w-full h-[0.5px] bg-gray-multi mt-1.5" />
            </div>
          </div>
        </Container>
      </form>
    </div>
  )
}

export default ProductSearchFilterDesktop