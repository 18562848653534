import React from 'react'

const ProductCardLoader = () => (
  <div className="bg-white-multi border border-black-multi">
    <div className="p-2 w-full">
      <div className="w-full aspect-squre h-36 bg-gray-multi-2 rounded animate-pulse" />
      <div className="h-8 w-full bg-gray-multi-2 mt-2 rounded animate-pulse" />
      <div className="h-5 w-full bg-gray-multi-2 mt-6 rounded animate-pulse" />
    </div>
    <div className="w-full bg-gray-multi-2 h-7 animate-pulse" />
  </div>
)

export default ProductCardLoader