export default {
  path: {
    base: '/',
    login: '/login',
    register: '/register',
    registerCompletion: '/register-completion',
    resetPassword: '/reset-password/:linkId',
    verifyResetPassword: '/reset-password/verify',
    profile: '/profile',
    createPassword: '/profile/create-password',
    changePassword: '/profile/change-password/:linkId',
    editProfile: '/profile/edit',
    address: '/profile/address',
    editAddress: '/profile/address/:addressId',
    search: '/q',
    product: '/p/:name/:sku',
    wishlist: '/wishlist',
    aboutUs: '/about-us',
    cart: '/cart',
    checkout: '/checkout',
    postCheckout: '/checkout/:orderId',
    order: '/order',
    orderDetail: '/order/:id',
    checkoutSettlement: '/checkout/settlement/:id',
    checkoutAdjustment: '/checkout/adjustment/:id',
    tnc: '/tnc',
    customLayout: '/page/:slug'
  },
  api: {
    googleLogin: '/backend/user/_google-login',
    facebookLogin: '/backend/user/_facebook-login',
    login: '/backend/user/_login',
    logout: '/backend/user/_logout',
    loginInfo: '/backend/user/_login/info',
    user: '/backend/user',
    resetPassword: '/backend/user/_reset-password',
    createPassword: '/backend/user/_create-password',
    changePassword: '/backend/user/_change-password',
    otpDetail(otpId) {
      return `/backend/otp/${otpId}`
    },
    resendRegistrationOtp: '/backend/otp/registration/_resend',
    validateRegistrationOtp: '/backend/otp/registration/_validate',
    resetPasswordOtp: {
      send: '/backend/otp/reset-password',
      resend: '/backend/otp/reset-password/_resend',
      validate: '/backend/otp/reset-password/_validate'
    },
    changePasswordOtp: {
      send: '/backend/otp/change-password',
      resend: '/backend/otp/change-password/_resend',
      validate: '/backend/otp/change-password/_validate'
    },
    oneTimeLink: {
      isUnclaimedLink(linkId) {
        return `/backend/one-time-link/${linkId}`
      },
      create: '/backend/one-time-link',
      claim(linkId) {
        return `/backend/one-time-link/${linkId}`
      }
    },
    banner: '/backend/banner',
    product: {
      latest: '/backend/product/latest-product',
      filter: '/backend/product',
      detail(sku) {
        return `/backend/product/${sku}`
      }
    },
    address: {
      base: '/backend/address',
      detail(addressId) {
        return `/backend/address/${addressId}`
      },
      setPrimary(addressId) {
        return `/backend/address/${addressId}/_set-primary`
      }
    },
    cart: {
      base: '/backend/cart',
      detail(sku) {
        return `/backend/cart/${sku}`
      },
      count: '/backend/cart/_count',
      setDownPayment: '/backend/cart/_set-down-payment'
    },
    checkout: '/backend/checkout',
    checkoutPayment: '/backend/checkout/_pay',
    order: {
      base: '/backend/order',
      detail(orderId) {
        return `/backend/order/${orderId}`
      },
      create(checkoutId) {
        return `/backend/order/${checkoutId}`
      }
    },
    shipping: {
      rates: '/backend/shipping/rates'
    },
    region: {
      base: 'https://multitoys88.github.io/api-wilayah-indonesia/api',
      provinces() {
        return `${this.base}/provinces.json`
      },
      province(provinceId) {
        return `${this.base}/province/${provinceId}.json`
      },
      regencies(provinceId) {
        return `${this.base}/regencies/${provinceId}.json`
      },
      regency(regencyId) {
        return `${this.base}/regency/${regencyId}.json`
      },
      districts(regencyId) {
        return `${this.base}/districts/${regencyId}.json`
      },
      district(districtId) {
        return `${this.base}/district/${districtId}.json`
      },
      villages(districtId) {
        return `${this.base}/villages/${districtId}.json`
      },
      village(villageId) {
        return `${this.base}/village/${villageId}.json`
      }
    },
    attribute: {
      category: '/backend/category',
      brand: '/backend/brand',
      series: '/backend/series',
      character: '/backend/character'
    },
    wishlist: '/backend/wishlist',
    checkWishlist(sku) {
      return `/backend/wishlist/${sku}`
    },
    checkoutSettlement: '/backend/checkout/settlement',
    checkoutSettlementPayment: '/backend/checkout/settlement/_pay',
    checkoutAdjustment: '/backend/checkout/adjustment',
    checkoutAdjustmentPayment: '/backend/checkout/adjustment/_pay',
    trackShipping(shippingId) {
      return `/backend/shipping/track/${shippingId}`
    },
    config: {
      homepageProduct: '/backend/config/homepage-product'
    },
    newsletter: {
      subscribe: '/backend/newsletter/_subscribe'
    },
    featuredProduct: '/backend/featured-product',
    storeLocation: '/backend/store-location',
    customLayout(slug) {
      return `/backend/custom-layout/${slug}`
    }
  },
  wording: {
    gender: {
      MALE: 'Male',
      FEMALE: 'Female'
    }
  },
  product: {
    status: [
      {
        key: 'DRAFT',
        value: 'Draft'
      },
      {
        key: 'PUBLISHED',
        value: 'Published'
      }
    ],
    availability: {
      READY_STOCK: 'Ready Stock',
      PREORDER: 'Pre-order',
      LATE_PREORDER: 'Late Pre-order',
      WAITING_LIST: 'Waiting List'
    },
    availabilities: [
      {
        key: 'READY_STOCK',
        value: 'Ready Stock'
      },
      {
        key: 'PREORDER',
        value: 'Pre-order'
      },
      {
        key: 'LATE_PREORDER',
        value: 'Late Pre-order'
      },
      {
        key: 'WAITING_LIST',
        value: 'Waiting List'
      }
    ],
    sort: [
      {
        key: 'MOST_RELEVANT',
        value: 'Most Relevant'
      },
      {
        key: 'LATEST',
        value: 'Latest'
      },
      {
        key: 'OLDEST',
        value: 'Oldest'
      },
      {
        key: 'HIGHEST_PRICE',
        value: 'Highest Price'
      },
      {
        key: 'LOWEST_PRICE',
        value: 'Lowest Price'
      }
    ]
  },
  link: {
    whatsapp: 'https://api.whatsapp.com/send?phone=6287880161153&text='
  },
  gmaps: {
    libraries: ['places']
  }
}
