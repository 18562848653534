import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '@/axios'
import config from '@/constant/config'
import { toResponse } from '@/utils/common'

const { api } = config

export const getOtpDetail = createAsyncThunk(
  'otp/getOtpDetail',
  async (otpId, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(api.otpDetail(otpId))
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const resendRegistrationOtp = createAsyncThunk(
  'otp/resendRegistrationOtp',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(api.resendRegistrationOtp, body)
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const validateRegistrationOtp = createAsyncThunk(
  'otp/validateRegistrationOtp',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(api.validateRegistrationOtp, body)
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const sendResetPasswordOtp = createAsyncThunk(
  'otp/sendResetPasswordOtp',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(api.resetPasswordOtp.send, body)
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const validateResetPasswordOtp = createAsyncThunk(
  'otp/validateResetPasswordOtp',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(api.resetPasswordOtp.validate, body)
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const resendResetPasswordOtp = createAsyncThunk(
  'otp/resendResetPasswordOtp',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(api.resetPasswordOtp.resend, body)
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const sendChangePasswordOtp = createAsyncThunk(
  'otp/sendChangePasswordOtp',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(api.changePasswordOtp.send, body)
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const validateChangePasswordOtp = createAsyncThunk(
  'otp/validateChangePasswordOtp',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(api.changePasswordOtp.validate, body)
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const resendChangePasswordOtp = createAsyncThunk(
  'otp/resendChangePasswordOtp',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(api.changePasswordOtp.resend, body)
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const otpSlice = createSlice({
  name: 'otp',
  initialState: {},
  reducers: {}
})

export default otpSlice.reducer