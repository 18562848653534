import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '@/axios'
import config from '@/constant/config'
import { toResponse } from '@/utils/common'

const { api } = config

export const getCheckoutItems = createAsyncThunk(
  'checkout/getCheckoutItems',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(api.checkout, body)
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const getShippingRates = createAsyncThunk(
  'checkout/getShippingRates',
  async ({ destinationAddressId, items, orderId }, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(api.shipping.rates, {
        params: {
          destinationAddressId,
          orderId,
          items
        }
      })
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const checkoutPayment = createAsyncThunk(
  'checkout/checkoutPayment',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(api.checkoutPayment, body)
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const checkoutSettlement = createAsyncThunk(
  'checkout/checkoutSettlement',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(api.checkoutSettlement, body)
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const checkoutSettlementPayment = createAsyncThunk(
  'checkout/checkoutSettlementPayment',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(api.checkoutSettlementPayment, body)
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const checkoutAdjustment = createAsyncThunk(
  'checkout/checkoutAdjustment',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(api.checkoutAdjustment, body)
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const checkoutAdjustmentPayment = createAsyncThunk(
  'checkout/checkoutAdjustmentPayment',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(api.checkoutAdjustmentPayment, body)
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState: {},
  reducers: {}
})

export default checkoutSlice.reducer