import React, { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getOrderDetail } from '@/store/slices/order'
import toast from 'react-hot-toast'
import config from '@/constant/config'
import useLoader from '@/hooks/loader'
import useAuthentication from '@/hooks/authentication'
import useResponsive from '@/hooks/responsive'
import { FaCheckCircle, FaTimesCircle, FaClock } from 'react-icons/fa';

import GeneralHeader from '@/components/GeneralHeader'
import TitleHeader from '@/components/TitleHeader'
import Footer from '@/components/Footer'

const { path } = config

const OrderListPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { isDesktop } = useResponsive()
  const { orderId } = useParams()

  const { unauthorized } = useAuthentication()
  const [, setIsLoading] = useLoader()

  const [status, setStatus] = useState('')

  useEffect(() => {
    fetchOrderDetail()
  }, [])

  const fetchOrderDetail = async () => {
    try {
      setIsLoading(true)

      const { data } = await dispatch(getOrderDetail(orderId)).unwrap()
      const { payment = [] } = data
      const p = payment.find(p => p.description === 'FIRST_PAYMENT') || {}
      setStatus(p.status)

      setIsLoading(false)
    } catch(error) {
      unauthorized(error, () => {
        setIsLoading(false)
        toast.error('Something went wrong, please try again')
      })
    }
  }

  return (
    <div className="flex flex-col min-h-screen">
      {
        isDesktop ? (
          <GeneralHeader />
        ) : (
          <TitleHeader
            title="Order"
            noBackButton
          />
        )
      }

      <section className="bg-white-multi grow p-5 relative flex flex-col lg:py-8">
        {
          status === 'PAID' && (
            <>
              <div className="w-full flex flex-col grow justify-center items-center">
                <FaCheckCircle className="text-green-multi text-[9rem] lg:text-[11rem]" />

                <div className="font-semibold text-2xl my-4 lg:text-3xl lg:my-6">
                  Payment Success
                </div>

                <div className="text-sm lg:text-base text-gray-multi">
                  Your order is being processed!
                </div>
                <div className="text-sm lg:text-base text-gray-multi">
                  You can check your order or continue shopping.
                </div>
              </div>

              <div className="flex gap-x-3 mx-auto w-full lg:w-1/2 2xl:w-1/3">
                <button
                  type="button"
                  className="button-primary"
                  onClick={() => navigate(path.base)}
                >
                  Keep Shopping
                </button>
                <button
                  type="button"
                  className="button-primary dark"
                  onClick={() => navigate(path.order)}
                >
                  Check Orders
                </button>
              </div>
            </>
          )
        }

        {
          status === 'EXPIRED' && (
            <>
              <div className="w-full flex flex-col grow justify-center items-center">
                <FaTimesCircle className="text-red-multi text-[9rem] lg:text-[11rem]" />

                <div className="font-semibold text-2xl my-4 lg:text-3xl lg:my-6">
                  Payment Failed
                </div>

                <div className="text-sm lg:text-base text-gray-multi">
                  Your order is canceled.
                </div>
                <div className="text-sm lg:text-base text-gray-multi">
                  You can try creating order again or continue shopping.
                </div>

                <button
                  type="button"
                  className="button-primary dark mt-6 lg:!w-1/5"
                  onClick={() => navigate(path.base)}
                >
                  Back to Home
                </button>
              </div>
            </>
          )
        }

        {
          status === 'PENDING' && (
            <>
              <div className="w-full flex flex-col grow justify-center items-center">
                <FaClock className="text-blue-multi text-[9rem] lg:text-[11rem]" />

                <div className="font-semibold text-2xl my-4 lg:text-3xl lg:my-6">
                  Payment Pending
                </div>

                <div className="text-sm lg:text-base text-gray-multi">
                  Please complete your payment so we can process your order.
                </div>

                <button
                  type="button"
                  className="button-primary dark mt-6 lg:!w-1/5"
                  onClick={fetchOrderDetail}
                >
                  Refresh
                </button>
              </div>
            </>
          )
        }
      </section>

      {isDesktop && <Footer />}
    </div>
  )
}

export default OrderListPage