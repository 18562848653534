import React from 'react'

const AVAILABILITY_MAP = {
  READY_STOCK: {
    text: 'Ready Stock',
    color: 'bg-green-multi'
  },
  PREORDER: {
    text: 'Pre-order',
    color: 'bg-orange-multi'
  },
  LATE_PREORDER: {
    text: 'Late Pre-order',
    color: 'bg-red-multi'
  },
  WAITING_LIST: {
    text: 'Waiting List',
    color: 'bg-blue-multi'
  }
}

const ProductAvailabilityLabel = ({ availability, className = '' }) => {
  const availabilityDetail = AVAILABILITY_MAP[availability]
  return (
    <div className={`rounded-full text-center text-white-primary text-[11px] px-2 py-1 ${availabilityDetail?.color} ${className}` }>
      {availabilityDetail?.text}
    </div>
  )
}

export default ProductAvailabilityLabel