import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getProducts } from '@/store/slices/product'
import { useSearchParams } from 'react-router-dom'
import { cleanObject } from '@/utils/common'
import { FaSortAmountDown, FaChevronLeft } from 'react-icons/fa'
import { MdSearchOff } from 'react-icons/md'
import { IoMdSearch } from 'react-icons/io'
import { LiaTimesCircle, LiaFilterSolid } from 'react-icons/lia'
import config from '@/constant/config'
import toast from 'react-hot-toast'
import debounce from '@/utils/debouncer'
import InfiniteScroll from 'react-infinite-scroll-component'
import classNames from 'classnames'
import useResponsive from '@/hooks/responsive'

import ProductCard from '@/components/ProductCard'
import ProductCardLoader from '@/components/ProductCardLoader'
import GeneralHeader from '@/components/GeneralHeader'
import SideNavigation from '@/components/SideNavigation'
import Footer from '@/components/Footer'
import RadioButton from '@/components/RadioButton'
import ProductSearchFilterMobile from '@/components/ProductSearchFilterMobile'
import ProductSearchFilterDesktop from '@/components/ProductSearchFilterDesktop'

const { sort } = config.product

const ProductSearchPage = () => {
  const dispatch = useDispatch()

  const [searchParams, setSearchParams] = useSearchParams()

  const { isDesktop } = useResponsive()

  const [products, setProducts] = useState([])
  const [productPage, setProductPage] = useState({})

  const [selectedSort, setSelectedSort] = useState(searchParams.get('sort') || sort[0].key)
  const [tempSearchKeyword, setTempSearhKeyword] = useState(searchParams.get('search') || '')
  const [searchKeyword, setSearchKeyword] = useState(searchParams.get('search') || '')
  const [filters, setFilters] = useState({
    availability: searchParams.getAll('availability'),
    category: searchParams.getAll('category'),
    brand: searchParams.getAll('brand'),
    series: searchParams.getAll('series'),
    character: searchParams.getAll('character'),
    minPrice: searchParams.get('minPrice'),
    maxPrice: searchParams.get('maxPrice')
  })

  const [isFetchingProduct, setIsFetchingProduct] = useState(false)
  const [visibleSortMenu, setVisibleSortMenu] = useState(false)
  const [visibleFilterMenu, setVisibleFilterMenu] = useState(false)

  useEffect(() => {
    setProducts([])
    fetchProducts(1, false)
  }, [selectedSort, searchKeyword, filters])

  useEffect(() => {
    debounce(() => setSearchKeyword(tempSearchKeyword), 300, 'search-product')
  }, [tempSearchKeyword])

  const fetchProducts = async (page, append = true) => {
    try {
      setIsFetchingProduct(true)

      const cleanedObject = cleanObject(filters)

      setSearchParams({
        search: searchKeyword,
        sort: selectedSort,
        ...cleanedObject
      })

      const requestParam = {
        search: searchKeyword,
        page,
        sort: selectedSort,
        size: isDesktop ? 24 : 10,
        ...cleanedObject
      }
      const { data, paging } = await dispatch(getProducts(requestParam)).unwrap()

      if (append) {
        setProducts([...products, ...data])
      } else {
        setProducts(data)
      }

      setProductPage(paging)

      setIsFetchingProduct(false)
    } catch(error) {
      toast.error('Something went wrong, please try again')
    }
  }

  const handleSortChange = (s) => {
    setSelectedSort(s.key)
    setVisibleSortMenu(false)
  }

  const productLoader = () => {
    const size = isDesktop ? 12 : 6
    return [...new Array(size)].map((_, i) => <ProductCardLoader key={i} />)
  }

  const hasAppliedFilter = () => (
    filters.availability?.length ||
    filters.brand?.length ||
    filters.category?.length ||
    filters.series?.length ||
    filters.character?.length ||
    filters.minPrice ||
    filters.maxPrice
  )

  return (
    <>
      {/* Sort Sidebar Navigation */}
      {
        !isDesktop && (
          <SideNavigation
            visible={visibleSortMenu}
            direction={1}
            onClose={() => setVisibleSortMenu(false)}
          >
            <div className="flex items-center mb-5">
              <button
                type="button"
                onClick={() => setVisibleSortMenu(false)}
              >
                <FaChevronLeft className="text-yellow-multi text-sm mt-0.5" />
              </button>

              <div className="font-semibold text-yellow-multi text-xl ml-3">
                Sort By
              </div>
            </div>
            {
              sort.map(s => (
                <div key={s.key}>
                  <RadioButton
                    id={s.key}
                    text={s.value}
                    value={s.key}
                    name="SORT"
                    type="DARK"
                    className="text-white-multi px-3 py-5"
                    onChange={() => handleSortChange(s)}
                    checked={s.key === selectedSort}
                  />
                  <div className="w-full h-[1px] bg-gray-multi" />
                </div>
              ))
            }
          </SideNavigation>
        )
      }

      {
        !isDesktop && (
          <ProductSearchFilterMobile
            visible={visibleFilterMenu}
            onClose={() => setVisibleFilterMenu(false)}
            filters={filters}
            setFilters={setFilters}
          />
        )
      }

      <div className="relative flex flex-col min-h-screen">
        <GeneralHeader searchKeywordState={[searchKeyword, setSearchKeyword]} />

        <section className="relative bg-white-multi grow p-5 flex lg:items-start lg:gap-x-8 lg:py-8 lg:px-20 lg:justify-between 2xl:px-32">
          {/* Desktop Filter */}
          {
            isDesktop && (
              <section className="lg:w-1/4">
                <ProductSearchFilterDesktop
                  filters={filters}
                  setFilters={setFilters}
                />
              </section>
            )
          }

          <section className="w-full lg:w-3/4 2xl:w-2/3">
            {/* Mobile Filter */}
            {
              !isDesktop && (
                <div className="flex items-center">
                  <div className="w-[80%] px-3 py-2.5 border border-black-multi rounded-lg flex items-center">
                    <IoMdSearch className="text-2xl" />
                    <input
                      type="text"
                      placeholder="Search for products"
                      className="bg-white-multi outline-none text-sm ml-2 w-full"
                      value={tempSearchKeyword}
                      onChange={e => setTempSearhKeyword(e.target.value)}
                    />

                    {
                      tempSearchKeyword && (
                        <button
                          type="button"
                          onClick={() => setTempSearhKeyword('')}
                        >
                          <LiaTimesCircle className="text-lg mt-0.5" />
                        </button>
                      )
                    }
                  </div>

                  <button
                    type="button"
                    className={classNames({
                      'ml-2 mr-1 relative': true,
                      'after:absolute after:top-0 after:right-0 after:w-2 after:h-2 after:bg-red-multi after:rounded-full': hasAppliedFilter()
                    })}
                    onClick={() => setVisibleFilterMenu(true)}
                  >
                    <LiaFilterSolid className="text-[1.5rem] mx-auto" />
                  </button>

                  <button
                    type="button"
                    className="w-[10%]"
                    onClick={() => setVisibleSortMenu(true)}
                  >
                    <FaSortAmountDown className="text-[1.1rem] mx-auto" />
                  </button>
                </div>
              )
            }

            {
              isDesktop && (
                <div>
                  {
                    !!searchKeyword.length && productPage?.totalItem > 0 && (
                      <div className="lg:font-semibold lg:mb-3">
                        Showing 1 - {productPage.totalItem} results for "{searchKeyword}"
                      </div>
                    )
                  }

                  <div className="lg:flex lg:text-sm 2xl:text-base">
                    <div className="lg:flex lg:items-center">
                      <LiaFilterSolid className="lg:text-xl" />
                      <div>
                        Sort by
                      </div>
                    </div>

                    <div className="lg:flex lg:ml-auto lg:gap-x-4">
                      {
                        sort.map(s => (
                          <button
                            type="button"
                            key={s.key}
                            className={classNames({
                              'button-secondary lg:w-[unset] lg:px-2 lg:text-xs 2xl:text-sm': selectedSort !== s.key,
                              'button-primary lg:w-[unset] lg:px-2 lg:text-xs 2xl:text-sm': selectedSort === s.key
                            })}
                            onClick={() => handleSortChange(s)}
                          >
                            {s.value}
                          </button>
                        ))
                      }
                    </div>
                  </div>
                </div>
              )
            }

            {
              !!products.length && (
                <InfiniteScroll
                  className="product-container xl:grid-cols-5 mt-4"
                  dataLength={products.length}
                  next={() => fetchProducts(productPage.page + 1 || 1)}
                  hasMore={products.length < productPage.totalItem}
                  loader={productLoader()}
                  scrollThreshold={0.5}
                >
                  {
                    products.map(product => (
                      <ProductCard
                        id={product.sku}
                        key={product.sku}
                        name={product.displayName}
                        brand={product.brand}
                        price={product.price.offer}
                        originalPrice={product.price.original}
                        images={product.images}
                        availability={product.availability}
                        stock={product.stock}
                        hidden={product.hidden}
                      />
                    ))
                  }
                </InfiniteScroll>
              )
            }

            {
              !isFetchingProduct && !products.length && (
                <div className="flex flex-col justify-center items-center h-[50vh]">
                  <MdSearchOff className="text-[12rem] text-gray-multi" />
                  <div>
                    No products found
                  </div>
                </div>
              )
            }
          </section>
        </section>

        <Footer />
      </div>
    </>
  )
}

export default ProductSearchPage