import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useDispatch, useSelector } from 'react-redux'
import { changePassword, userSelector } from '@/store/slices/user'
import { isUnclaimedLink, claimLink } from '@/store/slices/oneTimeLink'
import { BsInfoCircleFill } from 'react-icons/bs'
import config from '@/constant/config'
import toast from 'react-hot-toast'
import useLoader from '@/hooks/loader'
import useAuthentication from '@/hooks/authentication'
import useResponsive from '@/hooks/responsive'

import GeneralHeader from '@/components/GeneralHeader'
import TitleHeader from '@/components/TitleHeader'
import Footer from '@/components/Footer'

const { path } = config

const validationSchema = yup.object().shape({
  oldPassword: yup.string().required(),
  newPassword: yup.string()
    .min(8)
    .matches(/[A-Z]/)
    .required()
})

const ChangePasswordPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { isDesktop } = useResponsive()

  const { unauthorized } = useAuthentication()
  const { linkId } = useParams()
  const { user } = useSelector(userSelector)

  const [, setIsLoading] = useLoader()

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  })

  useEffect(() => {
    checkIsValidLink()
  }, [])

  const checkIsValidLink = async () => {
    try {
      setIsLoading(true)

      const { data: unclaimedLink } = await dispatch(isUnclaimedLink(linkId)).unwrap()

      setIsLoading(false)

      if (!unclaimedLink) {
        toast('Link expired, please restart the step', {
          duration: 2000,
          icon: <BsInfoCircleFill className="text-lg text-blue-500" /> 
        })
        navigate(path.editProfile)
        return
      }

      await dispatch(claimLink(linkId)).unwrap()
    } catch(error) {
      setIsLoading(false)
      navigate(path.base)
      toast.error('Something went wrong, please try again')
    }
  }

  const doChangePassword = async form => {
    try {
      setIsLoading(true)

      await dispatch(changePassword({
        email: user?.email,
        oldPassword: form.oldPassword,
        newPassword: form.newPassword
      })).unwrap()

      toast.success('Password changed')

      navigate(path.profile)

      setIsLoading(false)
    } catch(error) {
      unauthorized(error, () => {
        setIsLoading(false)
        if (error.data.error === 'INVALID_PASSWORD') {
          toast.error('Invalid old password')
          return
        }
        toast.error('Something went wrong, please try again')
      })
    }
  }

  return (
    <div className="flex flex-col h-screen">
      {
        isDesktop ? (
          <GeneralHeader />
        ) : (
          <TitleHeader
            title="Change Password"
            backUrl={path.editProfile}
          />
        )
      }

      <section className="bg-white-multi grow">
        <form
          onSubmit={handleSubmit(doChangePassword)}
          className="p-5 flex flex-col h-full lg:py-8 lg:px-20"
        >
          {
            isDesktop && (
              <div className="lg:text-2xl lg:font-semibold lg:my-8">
                Change Password
              </div>
            )
          }
          <div className="mb-4 lg:w-1/2">
            <div className="font-semibold mb-1.5">
              Old Password
            </div>
            <input
              type="password"
              placeholder="Enter your old password"
              className="input-primary"
              {...register('oldPassword')}
            />
            {
              errors.oldPassword && (
                <small className="text-red-multi italic">
                  Old password cannot be empty
                </small>
              )
            }
          </div>

          <div className="mb-4 lg:w-1/2">
            <div className="font-semibold mb-1.5">
              New Password
            </div>
            <input
              type="password"
              placeholder="Enter new password"
              className="input-primary"
              {...register('newPassword')}
            />
            {
              errors.newPassword && (
                <small className="text-red-multi italic">
                  Password must contain minimal 8 characters and 1 uppercase letter
                </small>
              )
            }
          </div>

          <div className="mt-auto w-full lg:w-[unset] lg:my-8 lg:self-start">
            <button
              type="submit"
              className="button-primary px-8"
            >
              Change Password
            </button>
          </div>
        </form>
      </section>

      {
        isDesktop && <Footer />
      }
    </div>
  )
}

export default ChangePasswordPage