import { useDispatch, useSelector } from 'react-redux'
import { setIsLoading, isLoadingSelector } from '@/store/slices/loader'

const useLoader = () => {
  const dispatch = useDispatch()

  const isLoading = useSelector(isLoadingSelector)

  const doSetIsLoading = value => {
    dispatch(setIsLoading(value))
  }

  return [isLoading, doSetIsLoading]
}

export default useLoader