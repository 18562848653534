import React, { useEffect } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useDispatch } from 'react-redux'
import { resetPassword } from '@/store/slices/user'
import { isUnclaimedLink, claimLink } from '@/store/slices/oneTimeLink'
import { BsInfoCircleFill } from 'react-icons/bs'
import config from '@/constant/config'
import toast from 'react-hot-toast'
import useLoader from '@/hooks/loader'
import useResponsive from '@/hooks/responsive'

import GeneralHeader from '@/components/GeneralHeader'
import TitleHeader from '@/components/TitleHeader'
import Footer from '@/components/Footer'

const { path } = config

const validationSchema = yup.object().shape({
  password: yup.string()
    .min(8)
    .matches(/[A-Z]/)
    .required(),
  confirmPassword: yup.string()
    .test('validPassword', 'Password is different', function(confirmPassword) {
      return confirmPassword === this.parent.password
    })
    .required('Confirm password must not empty')
})

const ResetPasswordPage = () => {
  const location = useLocation()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { isDesktop } = useResponsive()

  const { linkId } = useParams()

  const [, setIsLoading] = useLoader()

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  })

  useEffect(() => {
    if (!location.state?.email) {
      navigate(path.base)
    }

    checkIsValidLink()
  }, [])

  const checkIsValidLink = async () => {
    try {
      setIsLoading(true)

      const { data: unclaimedLink } = await dispatch(isUnclaimedLink(linkId)).unwrap()

      setIsLoading(false)

      if (!unclaimedLink) {
        toast('Link expired, please restart the step', {
          duration: 2000,
          icon: <BsInfoCircleFill className="text-lg text-blue-500" /> 
        })
        navigate(path.login)
        return
      }

      await dispatch(claimLink(linkId)).unwrap()
    } catch(error) {
      setIsLoading(false)
      navigate(path.login)
      toast.error('Something went wrong, please try again')
    }
  }

  const doResetPassword = async form => {
    try {
      setIsLoading(true)

      await dispatch(resetPassword({
        email: location.state?.email,
        password: form.password
      })).unwrap()

      setIsLoading(false)

      toast.success('Your password has been reset')

      navigate(path.login)
    } catch(error) {
      setIsLoading(false)
      if (error.data.error === 'RESET_FAILED') {
        toast.error('Reset password failed, please re-start the step')
        navigate(path.login)
        return
      }
      toast.error('Something went wrong, please try again')
    }
  }

  return (
    <div className="flex flex-col h-screen">
      {
        isDesktop ? (
          <GeneralHeader />
        ) : (
          <TitleHeader
            title="Reset Password"
            backUrl={path.login}
          />
        )
      }

      <section className="bg-white-multi grow">
        <form
          onSubmit={handleSubmit(doResetPassword)}
          className="p-5 flex flex-col h-full lg:py-8 lg:px-20"
        >
          {
            isDesktop && (
              <div className="lg:text-2xl lg:font-semibold lg:my-8">
                Reset Password
              </div>
            )
          }
          <div className="mb-4 lg:w-1/2">
            <div className="font-semibold mb-1.5">
              New Password
            </div>
            <input
              type="password"
              placeholder="Enter your password"
              className="input-primary"
              {...register('password')}
            />
            {
              errors.password && (
                <small className="text-red-multi italic">
                  Password must contain minimal 8 characters and 1 uppercase letter
                </small>
              )
            }
          </div>

          <div className="lg:w-1/2">
            <div className="font-semibold mb-1.5">
              Confirm Password
            </div>
            <input
              type="password"
              placeholder="Confirm your password"
              className="input-primary"
              {...register('confirmPassword')}
            />
            {
              errors.confirmPassword && (
                <small className="text-red-multi italic">
                  Password must match the password you've entered
                </small>
              )
            }
          </div>

          <div className="mt-auto w-full lg:w-[unset] lg:my-8 lg:self-start">
            <button
              type="submit"
              className="button-primary px-8"
            >
              Reset Password
            </button>
          </div>
        </form>
      </section>

      {
        isDesktop && <Footer />
      }
    </div>
  )
}

export default ResetPasswordPage