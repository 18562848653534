import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '@/axios'
import config from '@/constant/config'
import { toResponse } from '@/utils/common'

const { api } = config

export const createOrder = createAsyncThunk(
  'order/createOrder',
  async (checkoutId, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(api.order.create(checkoutId))
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const getOrderList = createAsyncThunk(
  'order/getOrderList',
  async (params, { rejectWithValue })=> {
    try {
      const { data } = await axios.get(api.order.base, { params })
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const getOrderDetail = createAsyncThunk(
  'order/getOrderDetail',
  async (orderId, { rejectWithValue })=> {
    try {
      const { data } = await axios.get(api.order.detail(orderId))
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const trackShipping = createAsyncThunk(
  'order/trackShipping',
  async (shippingId, { rejectWithValue })=> {
    try {
      const { data } = await axios.get(api.trackShipping(shippingId))
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const orderSlice = createSlice({
  name: 'order',
  initialState: {},
  reducers: {}
})

export default orderSlice.reducer