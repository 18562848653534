import config from '@/constant/config'
import { createBrowserRouter } from 'react-router-dom'
import ProtectedRoute from '@/components/ProtectedRoute'

import Root from '@/components/Root'
import HomePage from '@/pages/HomePage'
import LoginPage from '@/pages/LoginPage'
import RegisterPage from '@/pages/RegisterPage'
import RegisterCompletionPage from '@/pages/RegisterCompletionPage'
import ResetPasswordPage from '@/pages/ResetPasswordPage'
import ResetPasswordVerificationPage  from '@/pages/ResetPasswordVerificationPage'
import ProfilePage from '@/pages/ProfilePage'
import EditProfilePage from '@/pages/EditProfilePage'
import CreateAddressPage from '@/pages/CreateAddressPage'
import EditAddressPage from '@/pages/EditAddressPage'
import CreatePasswordPage from '@/pages/CreatePasswordPage'
import ChangePasswordPage from '@/pages/ChangePasswordPage'
import ProductSearchPage from '@/pages/ProductSearchPage'
import ProductDetailPage from '@/pages/ProductDetailPage'
import WishlistPage from '@/pages/WishlistPage'
import AboutUsPage from '@/pages/AboutUsPage'
import CartPage from '@/pages/CartPage'
import CheckoutPage from '@/pages/CheckoutPage'
import PostCheckoutPage from '@/pages/PostCheckoutPage'
import OrderListPage from '@/pages/OrderListPage'
import OrderDetailPage from '@/pages/OrderDetailPage'
import CheckoutSettlementPage from '@/pages/CheckoutSettlementPage'
import TermsConditionPage from '@/pages/TermsConditionPage'
import CustomLayoutPage from '@/pages/CustomLayoutPage'
import CheckoutAdjustmentPage from '@/pages/CheckoutAdjustmentPage'

const { path } = config

const router = createBrowserRouter([
  {
    element: <Root />,
    children: [
      {
        path: path.base,
        element: <HomePage />,
        index: true
      },
      {
        path: path.login,
        element: <LoginPage />
      },
      {
        path: path.register,
        element: <RegisterPage />
      },
      {
        path: path.registerCompletion,
        element: <RegisterCompletionPage />
      },
      {
        path: path.resetPassword,
        element: <ResetPasswordPage />
      },
      {
        path: path.verifyResetPassword,
        element: <ResetPasswordVerificationPage />
      },
      {
        path: path.tnc,
        element: <TermsConditionPage />,
      },
      {
        path: path.customLayout,
        element: <CustomLayoutPage />
      },
      {
        path: path.profile,
        element: (
          <ProtectedRoute>
            <ProfilePage />
          </ProtectedRoute>
        )
      },
      {
        path: path.createPassword,
        element: (
          <ProtectedRoute>
            <CreatePasswordPage />
          </ProtectedRoute>
        )
      },
      {
        path: path.changePassword,
        element: (
          <ProtectedRoute>
            <ChangePasswordPage />
          </ProtectedRoute>
        )
      },
      {
        path: path.editProfile,
        element: (
          <ProtectedRoute>
            <EditProfilePage />
          </ProtectedRoute>
        )
      },
      {
        path: path.address,
        element: (
          <ProtectedRoute>
            <CreateAddressPage />
          </ProtectedRoute>
        )
      },
      {
        path: path.editAddress,
        element: (
          <ProtectedRoute>
            <EditAddressPage />
          </ProtectedRoute>
        )
      },
      {
        path: path.search,
        element: <ProductSearchPage />
      },
      {
        path: path.product,
        element: <ProductDetailPage />
      },
      {
        path: path.wishlist,
        element: (
          <ProtectedRoute>
            <WishlistPage />
          </ProtectedRoute>
        )
      },
      {
        path: path.aboutUs,
        element: <AboutUsPage />
      },
      {
        path: path.cart,
        element: (
          <ProtectedRoute>
            <CartPage />
          </ProtectedRoute>
        )
      },
      {
        path: path.checkout,
        element: (
          <ProtectedRoute>
            <CheckoutPage />
          </ProtectedRoute>
        )
      },
      {
        path: path.postCheckout,
        element: (
          <ProtectedRoute>
            <PostCheckoutPage />
          </ProtectedRoute>
        )
      },
      {
        path: path.order,
        element: (
          <ProtectedRoute>
            <OrderListPage />
          </ProtectedRoute>
        )
      },
      {
        path: path.orderDetail,
        element: (
          <ProtectedRoute>
            <OrderDetailPage />
          </ProtectedRoute>
        )
      },
      {
        path: path.checkoutSettlement,
        element: (
          <ProtectedRoute>
            <CheckoutSettlementPage />
          </ProtectedRoute>
        )
      },
      {
        path: path.checkoutAdjustment,
        element: (
          <ProtectedRoute>
            <CheckoutAdjustmentPage />
          </ProtectedRoute>
        )
      },
      {
        path: '*',
        element: <div>Not Found</div>
      }
    ]
  }
])

export default router