import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '@/axios'
import config from '@/constant/config'
import { toResponse } from '@/utils/common'

const { api } = config

export const getWishlistProducts = createAsyncThunk(
  'wishlist/getWishlistProducts',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(api.wishlist, { params })
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const checkWishlist = createAsyncThunk(
  'wishlist/checkWishlist',
  async (sku, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(api.checkWishlist(sku))
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const saveWishlist = createAsyncThunk(
  'wishlist/saveWishlist',
  async (sku, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(api.wishlist, null, {
        params: { sku }
      })
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const deleteWishlist = createAsyncThunk(
  'wishlist/deleteWishlist',
  async (skus, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete(api.wishlist, {
        data: { skus }
      })
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const wishlistSlice = createSlice({
  name: 'wishlist',
  initialState: {},
  reducers: {}
})

export default wishlistSlice.reducer