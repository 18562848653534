import React, { forwardRef } from 'react'
import classNames from 'classnames'

const Checkbox = forwardRef(({ label, id, dark = false, size = 'sm', ...props }, ref) => {
  return (
    <div className="relative w-full flex items-center gap-x-2">
      <input
        className={classNames({
          'peer relative appearance-none shrink-0 w-4 h-4 border rounded-sm bg-transparent checked:border-0 disabled:border-steel-400 disabled:bg-steel-400': true,
          'border-white-multi checked:bg-white-multi': dark,
          'border-black-multi checked:bg-black-multi': !dark
        })}
        type="checkbox"
        id={id}
        ref={ref}
        {...props}
      />
      <svg
        className={classNames({
          'absolute w-4 h-4 pointer-events-none hidden peer-checked:block outline-none': true,
          'stroke-white-primary': !dark,
          'store-black-multi': dark
        })}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <polyline points="20 6 9 17 4 12"></polyline>
      </svg>
      <label
        htmlFor={id}
        className={classNames({
          [`text-${size}`]: true,
          'text-white-multi': dark,
          'text-black-multi': !dark
        })}
      >
        {label}
      </label>
    </div>
  )
})

export default Checkbox