import React, { useEffect } from 'react'
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock'

const ScrollLock = ({ isLocked }) => {
  useEffect(() => {
    const targetElement = document.querySelector('body')

    if (isLocked) {
      disableBodyScroll(targetElement)
    } else {
      enableBodyScroll(targetElement)
    }

    return () => {
      enableBodyScroll(targetElement)
    }
  }, [isLocked])

  return null;
}

export default ScrollLock