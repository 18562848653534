import React, { useEffect, useState } from 'react'
import useResponsive from '@/hooks/responsive'
import { useParams, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { checkoutAdjustment, checkoutAdjustmentPayment } from '@/store/slices/checkout'
import useAuthentication from '@/hooks/authentication'
import useLoader from '@/hooks/loader'
import toast from 'react-hot-toast'
import { numberFormatter } from '@/utils/common'
import config from '@/constant/config'

import GeneralHeader from '@/components/GeneralHeader'
import TitleHeader from '@/components/TitleHeader'
import Footer from '@/components/Footer'
import CheckoutShippingOptionModal from '@/components/CheckoutShippingOptionModal'
import classNames from 'classnames'

const { path } = config

const CheckoutAdjustmentPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isDesktop } = useResponsive()
  const { unauthorized } = useAuthentication()
  const { id } = useParams()

  const [, setIsLoading] = useLoader()

  const [adjustmentDetail, setAdjustmentDetail] = useState({})
  const [isPaying, setIsPaying] = useState(false)

  useEffect(() => {
    fetchCheckoutAdjustment()
  }, [])

  const fetchCheckoutAdjustment = async () => {
    try {
        setIsLoading(true)

        const [orderId, orderItemId] = id.split('-')
        const { data } = await dispatch(checkoutAdjustment({
          orderId,
          orderItemId
        })).unwrap()
        setAdjustmentDetail(data)

        setIsLoading(false)
    } catch(error) {
      unauthorized(error, () => {
        if (error.data?.error === 'NO_ADJUSTMENT' || error.data?.error === 'ADJUSTMENT_INVOICE_EXIST') {
          toOrderDetailPage()
          return
        }
        toast.error('Something went wrong, please try again')
        setIsLoading(false)
      })
    }
  }

  const toOrderDetailPage = () => {
    const [orderId] = id.split('-')
    navigate(path.orderDetail.replace(':id', orderId))
  }

  const doPay = async () => {
    try {
      setIsLoading(true)
      setIsPaying(true)

      const [orderId, orderItemId] = id.split('-')
      const { data: invoiceUrl } = await dispatch(checkoutAdjustmentPayment({
        orderId,
        orderItemId
      })).unwrap()

      window.location.href = invoiceUrl

      setIsLoading(false)
    } catch(error) {
      unauthorized(error, () => {
        setIsLoading(false)
        setIsPaying(false)
        if (error.data?.error === 'NO_ADJUSTMENT' || error.data?.error === 'ADJUSTMENT_INVOICE_EXIST') {
          toOrderDetailPage()
          return
        }
        toast.error('Something went wrong, please try again')
      })
    }
  }

  return (
    <div className="flex flex-col min-h-screen">
      {
        isDesktop ? (
          <GeneralHeader />
        ) : (
          <TitleHeader
            title="Payment Settlement"
            onBackClick={toOrderDetailPage}
          />
        )
      }

      <section className="bg-white-multi grow p-5 relative lg:py-8 flex justify-center">
        <section className="flex w-full lg:gap-x-8 lg:items-start lg:w-[90%] 2xl:w-[70%]">
          <section className="flex flex-col gap-y-5 lg:w-[75%]">
            {
              isDesktop && (
                <div className="font-semibold text-2xl mb-4">
                  Adjustment Summary
                </div>
              )
            }

            <section>
              <div className="font-semibold mb-1.5">
                Price Adjustment
              </div>
              <div>
                There's price adjustment for below product's because of:

                <div className="p-3 bg-[#FFE6D6] mt-2 italic">
                  {adjustmentDetail.reason}
                </div>
              </div>
            </section>

            <div>
              <div className="font-semibold mb-1.5">
                Products
              </div>
              <div className="flex flex-col gap-y-5">
                {
                  adjustmentDetail.products?.map(product => (
                    <div key={product.sku} className="flex">
                      <div className="w-1/3 lg:w-36">
                        <img src={product.image} className="object-cover aspect-square" alt="" />
                      </div>
                      <div className="ml-2 flex flex-col w-2/3 lg:p-2 text-sm lg:text-base">
                        <div className="font-semibold line-clamp-2">
                          {product.name}
                        </div>

                        <div className="flex flex-col mt-3 gap-y-1.5 lg:w-1/2 lg:flex-row lg:gap-x-10 lg:mt-auto">
                          <div>
                            Before Price:
                            <div className="font-semibold">
                              {numberFormatter(product.old, 'Rp')} &nbsp; x{product.qty}
                            </div>
                          </div>
                          <div>
                            Updated Price:
                            <div className="font-semibold">
                              {numberFormatter(product.new, 'Rp')} &nbsp; x{product.qty}
                            </div>
                          </div>
                          <div>
                            Deficit:
                            <div className="font-semibold text-red-multi">
                              {numberFormatter(product.deficit, 'Rp')}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>
            </div>

          </section>

          {
            isDesktop && (
              <section className="sticky top-20 left-0 w-1/3 ml-auto self-start 2xl:w-1/4">
                <section className="lg:border lg:border-gray-multi p-4 mb-4">
                  <div className="font-semibold text-lg">
                    Detail
                  </div>

                  <div className="my-8 text-sm flex flex-col gap-y-2">
                    {
                      adjustmentDetail.products?.map(p => (
                        <div>
                          <div>
                            {p.name}
                          </div>
                          <div>
                            Deficit: &nbsp;&nbsp;&nbsp;
                            <strong>x{p.qty} &nbsp; {numberFormatter(p.deficit, 'Rp')}</strong>
                          </div>
                        </div>
                      ))
                    }
                  </div>

                  <div className="font-semibold">
                    Total Price
                  </div>
                  <div className="text-red-multi font-semibold text-xl">
                    {numberFormatter(adjustmentDetail.deficit, 'Rp')}
                  </div>

                  <button
                    type="submit"
                    className={classNames({
                      'button-primary mt-4 mb-3': true,
                      'disabled': isPaying
                    })}
                    disabled={isPaying}
                    onClick={doPay}
                  >
                    Pay Now
                  </button>
                </section>
              </section>
            )
          }
        </section>
      </section>

      {
        !isDesktop && (
          <section className="sticky bottom-0 left-0 bg-white-multi px-4 py-3 border-t-2 border-black-multi">
            <div className="font-semibold text-sm">
              Total Price
            </div>
            <div className="flex justify-between items-center">
              <div className="text-red-multi font-semibold text-xl">
                {numberFormatter(adjustmentDetail.deficit, 'Rp')}
              </div>
            </div>

            <button
              type="submit"
              className={classNames({
                'button-primary mt-2': true,
                'disabled': isPaying
              })}
              disabled={isPaying}
              onClick={doPay}
            >
              Pay Now
            </button>
          </section>
        )
      }

      {isDesktop && <Footer />}
    </div>
  )
}

export default CheckoutAdjustmentPage