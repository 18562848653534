import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { sendResetPasswordOtp, resendResetPasswordOtp, validateResetPasswordOtp } from '@/store/slices/otp'
import { createLink } from '@/store/slices/oneTimeLink'
import config from '@/constant/config'
import toast from 'react-hot-toast'
import { useForm, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import useLoader from '@/hooks/loader'
import useResponsive from '@/hooks/responsive'

import GeneralHeader from '@/components/GeneralHeader'
import TitleHeader from '@/components/TitleHeader'
import OtpModal from '@/components/OtpModal'
import Footer from '@/components/Footer'

const { path } = config

const validationSchema = yup.object().shape({
  email: yup.string().email().required()
})

const ResetPasswordVerificationPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { isDesktop } = useResponsive()

  const {
    register,
    handleSubmit,
    formState: { errors },
    control
  } = useForm({
    resolver: yupResolver(validationSchema)
  })

  const emailForm = useWatch({
    control,
    name: 'email'
  })

  const [, setIsLoading] = useLoader()
  const [visibleVerificationModal, setVisibleVerificationModal] = useState(false)
  const [otpId, setOtpId] = useState('')

  const requestResetPassword = async form => {
    try {
      setIsLoading(true)

      const { data: otpId } = await dispatch(sendResetPasswordOtp({
        email: form.email
      })).unwrap()

      setOtpId(otpId)
      setVisibleVerificationModal(true)

      setIsLoading(false)
    } catch(error) {
      setIsLoading(false)
      if (error.data.error === 'USER_NOT_EXIST') {
        toast.error('Email not registered yet')
        return
      }
      toast.error('Something went wrong, please try again')
    }
  }

  const resendOtp = async otpId => {
    await dispatch(resendResetPasswordOtp({
      otpId
    })).unwrap()
  }

  const verifyOtp = async (otp, otpId) => {
    await dispatch(validateResetPasswordOtp({
      email: emailForm,
      token: otp,
      otpId
    })).unwrap()

    const { data: link } = await dispatch(createLink({ type: 'RESET_PASSWORD' })).unwrap()

    navigate(path.resetPassword.replace(':linkId', link), {
      state: {
        email: emailForm
      }
    })
  }

  return (
    <>
      {
        otpId && (
          <OtpModal
            visible={visibleVerificationModal}
            onClose={() => setVisibleVerificationModal(false)}
            otpId={otpId}
            resend={resendOtp}
            verify={verifyOtp}
          />
        )
      }

      <div className="flex flex-col h-screen">
        {
          isDesktop ? (
            <GeneralHeader />
          ) : (
            <TitleHeader title="Reset Password"/>
          )
        }

        <section className="bg-white-multi grow">
          <form
            onSubmit={handleSubmit(requestResetPassword)}
            className="p-5 flex flex-col h-full lg:py-8 lg:px-20"
          >
            {
              isDesktop && (
                <div className="lg:text-2xl lg:font-semibold lg:my-8">
                  Verification
                </div>
              )
            }
            <div className="font-semibold mb-1.5">
              Email
            </div>
            <input
              type="text"
              placeholder="Enter your email address"
              className="bg-white-multi input-primary lg:w-1/2"
              {...register('email')}
            />
            {
              errors.email && (
                <small className="text-red-multi italic">
                  Incorrect email
                </small>
              )
            }

            <div className="mt-auto w-full lg:w-[unset] lg:my-8 lg:self-start">
              <button
                type="submit"
                className="button-primary px-8"
              >
                Continue
              </button>
            </div>
          </form>
        </section>

        {
          isDesktop && <Footer />
        }
      </div>
    </>
  )
}

export default ResetPasswordVerificationPage