import React from 'react'
import { FaCircleInfo } from 'react-icons/fa6'

const OrangeTicker = ({ children }) => {
  return (
    <div className="p-2 rounded-lg border-[3px] border-orange-multi bg-orange-200 text-sm flex items-center">
        <FaCircleInfo className="w-[7%] text-lg text-orange-400 mr-3 lg:w-[4%] lg:mr-1" />
        <div>
          {children}
        </div>
      </div>
  )
}

export default OrangeTicker