import React, { forwardRef } from 'react'
import styled from 'styled-components'
import tw from 'tailwind-styled-components'

const BaseRadioInput = styled.input`
  appearance: none;
  margin: 0;
  font: inherit;
  color: currentColor;
  border-radius: 50%;
  display: grid;
  place-content: center;
  width: 1em;
  height: 1em;

  &::before {
    content: "";
    width: 0.6em;
    height: 0.6em;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
  }

  &:checked::before {
    transform: scale(1);
  }
`

const BaseLightRadioInput = styled(BaseRadioInput)`
  &::before {
    box-shadow: inset 1em 1em #212121;
  }
`

const LightRadioInput = tw(BaseLightRadioInput)`
  bg-white-multi
  border
  border-black-multi
`

const BaseDarkRadioInput = styled(BaseRadioInput)`
  &::before {
    box-shadow: inset 1em 1em #FFFAE7;
  }
`

const DarkRadioInput = tw(BaseDarkRadioInput)`
  bg-black-multi
  border
  border-white-multi
`

const RadioButton = forwardRef(({ id, label, text, className = '', radioClassName = '', type = 'LIGHT', ...props }, ref) => {
  return (
    <div className={'flex items-center ' + className}>
      <div className={radioClassName}>
        {
          type === 'LIGHT' && (
            <LightRadioInput
              id={id}
              type="radio"
              {...props}
              ref={ref}
            />
          )
        }
        {
          type === 'DARK' && (
            <DarkRadioInput
              id={id}
              type="radio"
              {...props}
              ref={ref}
            />
          )
        }
      </div>

      {
        !!label ? (
          label()
        ) : (
          <label
            htmlFor={id}
            className="ml-2 text-sm lg:text-base font-medium"
          >
            {text}
          </label>
        )
      }
    </div>
  )
})

export default RadioButton