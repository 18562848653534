import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { getOrderList } from '@/store/slices/order'
import toast from 'react-hot-toast'
import useAuthentication from '@/hooks/authentication'
import useResponsive from '@/hooks/responsive'
import InfiniteScroll from 'react-infinite-scroll-component'
import debounce from '@/utils/debouncer'
import { IoMdSearch } from 'react-icons/io'
import classNames from 'classnames'
import config from '@/constant/config'
import { MdSearchOff } from 'react-icons/md'

import GeneralHeader from '@/components/GeneralHeader'
import TitleHeader from '@/components/TitleHeader'
import Footer from '@/components/Footer'
import OrderListCard from '@/components/OrderListCard'

const { path } = config

const Loader = () => (
  <div className="border border-black-multi p-3 flex flex-col gap-y-3">
    <div className="animate-pulse bg-gray-multi-2 h-4" />

    <div className="w-1/4 animate-pulse bg-gray-multi-2 h-4" />
    <div className="animate-pulse bg-gray-multi-2 h-20" />

    <div className="w-1/4 animate-pulse bg-gray-multi-2 h-4" />
    <div className="animate-pulse bg-gray-multi-2 h-20" />
  </div>
)

const ORDER_STATUSES = [
  {
    key: 'ONGOING',
    value: 'Ongoing',
    statuses: [
      'PENDING',
      'PROCESSED',
      'READY_TO_SHIP',
      'PAYMENT_SETTLED',
      'IN_DELIVERY'
    ]
  },
  {
    key: 'SUCCESS',
    value: 'Success',
    statuses: [
      'SUCCESS'
    ]
  },
  {
    key: 'FAILED',
    value: 'Failed',
    statuses: [
      'FAILED',
      'CANCELED'
    ]
  }
]

const OrderListPage = () => {
  const dispatch = useDispatch()

  const { isDesktop } = useResponsive()

  const { unauthorized } = useAuthentication()

  const [isFetchingOrder, setIsFetchingOrder] = useState(false)
  const [selectedStatus, setSelectedStatus] = useState(ORDER_STATUSES[0])
  const [orders, setOrders] = useState([])
  const [orderPage, setOrderPage] = useState({})
  const [search, setSearch] = useState('')

  useEffect(() => {
    fetchOrderList(1, false)
  }, [selectedStatus, search])

  const fetchOrderList = async (page, append = true) => {
    try {
      if (isFetchingOrder) {
        return
      }

      setIsFetchingOrder(true)

      if (!append) {
        setOrders([])
      }

      const { data, paging } = await dispatch(getOrderList({
        status: selectedStatus.key,
        page,
        size: 10,
        search
      })).unwrap()

      setOrderPage(paging)

      if (append) {
        setOrders([
          ...orders,
          ...data
        ])
      } else {
        setOrders(data)
      }

      setIsFetchingOrder(false)
    } catch(error) {
      unauthorized(error, () => {
        setIsFetchingOrder(false)
        toast.error('Something went wrong, please try again')
      })
    }
  }

  const loader = () => {
    return [...new Array(4)].map((_, i) => <Loader key={i} />)
  }

  return (
    <div className="flex flex-col min-h-screen">
      {
        isDesktop ? (
          <GeneralHeader />
        ) : (
          <TitleHeader
            title="Order"
            backUrl={path.base}
          />
        )
      }

      <section className="bg-white-multi grow p-5 relative lg:py-8 flex justify-center">
        <section className="flex w-full lg:items-start lg:gap-x-5 lg:w-[90%] 2xl:w-[70%] lg:justify-between">
          {
            isDesktop && (
              <section className="w-1/3 text-3xl font-bold">
                Your Order's
              </section>
            )
          }

          <section className="w-full flex flex-col h-full lg:w-2/3">
            <div className="flex flex-col lg:flex-row lg:items-center">
              <div className="flex gap-x-3 w-full lg:w-1/2">
                {
                  ORDER_STATUSES.map(status => (
                    <button
                      type="button"
                      key={status.key}
                      className={classNames({
                        'grow text-sm text-center py-2 border border-black-multi transition duration-100': true,
                        'bg-black-multi text-white-multi': selectedStatus.key === status.key
                      })}
                      onClick={() => setSelectedStatus(status)}
                    >
                      {status.value}
                    </button>
                  ))
                }
              </div>

              <div className="mt-4 w-full px-3 py-2 border border-black-multi rounded-lg flex items-center lg:mt-0 lg:w-1/3 lg:ml-auto">
                <IoMdSearch className="text-2xl" />
                <input
                  type="text"
                  placeholder="Search orders"
                  className="bg-white-multi outline-none text-sm ml-2 w-full"
                  onChange={e => debounce(() => setSearch(e.target.value), 300)}
                />
              </div>
            </div>

            {
              !isFetchingOrder && !!orders.length && (
                <InfiniteScroll
                  className="mt-4 flex flex-col gap-y-5"
                  dataLength={orders.length}
                  next={() => fetchOrderList(orderPage.page + 1 || 1)}
                  hasMore={orders.length < orderPage.totalItem}
                  loader={loader()}
                >
                  {
                    orders.map(order => (
                      <OrderListCard
                        key={order.id}
                        id={order.id}
                        status={order.status}
                        date={order.date}
                        items={order.items}
                      />
                    ))
                  }
                </InfiniteScroll>
              )
            }

            {
              !isFetchingOrder && !orders.length && (
                <div className="grow flex flex-col justify-center items-center text-gray-multi">
                  <MdSearchOff className="text-[12rem]" />
                  <div>
                    No {selectedStatus.value} Order Yet
                  </div>
                </div>
              )
            }
          </section>
        </section>
      </section>

      {isDesktop && <Footer />}
    </div>
  )
}

export default OrderListPage