import React, { useCallback, useRef } from 'react'
import { useReactToPrint } from 'react-to-print'
import logo from '@/assets/logo.png'
import { toFormattedDate, toCapitalCase, numberFormatter } from '@/utils/common'

import BottomSheet from '@/components/BottomSheet'
import Label from '@/components/Label'

const OrderInvoice = ({ orderDetail, onClose, visible }) => {

  const contentRef = useRef(null)

  const reactToPrintContent = useCallback(() => {
    return contentRef.current
  }, [contentRef.current])

  const handlePrint = useReactToPrint({
    content: reactToPrintContent,
    documentTitle: `invoice-${orderDetail.id}`
  })

  const products = () => orderDetail.items?.flatMap(item => item.products) || []

  const addressText = address => `${toCapitalCase(address.province?.name)}, ${toCapitalCase(address.regency?.name)}, ${toCapitalCase(address.district?.name)}, ${toCapitalCase(address.village?.name)}, ${address.postalCode}`

  const priceSummaries = () => {
    const grouped = orderDetail.prices.reduce((acc, price) => {
      return {
        ...acc,
        [price.id]: {
          ...price,
          nominal: acc[price.id] ? acc[price.id].nominal + price.nominal : price.nominal,
          id: price.id
        }
      }
    }, {})

    return Object.values(grouped)
  }

  const getProductAmount = product => {
    if (product.downPayment) {
      return Math.ceil(product.price?.offer / 2 * product.quantity)
    }
    return Math.ceil(product.price?.offer * product.quantity)
  }

  const getInvoiceContent = () => (
    <div
      ref={contentRef}
      className="w-full text-xs bg-white-primary border border-black-multi py-2 px-4 flex flex-col lg:text-base lg:py-4 lg:px-6"
    >
      <img
        src={logo}
        alt="logo"
        className="w-16"
      />

      <hr className="mt-3 mb-2 border-black-multi lg:my-4" />

      <div className="flex flex-col">
        <div>
          {orderDetail.user.name}
        </div>
        <div>
          {orderDetail.address?.detail}
        </div>
        <div>
          {addressText(orderDetail.address)}
        </div>
      </div>

      <div className="flex items-center gap-x-4">
        <div className="font-semibold text-base text-red-multi my-2 lg:text-xl lg:my-7">
          Order # {orderDetail.id}
        </div>

        <Label
          type={orderDetail.status !== 'FAILED' && orderDetail.status !== 'CANCELED' ? 'success' : 'error'}
          className="w-16 font-semibold"
        >
          {orderDetail.status !== 'FAILED' && orderDetail.status !== 'CANCELED' ? 'PAID' : 'FAILED'}
        </Label>
      </div>

      <div className="flex w-full gap-x-4 lg:gap-x-8">
        <div className="flex flex-col">
          <div className="text-gray-multi">
            Order Date:
          </div>
          <div>
            {toFormattedDate(orderDetail.date)}
          </div>
        </div>
        <div className="flex flex-col">
          <div className="text-gray-multi">
            Sales Person:
          </div>
          <div>
            Multi Toys
          </div>
        </div>
      </div>

      <section className="flex flex-col mt-4 lg:mt-8">
        <div className="flex gap-x-1 border-y border-gray-multi py-1.5 text-gray-multi lg:py-2.5">
          <div className="w-[40%]">
            Description
          </div>
          <div className="w-[10%] text-center">
            Qty
          </div>
          <div className="w-[25%] text-center">
            Unit Price
          </div>
          <div className="w-[25%] text-center">
            Amount
          </div>
        </div>

        {
          products().map(product => (
            <div
              key={product.sku}
              className="flex gap-x-1 py-1.5 border-b border-gray-multi lg:py-2.5"
            >
              <div className="w-[40%]">
                <div>
                  {product.displayName}
                </div>
                {
                  product.downPayment && (
                    <small className="text-gray-multi">
                      50% Down Payment
                    </small>
                  )
                }
              </div>
              <div className="w-[10%] text-center">
                {product.quantity}
              </div>
              <div className="w-[25%] text-center">
                {numberFormatter(product.price.offer, 'Rp')}
              </div>
              <div className="w-[25%] text-center">
                {numberFormatter(getProductAmount(product), 'Rp')}
              </div>
            </div>
          ))
        }
      </section>

      <section className="flex flex-col mt-4 w-1/2 ml-auto lg:mt-8">
        <div className="border-y border-gray-multi py-1.5 lg:py-2.5">
          {
            priceSummaries().map(price => (
              <div
                key={price.id}
                className="flex justify-between"
              >
                <div>
                  {price.text}
                </div>
                <div>
                  {numberFormatter(price.nominal, 'Rp')}
                </div>
              </div>
            ))
          }
        </div>

        <div className="mt-1.5 flex justify-between text-red-multi font-semibold">
          <div>
            Total
          </div>
          <div>
            {numberFormatter(orderDetail.totalPrice, 'Rp')}
          </div>
        </div>
      </section>

      <section className="mt-10">
        <hr className="mt-auto mb-2 border-black-multi" />

        <section className="flex flex-col gap-y-2 lg:gap-y-0 lg:flex-row lg:justify-between">
          <div>
            <div>
              Phone / WA
            </div>
            <div>
              (+6221)45845792 / 0878-8016-1153
            </div>
          </div>

          <div>
            <div>
              Email
            </div>
            <div>
              order@multitoys.id / order@multitoys.co.id
            </div>
          </div>

          <div>
            <div>
              Web
            </div>
            <div>
              https://multitoys.id
            </div>
          </div>
        </section>
      </section>
    </div>
  )

  return (
    <BottomSheet
      visible={visible}
      onClose={onClose}
      className="lg:!w-[75vw] 2xl:!w-[70vw]"
    >
      <div>
        <div className="text-xl font-semibold text-center mb-5">
          Invoice
        </div>

        <div className="mb-4 lg:w-1/6">
          <button
            type="button"
            className="button-primary"
            onClick={handlePrint}
          >
            Download
          </button>
        </div>

        {getInvoiceContent()}
      </div>
    </BottomSheet>
  )
}

export default OrderInvoice