import React, { useState } from 'react'
import { HiOutlineMenuAlt2 } from 'react-icons/hi'

import MobileMenu from '@/components/MobileMenu'
import BaseHeader from '@/components/BaseHeader'

const GeneralHeader = ({ searchKeywordState }) => {

  const [visibleNavigation, setVisibleNavigation] = useState(false)

  return (
    <>
      <MobileMenu
        visible={visibleNavigation}
        onClose={() => setVisibleNavigation(false)}
      />

      <BaseHeader searchKeywordState={searchKeywordState}>
        <button
          type="button"
          onClick={() => setVisibleNavigation(true)}
        >
          <HiOutlineMenuAlt2 className="text-xl" />
        </button>
      </BaseHeader>
    </>
  )
}

export default GeneralHeader