import React, { useEffect, useState } from 'react'
import useResponsive from '@/hooks/responsive'
import { useParams, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { checkoutSettlement, checkoutSettlementPayment } from '@/store/slices/checkout'
import useAuthentication from '@/hooks/authentication'
import useLoader from '@/hooks/loader'
import toast from 'react-hot-toast'
import { numberFormatter } from '@/utils/common'
import config from '@/constant/config'

import GeneralHeader from '@/components/GeneralHeader'
import TitleHeader from '@/components/TitleHeader'
import Footer from '@/components/Footer'
import CheckoutShippingOptionModal from '@/components/CheckoutShippingOptionModal'
import classNames from 'classnames'

const { path } = config

const CheckoutSettlementPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isDesktop } = useResponsive()
  const { unauthorized } = useAuthentication()
  const { id } = useParams()

  const [, setIsLoading] = useLoader()

  const [visibleShippingOptionModal, setVisibleShippingOptionModal] = useState(false)
  const [checkoutDetail, setCheckoutDetail] = useState()
  const [selectedShippingMethod, setSelectedShippingMethod] = useState()
  const [isPaying, setIsPaying] = useState(false)

  useEffect(() => {
    fetchCheckoutDetail()
  }, [selectedShippingMethod])

  const fetchCheckoutDetail = async () => {
    try {
      setIsLoading(true)

      const [orderId, orderItemId] = id.split('-')
      const { data } = await dispatch(checkoutSettlement({
        orderId,
        orderItemId,
        ...(selectedShippingMethod ? {
          shipping: {
            company: selectedShippingMethod.company,
            type: selectedShippingMethod.type
          }
        }: {})
      })).unwrap()
      setCheckoutDetail(data)

      setIsLoading(false)
    } catch(error) {
      unauthorized(error, () => {
        if (error.data?.error === 'SETTLEMENT_INVOICE_EXIST') {
          toOrderDetailPage()
          return
        }
        toast.error('Something went wrong, please try again')
        setIsLoading(false)
      })
    }
  }

  const toOrderDetailPage = () => {
    const [orderId] = id.split('-')
    navigate(path.orderDetail.replace(':id', orderId))
  }

  const getShippingItems = () => checkoutDetail.item?.products?.map(product => ({ sku: product.sku, quantity: product.quantity }))

  const doPay = async () => {
    try {
      setIsLoading(true)
      setIsPaying(true)

      const [orderId, orderItemId] = id.split('-')
      const { data: invoiceUrl } = await dispatch(checkoutSettlementPayment({
        orderId,
        orderItemId,
        ...(selectedShippingMethod ? {
          shipping: {
            company: selectedShippingMethod.company,
            type: selectedShippingMethod.type,
            destinationAddressId: checkoutDetail.destinationAddressId
          }
        }: {})
      })).unwrap()

      window.location.href = invoiceUrl

      setIsLoading(false)
    } catch(error) {
      unauthorized(error, () => {
        setIsLoading(false)
        setIsPaying(false)
        console.log(error)

        if (error.data?.error === 'BLANK_SHIPPING_METHOD') {
          toast.error('Choose shipping method')
          return
        }
        toast.error('Something went wrong, please try again')
      })
    }
  }

  const productAdjustment = product => checkoutDetail.adjustment?.products?.find(p => p.sku === product.sku)

  return !!checkoutDetail && (
    <>
      <CheckoutShippingOptionModal
        visible={visibleShippingOptionModal}
        onClose={() => setVisibleShippingOptionModal(false)}
        orderId={id.split('-')[0]}
        items={getShippingItems()}
        onSelectShippingMethod={setSelectedShippingMethod}
      />

      <div className="flex flex-col min-h-screen">
        {
          isDesktop ? (
            <GeneralHeader />
          ) : (
            <TitleHeader
              title="Payment Settlement"
              onBackClick={toOrderDetailPage}
            />
          )
        }

        <section className="bg-white-multi grow p-5 relative lg:py-8 flex justify-center">
          <section className="flex w-full lg:gap-x-8 lg:items-start lg:w-[90%] 2xl:w-[70%]">
            <section className="flex flex-col gap-y-5 lg:w-[75%]">
              {
                isDesktop && (
                  <div className="font-semibold text-2xl mb-4">
                    Payment Summary
                  </div>
                )
              }

              {
                !!checkoutDetail.adjustment && (
                  <>
                    <section>
                      <div className="font-semibold mb-1.5">
                        Price Adjustment
                      </div>
                      <div>
                        There's price adjustment for below product's because of:

                        <div className="p-3 bg-[#FFE6D6] mt-2 italic">
                          {checkoutDetail.adjustment.reason}
                        </div>
                      </div>
                    </section>

                    <hr className="border-black-multi" />
                  </>
                )
              }

              <div className="font-semibold mb-1.5">
                Products
              </div>

              <div className="text-sm flex flex-col gap-y-5 2xl:w-2/3">
                {
                  checkoutDetail.item?.products?.map((product, i) => (
                    <div
                      key={i}
                      className="flex items-start"
                    >
                      <img
                        src={product.images[0]}
                        className="object-contain w-[28%] aspect-square lg:w-[20%] 2xl:w-[24%]"
                        loading="lazy"
                      />

                      <div className="w-full ml-3 flex flex-col lg:ml-4">
                        <div
                          className="font-semibold line-clamp-2"
                          title={product.displayName}
                        >
                          {product.displayName}
                        </div>

                        <div className="my-2.5 2xl:my-4">
                          <div className="flex justify-between w-1/2 2xl:w-1/3">
                            <div>
                              <div className={classNames({ 'line-through italic': !!productAdjustment(product) })}>
                                {numberFormatter(product.price?.offer, 'Rp')}
                              </div>
                              {
                                !!productAdjustment(product) && (
                                  <div className="text-red-multi">
                                    {numberFormatter(productAdjustment(product).new, 'Rp')}
                                  </div>
                                )
                              }
                            </div>
                            <div>
                              x{product.quantity}
                            </div>
                          </div>

                          {
                            product.downPayment && (
                              <div className="text-xs text-gray-multi-4">
                                50% Down Payment
                              </div>
                            )
                          }
                        </div>
                      </div>
                    </div>
                  ))
                }
              </div>

              {
                !!checkoutDetail.item.shipping && (
                  <div className="mt-6 flex flex-col items-end gap-y-2 w-full">
                    <div className="flex justify-between w-full items-center border border-black-multi p-3">
                      <div className="flex flex-col items-start">
                        <div className="flex items-center">
                          <div className="font-semibold">
                            {checkoutDetail.item.shipping.courierName}
                          </div>
                          <small className="text-gray-multi ml-1">
                            ({checkoutDetail.item.shipping.duration})
                          </small>
                        </div>
                        <small>
                          {checkoutDetail.item.shipping.courierServiceName}
                        </small>
                      </div>

                      <div>
                        {numberFormatter(checkoutDetail.item.shipping.price, 'Rp')}
                      </div>
                    </div>

                    <button
                      type="button"
                      className="text-[11px] text-black-multi font-semibold"
                      onClick={() => setVisibleShippingOptionModal(true)}
                    >
                      Change
                    </button>
                  </div>
                )
              }
              {
                !checkoutDetail.item.shipping && (
                  <button
                    type="button"
                    className="mt-6 button-secondary w-full lg:w-1/3"
                    onClick={() => setVisibleShippingOptionModal(true)}
                  >
                    Choose shipping method
                  </button>
                )
              }
            </section>

            {
              isDesktop && (
                <section className="sticky top-20 left-0 w-1/3 ml-auto self-start 2xl:w-1/4">
                  <section className="lg:border lg:border-gray-multi p-4 mb-4">
                    <div className="font-semibold text-lg">
                      Order Detail
                    </div>

                    <div className="my-8">
                      {
                        checkoutDetail?.prices?.map(price => (
                          <div
                            key={price.id}
                            className={classNames({
                              'text-sm flex justify-between my-1': true,
                              'text-green-multi': price.nominal < 0
                            })}
                          >
                            <div>
                              {price.text}
                            </div>
                            <div>
                              {numberFormatter(price.nominal, 'Rp')}
                            </div>
                          </div>
                        ))
                      }
                    </div>

                    <div className="font-semibold">
                      Total Price
                    </div>
                    <div className="text-red-multi font-semibold text-xl">
                      {numberFormatter(checkoutDetail.totalPrice, 'Rp')}
                    </div>

                    <button
                      type="submit"
                      className={classNames({
                        'button-primary mt-4 mb-3': true,
                        'disabled': isPaying
                      })}
                      disabled={isPaying}
                      onClick={doPay}
                    >
                      Pay Now
                    </button>
                  </section>
                </section>
              )
            }
          </section>
        </section>

        {
          !isDesktop && (
            <section className="sticky bottom-0 left-0 bg-white-multi px-4 py-3 border-t-2 border-black-multi">
              <div className="font-semibold text-sm">
                Total Price
              </div>
              <div className="flex justify-between items-center">
                <div className="text-red-multi font-semibold text-xl">
                  {numberFormatter(checkoutDetail.totalPrice, 'Rp')}
                </div>
              </div>

              <button
                type="submit"
                className={classNames({
                  'button-primary mt-2': true,
                  'disabled': isPaying
                })}
                disabled={isPaying}
                onClick={doPay}
              >
                Pay Now
              </button>
            </section>
          )
        }

        {isDesktop && <Footer />}
      </div>
    </>
  )
}

export default CheckoutSettlementPage