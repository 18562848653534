import React, { useEffect } from 'react'
import { RouterProvider } from 'react-router-dom'
import { loginInfo, setUser, setIsGettingUser, userSelector } from '@/store/slices/user'
import { setIsMobile, setIsDesktop, setIsTablet, setIsLargeDesktop } from '@/store/slices/responsive'
import { isMobile, isDesktop, isTablet, isLargeDesktop } from '@/utils/responsive'
import { countCartItems } from '@/store/slices/cart'
import { useDispatch, useSelector } from 'react-redux'
import router from './constant/routes'
import { Settings } from 'luxon'

const App = () => {
  const dispatch = useDispatch()

  const { isGettingUser } = useSelector(userSelector)

  useEffect(() => {
    Settings.defaultZone = 'Asia/Jakarta'

    const getLoginInfo = async () => {
      try {
        const response = await dispatch(loginInfo()).unwrap()
        dispatch(setIsGettingUser(false))
        dispatch(setUser(response.data))
      } catch(err) {
        dispatch(setIsGettingUser(false))
      }
    }
    getLoginInfo()
    getCartItemsCount()

    dispatch(setIsMobile(isMobile()))
    dispatch(setIsDesktop(isDesktop()))
    dispatch(setIsTablet(isTablet()))
    dispatch(setIsLargeDesktop(isLargeDesktop()))
  }, [])

  const getCartItemsCount = async () => {
    try {
      await dispatch(countCartItems()).unwrap()
    } catch(error) {
      console.log(error)
    }
  }

  return (
    <>
      {
        !isGettingUser && (
          <RouterProvider router={router} />
        )
      }
    </>
  )
}

export default App