import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import config from '@/constant/config'
import { useDispatch } from 'react-redux'
import { updateUser, loginInfo, setIsGettingUser, setUser } from '@/store/slices/user'
import { DateTime } from 'luxon'
import toast from 'react-hot-toast'
import useLoader from '@/hooks/loader'
import useResponsive from '@/hooks/responsive'
import loginIllustration from '@/assets/login-illustration.png'

import GeneralHeader from '@/components/GeneralHeader'
import TitleHeader from '@/components/TitleHeader'
import RadioButton from '@/components/RadioButton'
import DatePicker from '@/components/DatePicker'
import NumberInput from '@/components/NumberInput'
import Footer from '@/components/Footer'

const { path } = config

const PHONE_REGEX = /^(\+62|62|0)8[1-9][0-9]{6,10}$/

const validationSchema = yup.object().shape({
  dob: yup.date().notRequired(),
  phone: yup.string()
    .test('validPhone', phone => !!phone ? PHONE_REGEX.test(phone) : true),
  gender: yup.string().notRequired()
})

const RegisterCompletionPage = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  const { isDesktop } = useResponsive()

  const [dob, setDob] = useState()
  const [, setIsLoading] = useLoader()

  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  })

  useEffect(() => {
    if (!location.state?.email) {
      navigate(path.base)
      return
    }

    register('dob')
  }, [])

  useEffect(() => {
    setValue('dob', dob)
  }, [dob])

  const getLoginInfo = async () => {
    try {
      const response = await dispatch(loginInfo()).unwrap()
      dispatch(setIsGettingUser(false))
      dispatch(setUser(response.data))

      setIsLoading(false)
    } catch(err) {
      setIsLoading(false)
      dispatch(setIsGettingUser(false))
    }
  }

  const doCompleteRegistration = async form => {
    try {
      setIsLoading(true)

      const requestBody = {
        email: location.state.email,
        phone: form.phone,
        gender: form.gender,
        dob: form.dob && DateTime.fromJSDate(form.dob)
          .setZone('Asia/Jakarta')
          .toJSDate()
      }
      const sanitizedEntries = Object.entries(requestBody)
        .filter(([, value]) => !!value)
      const sanitizedRequestBody = Object.fromEntries(sanitizedEntries)

      if (sanitizedEntries.length) {
        await dispatch(updateUser(sanitizedRequestBody)).unwrap()
      }

      await getLoginInfo()

      const redirectionPage = searchParams.get('page')
      if (redirectionPage) {
        navigate(window.atob(redirectionPage))
      } else {
        navigate(path.base)
      }
    } catch(error) {
      console.log(error)
      setIsLoading(false)
      toast.error('Something went wrong, please try again')
    }
  }

  return (
    <>
      <div className="flex flex-col h-screen">
        {
          isDesktop ? (
            <GeneralHeader />
          ) : (
            <TitleHeader
              title="Profile Info"
              noBackButton
            />
          )
        }

        <section className="bg-white-multi grow flex">
          {
            isDesktop && (
              <div className="w-1/2 flex justify-center items-center">
                <img
                  src={loginIllustration}
                  className="w-96"
                  alt="illustration"
                />
              </div>
            )
          }

          <form
            onSubmit={handleSubmit(doCompleteRegistration)}
            className="w-full p-5 flex flex-col h-full lg:py-8 lg:px-20 lg:w-1/2"
          >
            {
              isDesktop && (
                <div className="lg:text-2xl lg:font-semibold lg:mb-8">
                  Profile Information
                </div>
              )
            }

            <div className="mb-4">
              <div className="font-semibold mb-1.5">
                Date of Birth
              </div>

              <DatePicker
                value={dob}
                onChange={setDob}
                placeholder="Select your date of birth"
                maxDate={new Date()}
                className="w-full"
              />
            </div>

            <div className="mb-4">
              <div className="font-semibold mb-1.5">
                Phone Number
              </div>
              <NumberInput
                name="phone"
                control={control}
                allowLeadingZeros
                placeholder="Enter your phone number"
                className="input-primary"
              />
              {
                errors.phone && (
                  <small className="text-red-multi italic">
                    Invalid phone number
                  </small>
                )
              }
            </div>

            <div className="mb-4">
              <div className="font-semibold mb-1.5">
                Gender
              </div>

              <div className="flex">
                <RadioButton
                  id="MALE"
                  text="Male"
                  value="MALE"
                  name="GENDER"
                  className="mr-6"
                  {...register('gender')}
                />
                <RadioButton
                  id="FEMALE"
                  text="Female"
                  value="FEMALE"
                  name="GENDER"
                  {...register('gender')}
                />
              </div>
            </div>

            <div className="mt-auto lg:my-6 lg:self-start">
              <button
                type="submit"
                className="button-primary lg:px-8"
              >
                Complete Setup
              </button>
            </div>
          </form>
        </section>

        {
          isDesktop && <Footer />
        }
      </div>
    </>
  )
}

export default RegisterCompletionPage