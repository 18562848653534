import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { formatDate, numberFormatter } from '@/utils/common'
import { FaChevronUp } from 'react-icons/fa6'
import classNames from 'classnames'
import config from '@/constant/config'

import OrderStatusLabel from '@/components/OrderStatusLabel'
import ProductAvailabilityLabel from '@/components/ProductAvailabilityLabel'
import OrangeTicker from '@/components/OrangeTicker'
import Label from '@/components/Label'
import ShippingHistoryModal from '@/components/ShippingHistoryModal'

const { path } = config

const InformationParagraph = ({ title, value, className = '' }) => (
  <div className={'flex w-full gap-x-2 mb-1.5 ' + className}>
    <div className="w-[27%] text-gray-multi-3">
      {title}
    </div>
    <div
      title={value}
      className="line-clamp-2 w-[73%] text-right"
    >
      {value}
    </div>
  </div>
)

const OrderDetailItem = ({
  id,
  orderId,
  index,
  status,
  products,
  shipping,
  subtotal,
  invoices,
  visibleAdjustmentTicker,
  visiblePendingAdjustmentTicker
}) => {
  const navigate = useNavigate()

  const [expandSubtotal, setExpandSubtotal] = useState(false)
  const [visibleShippingHistoryModal, setVisibleShippingHistoryModal] = useState(false)

  const getSubtotal = () => subtotal.reduce((acc, curr) => acc + curr.nominal, 0)

  const toCheckoutSettlementPage = () => navigate(path.checkoutSettlement.replace(':id', `${orderId}-${id}`))

  const toCheckoutAdjustmentPage = () => navigate(path.checkoutAdjustment.replace(':id', `${orderId}-${id}`))

  const settlementInvoice = () => invoices.find(invoice => invoice.description === `SETTLEMENT_${id}`)

  const adjustmentInvoice = () => invoices.find(invoice => invoice.description === `ADJUSTMENT_${id}`)

  const hasUnsettledPayment = () => {
    const invoice = settlementInvoice()
    return id !== 'READY_STOCK' && status === 'READY_TO_SHIP' && !invoice
  }

  const hasPendingSettlementInvoice = () => {
    const invoice = settlementInvoice()
    return id !== 'READY_STOCK' && status === 'READY_TO_SHIP' && !!invoice && invoice.status === 'PENDING'
  }

  return (
    <>
      <ShippingHistoryModal
        visible={visibleShippingHistoryModal}
        onClose={() => setVisibleShippingHistoryModal(false)}
        shippingId={shipping?.detail?.id}
      />

      <section>
        <div className="flex items-center justify-between">
          <div className="font-semibold mb-1 lg:text-base">
            Order {index + 1}
          </div>

          <div className="flex">
            {
              (hasUnsettledPayment() || hasPendingSettlementInvoice()) && (
                <div className="mr-1.5">
                  <Label type="warning">
                    Unsettled
                  </Label>
                </div>
              )
            }
            <OrderStatusLabel status={status} />
          </div>
        </div>

        {/* Products */}
        <section>
          <div>
            {
              products.map(product => (
                <div
                  key={product.sku}
                  className="flex mb-4"
                >
                  <img
                    src={product.images[0]}
                    alt="product image"
                    className="w-16 aspect-square object-contain lg:w-24"
                  />

                  <div className="w-full flex flex-col items-start ml-2 lg:ml-5">
                    <ProductAvailabilityLabel
                      availability={product.availability}
                      className="text-[9.5px] !py-0.5 mb-1.5"
                    />

                    <div className="mb-2">
                      {product.displayName}
                    </div>

                    {
                      product.downPayment && (
                        <div className="text-xs text-gray-multi-3 mb-1 lg:text-sm">
                          50% Down Payment
                        </div>
                      )
                    }
                    <div className="text-xs mb-1 lg:text-sm">
                      Quantity: {product.quantity}
                    </div>

                    <div className="font-semibold">
                      {numberFormatter(product.price?.offer, 'Rp')}
                      <small className="font-light">
                        &nbsp; / unit
                      </small>
                    </div>
                  </div>
                </div>
              ))
            }
          </div>
        </section>

        {
          hasUnsettledPayment() && (
            <section className="mb-3 lg:my-5">
              <OrangeTicker>
                <div className="flex flex-col">
                  <div>
                    Please settle your payment
                    <button
                      type="button"
                      className="ml-1 text-blue-400"
                      onClick={toCheckoutSettlementPage}
                    >
                      here
                    </button>
                  </div>
                  <div className="text-xs italic text-gray-multi-3">
                    Remaining of down payment product (if any) and shipping fee
                  </div>
                </div>
              </OrangeTicker>
            </section>
          )
        }

        {
          hasPendingSettlementInvoice() && (
            <section className="mb-3 lg:my-5">
              <OrangeTicker>
                <div>
                  Please complete your payment
                  <a
                    href={settlementInvoice().invoiceUrl}
                    className="ml-1 text-blue-400"
                  >
                    here
                  </a>
                </div>
              </OrangeTicker>
            </section>
          )
        }

        {
          visibleAdjustmentTicker && (
            <section className="mb-3 lg:my-5">
                <OrangeTicker>
                  <div>
                    There's price adjustment for your order. Click
                    <button
                      type="button"
                      className="mx-1 text-blue-400"
                      onClick={toCheckoutAdjustmentPage}
                    >
                      here
                    </button>
                    to see more detail
                  </div>
                </OrangeTicker>
              </section>
          )
        }

        {
          visiblePendingAdjustmentTicker && (
            <section className="mb-3 lg:my-5">
              <OrangeTicker>
                <div>
                  Please complete your adjustment price payment
                  <a
                    href={adjustmentInvoice().invoiceUrl}
                    className="ml-1 text-blue-400"
                  >
                    here
                  </a>
                </div>
              </OrangeTicker>
            </section>
          )
        }

        <section>
          {
            id !== 'READY_STOCK' && (
              <InformationParagraph
                title="Est. Arrival"
                value={formatDate(new Date(Number(id)), true)}
                className="mb-2"
              />
            )
          }

          {
            shipping?.rate && (
              <InformationParagraph
                title="Shipping"
                value={`${shipping?.rate.courierName} - ${shipping?.rate.courierServiceName} (${shipping?.rate.duration})`}
                className="mb-2"
              />
            )
          }

          {
            shipping?.detail?.courier?.waybillId && (
              <div className="flex flex-col items-end mb-2">
                <div className="flex w-full gap-x-2">
                  <div className="w-[27%] text-gray-multi-3">
                    Waybill Number
                  </div>
                  <div className="w-[73%] text-right">
                    {shipping?.detail?.courier?.waybillId}
                  </div>
                </div>

                <button
                  type="button"
                  className="text-xs text-blue-400 fond-semibold"
                  onClick={() => setVisibleShippingHistoryModal(true)}
                >
                  Track Shipping
                </button>
              </div>
            )
          }

          <div className="flex items-center">
            <InformationParagraph
              title="Subtotal"
              value={numberFormatter(getSubtotal(), 'Rp')}
            />

            {
              subtotal.length > 1 && (
                <FaChevronUp
                  className={classNames({
                    'ml-2 text-gray-multi transition duration-100 cursor-pointer': true,
                    'rotate-0': !expandSubtotal,
                    'rotate-180': expandSubtotal
                  })}
                  onClick={() => setExpandSubtotal(v => !v)}
                />
              )
            }
          </div>

          <div>
            {
              subtotal.length > 1 && expandSubtotal && subtotal.map(p => (
                <div
                  key={p.id}
                  className="w-2/5 ml-auto flex justify-between text-gray-multi-3"
                >
                  <div className="w-1/2">
                    {p.text}
                  </div>
                  <div>
                    {numberFormatter(p.nominal, 'Rp')}
                  </div>
                </div>
              ))
            }
          </div>
        </section>

        <hr className="border-black-multi my-5 lg:my-8" />
      </section>
    </>
  )
}

export default OrderDetailItem