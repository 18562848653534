/** @type {import('tailwindcss').Config} */
module.exports = {
  content: [
    './src/**/*.{js,jsx,ts,tsx}'
  ],
  theme: {
    extend: {
      animation: {
        enter: 'fadeInTop 300ms ease-out',
        leave: 'fadeOutBottom 300ms ease-in forwards',
      },
      keyframes: {
        fadeInTop: {
          '0%': {
            opacity: '0',
            transform: 'translateY(-2rem)',
          },
          '100%': {
            opacity: '1',
            transform: 'translateY(0)',
          }
        },
        fadeOutBottom: {
          '0%': {
            opacity: '1',
          },
          '100%': {
            opacity: '0',
          }
        }
      },
      fontFamily: {
        'general-sans': ['General Sans', 'sans-serif']
      },
      colors: {
        yellow: {
          multi: '#FFE369',
          'multi-2': '#E0B700'
        },
        white: {
          primary: '#FFFFFF',
          multi: '#FFFAE7'
        },
        black: {
          primary: '#000000',
          multi: '#212121'
        },
        gray: {
          multi: '#4F4F4F',
          'multi-2': '#D9D9D9',
          'multi-3': '#828282',
          'multi-4': '#A1A1A1'
        },
        red: {
          multi: '#DF0023'
        },
        blue: {
          multi: '#56CCF2'
        },
        green: {
          multi: '#219653'
        },
        orange: {
          multi: '#F2994A'
        }
      },
      letterSpacing: {
        'ultra-wide': '0.3em'
      }
    },
  },
  plugins: [
    require('tailwind-scrollbar')({ nocompatible: true })
  ]
}
