import React from 'react'
import useLoader from '@/hooks/loader'
import { Toaster } from 'react-hot-toast'
import { Outlet } from 'react-router-dom'

import FullPageLoader from '@/components/FullPageLoader'

const Root = () => {
  const [isLoading] = useLoader()

  return (
    <>
      {
        isLoading && <FullPageLoader />
      }
      <Outlet />
      <Toaster
        toastOptions={{
          className: "!text-sm lg:!text-base !bg-white-multi !shadow-none !rounded-none border border-black-multi"
        }}
      />
    </>
  )
}

export default Root