import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { numberFormatter } from '@/utils/common'
import { BsTrash } from 'react-icons/bs'
import { deleteFromCart, updateQuantity, updateDownPayment } from '@/store/slices/cart'
import { useDispatch } from 'react-redux'
import useAuthentication from '@/hooks/authentication'
import useLoader from '@/hooks/loader'
import toast from 'react-hot-toast'
import config from '@/constant/config'

import ProductQuantityCounter from '@/components/ProductQuantityCounter'
import Checkbox from '@/components/Checkbox'
import classNames from 'classnames'

const { path } = config

const CartCard = ({
  sku,
  imageUrl,
  displayName,
  price,
  originalPrice,
  stock,
  maxQuantityPerOrder,
  availability,
  quantity,
  downPayment,
  disabled,
  onSuccessRemoveFromCart,
  getPrice
}) => {
  const dispatch = useDispatch()

  const quantityState = useState(quantity)

  const { unauthorized } = useAuthentication()
  const [isLoading, setIsLoading] = useLoader()

  const [dpChecked, setDpChecked] = useState(downPayment || false)
  const [totalPrice, setTotalPrice] = useState(price * quantity)

  useEffect(() => {
    const finalPrice = price * quantityState[0]
    setTotalPrice(dpChecked ? Math.ceil(finalPrice / 2) : finalPrice)
  }, [quantityState[0], dpChecked])

  useEffect(() => {
    getPrice && getPrice(sku, totalPrice)
  }, [totalPrice])

  const visibleDpCheckbox = (
    availability === 'PREORDER' ||
    availability === 'LATE_PREORDER' ||
    availability === 'WAITING_LIST'
  ) && !disabled

  const removeFromCart = async () => {
    try {
      setIsLoading(true)

      await dispatch(deleteFromCart([sku])).unwrap()
      onSuccessRemoveFromCart && onSuccessRemoveFromCart(sku)

      toast.success('Product removed from cart')

      setIsLoading(false)
    } catch(error) {
      unauthorized(error, () => {
        setIsLoading(false)
        console.log(error)
        toast.error('Something went wrong, please try again')
      })
    }
  }

  const addQuantity = async () => {
    try {
      setIsLoading(true)

      await dispatch(updateQuantity({
        sku,
        quantity: quantityState[0] + 1
      })).unwrap()

      setIsLoading(false)
    } catch(error) {
      unauthorized(error, () => {
        setIsLoading(false)
        console.log(error)
        toast.error('Something went wrong, please try again')
      })
    }
  }

  const subtractQuantity = async () => {
    try {
      setIsLoading(true)

      await dispatch(updateQuantity({
        sku,
        quantity: quantityState[0] - 1
      })).unwrap()

      setIsLoading(false)
    } catch(error) {
      unauthorized(error, () => {
        setIsLoading(false)
        console.log(error)
        toast.error('Something went wrong, please try again')
      })
    }
  }

  const pdpUrl = () => {
    const encodedName = encodeURIComponent(displayName)
    return path.product.replace(':name', encodedName)
      .replace(':sku', sku)
  }

  const doUpdateDownPayment = async e => {
    try {
      setDpChecked(e.target.checked)

      setIsLoading(true)

      await dispatch(updateDownPayment({
        sku,
        downPayment: e.target.checked
      }))

      setIsLoading(false)
    } catch(error) {
      unauthorized(error, () => {
        setIsLoading(false)
        console.log(error)
        toast.error('Something went wrong, please try again')
      })
    }
  }

  return (
    <>
      <div
        key={sku}
        className={classNames({
          'border border-black-multi flex relative': true,
          'opacity-50': disabled,
          'border-red-multi': stock > 0 && quantityState[0] === 0
        })}
      >
        <img
          src={imageUrl}
          alt=""
          loading="lazy"
          className="object-contain w-1/3 aspect-square lg:w-1/4 2xl:w-1/6"
        />

        <div className="p-2 flex flex-col w-full lg:p-4">
          <Link
            to={pdpUrl()}
            className="text-sm font-semibold line-clamp-2 lg:text-base"
            title={displayName}
          >
            {displayName}
          </Link>

          <div className="mt-auto">
            <div className="text-red-multi font-semibold">
              {numberFormatter(price, 'Rp')}
            </div>
            {
              originalPrice > price && (
                <div className="text-gray-multi-3 text-sm line-through">
                  {numberFormatter(originalPrice, 'Rp')}
                </div>
              )
            }

            <div className="mt-1.5 flex justify-between items-end">
              <ProductQuantityCounter
                quantityState={quantityState}
                maxQuantityPerOrder={maxQuantityPerOrder}
                stock={stock}
                disabled={disabled || isLoading}
                onAddQuantity={addQuantity}
                onSubtractQuantity={subtractQuantity}
              />

              <button
                type="button"
                onClick={() => removeFromCart(sku)}
              >
                <BsTrash className="text-gray-multi" />
              </button>
            </div>
          </div>
        </div>
      </div>

      {
        visibleDpCheckbox && (
          <div className="mt-2">
            <Checkbox
              label="I want to pay 50% of the downpayment (DP)"
              id={sku}
              size="xs"
              onChange={doUpdateDownPayment}
              checked={dpChecked}
            />
          </div>
        )
      }
    </>
  )
}

export default CartCard