import React from 'react'
import { useController } from 'react-hook-form'
import { NumericFormat  } from 'react-number-format'

const NumberInput = ({
  name,
  control,
  separator = '',
  ...props
}) => {

  const { field } = useController({
    name,
    control
  })

  return (
    <NumericFormat
      value={field.value}
      onValueChange={values => field.onChange(values.value)}
      allowNegative={false}
      decimalScale={0}
      thousandSeparator={separator}
      decimalSeparator=','
      {...props}
    />
  )
}

export default NumberInput