import React, { useEffect, useState } from 'react'
import { IoSearchOutline, IoCloseOutline } from 'react-icons/io5'
import { HiUser } from 'react-icons/hi'
import { AiTwotoneHeart } from 'react-icons/ai'
import { FaList } from 'react-icons/fa'
import { BiLogOut } from 'react-icons/bi'
import { useNavigate } from 'react-router-dom'
import config from '@/constant/config'
import { userSelector, logout, setUser } from '@/store/slices/user'
import { setCount } from '@/store/slices/cart'
import { useDispatch, useSelector } from 'react-redux'
import toast from 'react-hot-toast'
import { motion } from 'framer-motion'
import useLoader from '@/hooks/loader'
import useResponsive from '@/hooks/responsive'

import SideNavigation from '@/components/SideNavigation'

const { path } = config

const PUBLIC_MENUS = [
  {
    key: 'SEARCH',
    name: 'Search',
    icon: <IoSearchOutline />,
    path: path.search
  },
  {
    key: 'LOGIN_REGISTER',
    name: 'Log In/Sign Up',
    icon: <HiUser />,
    path: path.login
  }
]

const AUTHENTICATED_MENUS = [
  {
    key: 'SEARCH',
    name: 'Search',
    icon: <IoSearchOutline />,
    path: path.search
  },
  {
    key: 'PROFILE',
    name: 'Profile',
    icon: <HiUser />,
    path: path.profile
  },
  {
    key: 'WISHLIST',
    name: 'Wishlist',
    icon: <AiTwotoneHeart />,
    path: path.wishlist
  },
  {
    key: 'ORDERS',
    name: 'Orders',
    icon: <FaList />,
    path: path.order
  },
  {
    key: 'LOGOUT',
    name: 'Logout',
    icon: <BiLogOut />,
    action: async (navigate, dispatch, setIsLoading) => {
      try {
        setIsLoading(true)

        await dispatch(logout()).unwrap()
        dispatch(setUser(null))
        dispatch(setCount(null))

        setIsLoading(false)
        navigate(path.login)
      } catch(error) {
        setIsLoading(false)
        toast.error('Something went wrong, please try again')
      }
    }
  }
]

const MenuItem = ({ name, icon, ...rest }) => {
  return (
    <motion.button
      animate="open"
      initial="closed"
      exit="closed"
      variants={{
        open: {
          opacity: 1,
          transition: {
            duration: 0.05,
            ease: 'easeOut',
            delay: 0.02
          }
        },
        closed: {
          opacity: 0,
          transition: {
            duration: 0.05,
            ease: 'easeOut',
            delay: 0.02
          }
        }
      }}
      type="button"
      {...rest}
    >
      <div className="text-yellow-multi flex items-center px-3 py-5">
        {icon}
        <div className="ml-5">
          {name}
        </div>
      </div>
      <div className="w-full h-0.5 bg-gray-multi" />
    </motion.button>
  )
}

const MobileMenu = ({ visible, onClose }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { user } = useSelector(userSelector)
  const { isDesktop } = useResponsive()

  const [, setIsLoading] = useLoader()

  const [menus, setMenus] = useState([])

  useEffect(() => {
    if (user) {
      setMenus(AUTHENTICATED_MENUS.filter(menu => isDesktop ? menu.key !== 'SEARCH' : true))
    } else {
      setMenus(PUBLIC_MENUS.filter(menu => isDesktop ? menu.key !== 'SEARCH' : true))
    }
  }, [user])

  const toPageOrAction = async menu => {
    if (menu.path) {
      navigate(menu.path)
      onClose()
      return
    }

    menu.action && await menu.action(navigate, dispatch, setIsLoading)
    onClose()
  }

  return (
    <SideNavigation
      visible={visible}
      onClose={onClose}
    >
      <button
        type="button"
        className="text-yellow-multi text-3xl self-end"
        onClick={onClose}
      >
        <IoCloseOutline />
      </button>
      {
        menus.map(menu => (
          <MenuItem
            key={menu.key}
            name={menu.name}
            icon={menu.icon}
            onClick={() => toPageOrAction(menu)}
          />
        ))
      }
    </SideNavigation>
  )
}

export default MobileMenu