import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { HiPencilAlt } from 'react-icons/hi'
import { BiSolidPencil } from 'react-icons/bi'
import { useSelector, useDispatch } from 'react-redux'
import { userSelector } from '@/store/slices/user'
import { getAddresses } from '@/store/slices/address'
import useAuthentication from '@/hooks/authentication'
import styled from 'styled-components'
import { DateTime } from 'luxon'
import config from '@/constant/config'
import toast from 'react-hot-toast'
import useResponsive from '@/hooks/responsive'

import TitleHeader from '@/components/TitleHeader'
import GeneralHeader from '@/components/GeneralHeader'
import Footer from '@/components/Footer'

const Container = styled.div`
  border-bottom: 1px solid #373737;
`

const { gender } = config.wording
const { path } = config

const ProfilePage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { isDesktop } = useResponsive()

  const { user } = useSelector(userSelector)

  const { unauthorized } = useAuthentication()

  const [addresses, setAddresses] = useState([])

  useEffect(() => {
    fetchAddresses()
  }, [])

  const fetchAddresses = async () => {
    try {
      const { data } = await dispatch(getAddresses()).unwrap()
      setAddresses(data)
    } catch(error) {
      unauthorized(error, () => toast.error('Something went wrong, please try again'))
    }
  }

  const getDob = () => {
    if (user.dob) {
      return DateTime.fromJSDate(new Date(user.dob))
        .toFormat('dd MMMM yyyy')
    }
    return '-'
  }

  if (!user) {
    return <div className="bg-white-multi h-screen" />
  }

  if (!isDesktop) {
    return (
      <div className="flex flex-col h-screen">
        <TitleHeader
          title="Profile"
          backUrl={path.base}
          slot={() => (
            <button
              type="button"
              className="ml-auto"
              onClick={() => navigate(path.editProfile)}
            >
              <HiPencilAlt className="text-lg" />
            </button>
          )}
        />

        <section className="bg-black-multi grow">
          <div className="text-2xl pt-5 px-5">
            <Container className="text-yellow-multi font-semibold pb-5">
              {user.name}
            </Container>
          </div>

          <div className="text-base pt-5 px-5">
            <Container className="pb-5 flex justify-between">
              <div className="text-yellow-multi font-semibold">
                Email
              </div>

              <div className="text-white-multi text-sm w-1/2 break-words text-right">
                {user.email}
              </div>
            </Container>
          </div>

          <div className="text-base pt-5 px-5">
            <Container className="pb-5 flex justify-between">
              <div className="text-yellow-multi font-semibold">
                Phone
              </div>

              <div className="text-white-multi text-sm">
                {user.phone || '-'}
              </div>
            </Container>
          </div>

          <div className="text-base pt-5 px-5">
            <Container className="pb-5 flex justify-between">
              <div className="text-yellow-multi font-semibold">
                Date of Birth
              </div>

              <div className="text-white-multi text-sm">
                {getDob()}
              </div>
            </Container>
          </div>

          <div className="text-base pt-5 px-5">
            <Container className="pb-5 flex justify-between">
              <div className="text-yellow-multi font-semibold">
                Gender
              </div>

              <div className="text-white-multi text-sm">
                {gender[user.gender] || '-'}
              </div>
            </Container>
          </div>

          {
            !!addresses.length && (
              <div className="text-base pt-5 px-5">
                <Container className="pb-5 flex flex-col">
                  <div className="text-yellow-multi font-semibold mb-4">
                    Address
                  </div>

                  {
                    addresses.map(address => (
                      <div
                        key={address.id}
                        className="mb-2"
                      >
                        <div className="flex items-center py-2.5">
                          <div className="text-yellow-multi font-semibold text-sm">
                            {address.name}
                          </div>
                          {
                            address.primary && (
                              <div>
                                <div className="ml-2 px-2.5 text-[10px] rounded-full bg-yellow-multi text-black-multi">
                                  Primary
                                </div>
                              </div>
                            )
                          }
                        </div>

                        <div className="text-white-multi text-sm">
                          {address.detail}
                        </div>
                      </div>
                    ))
                  }
                </Container>
              </div>
            )
          }
        </section>
      </div>
    )
  }

  return (
    <section className="flex flex-col h-screen">
      <GeneralHeader />

      <section className="bg-white-multi grow py-8 px-20">
        <div className="font-semibold text-xl mb-4">
          Profile
        </div>

        <div className="flex gap-x-4">
          <div className="bg-black-multi text-yellow-multi w-1/3 p-6 self-start">
            <div className="font-semibold text-xl">
              {user.name}
            </div>

            <button
              type="button"
              className="flex items-center gap-x-3 mt-24"
              onClick={() => navigate(path.editProfile)}
            >
              <BiSolidPencil />
              <div>
                Edit Profile
              </div>
            </button>
          </div>

          <div className="w-2/3">
            <Container className="p-3 text-base flex justify-between">
              <div className="text-black-multi font-semibold">
                Email
              </div>

              <div className="text-black-multi text-sm w-1/2 break-words text-right">
                {user.email}
              </div>
            </Container>

            <Container className="p-3 text-base flex justify-between">
              <div className="text-black-multi font-semibold">
                Phone
              </div>

              <div className="text-black-multi text-sm w-1/2 break-words text-right">
                {user.phone || '-'}
              </div>
            </Container>

            <Container className="p-3 text-base flex justify-between">
              <div className="text-black-multi font-semibold">
                Date of Birth
              </div>

              <div className="text-black-multi text-sm w-1/2 break-words text-right">
                {getDob()}
              </div>
            </Container>

            <Container className="p-3 text-base flex justify-between">
              <div className="text-black-multi font-semibold">
                Gender
              </div>

              <div className="text-black-multi text-sm w-1/2 break-words text-right">
                {gender[user.gender] || '-'}
              </div>
            </Container>

            {
              !!addresses.length && (
                <Container className="p-3 text-base">
                  {
                    addresses.map(address => (
                      <div
                        key={address.id}
                        className="mb-2"
                      >
                        <div className="flex items-center py-2.5">
                          <div className="text-black-multi font-semibold text-sm">
                            {address.name}
                          </div>
                          {
                            address.primary && (
                              <div>
                                <div className="ml-2 px-2.5 text-[10px] rounded-full bg-black-multi text-yellow-multi">
                                  Primary
                                </div>
                              </div>
                            )
                          }
                        </div>

                        <div className="text-black-multi text-sm">
                          {address.detail}
                        </div>
                      </div>
                    ))
                  }
                </Container>
              )
            }

          </div>
        </div>
      </section>

      <Footer />
    </section>
  )
}

export default ProfilePage