import React from 'react'

const TextComponent = ({ component }) => {
  return (
    <div
      dangerouslySetInnerHTML={{ __html: component.text }}
      className="!text-black-primary text-sm lg:text-base"
    />
  )
}

export default TextComponent
