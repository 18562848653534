import React from 'react'
import { userSelector } from '@/store/slices/user' 
import { useSelector } from 'react-redux'
import { Navigate, useLocation } from 'react-router-dom'
import config from '@/constant/config'

const { path } = config

const ProtectedRoute = ({ children }) => {
  const location = useLocation()
  const { user, isGettingUser } = useSelector(userSelector)

  return (
    <>
      {
        !isGettingUser && !!user && children
      }
      {
        !isGettingUser && !user && <Navigate to={{ pathname: path.login, search: `?page=${window.btoa(location.pathname)}` }}  />
      }
    </>
  )
}

export default ProtectedRoute