import { DateTime } from "luxon"

export const ensureTwoDigits = num => ('0' + num).slice(-2)

export const toResponse = response => ({
  status: response.status,
  data: response.data
})

export const numberFormatter = (number, currency = '') => {
  if (!number) {
    return undefined
  }
  return currency + number.toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1.')
}

export const formatDate = (date, monthYear = false) => {
  if (!date) return
  const options = {
    year: 'numeric',
    month: 'long'
  }
  if (!monthYear) {
    options.day = '2-digit'
  }
  return date.toLocaleDateString('en-GB', options)
}

export const toFormattedDate = (millis, format = 'dd MMMM yyyy, HH:mm') => {
  return DateTime.fromMillis(millis)
    .toFormat(format)
}

export const getQuarter = month => {
  const monthToQuarter = {
    1: ['Q1', 'Jan-Mar'],
    2: ['Q1', 'Jan-Mar'],
    3: ['Q1', 'Jan-Mar'],
    4: ['Q2', 'Apr-Jun'],
    5: ['Q2', 'Apr-Jun'],
    6: ['Q2', 'Apr-Jun'],
    7: ['Q3', 'Jul-Sep'],
    8: ['Q3', 'Jul-Sep'],
    9: ['Q3', 'Jul-Sep'],
    10: ['Q4', 'Oct-Dec'],
    11: ['Q4', 'Oct-Dec'],
    12: ['Q4', 'Oct-Dec']
  }
  return monthToQuarter[month]
}

export const toCapitalCase = sentence => {
  if (!sentence) {
    return null
  }
  const lowered = sentence.toLowerCase()
  const words = lowered.split(' ')
  return words.map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
}

export const cleanObject = object => {
  const sanitizedEntries = Object.entries(object)
    .filter(([, value]) => (
      typeof value === 'boolean' ||
      typeof value === 'number' ||
      !!value
    ))
  return Object.fromEntries(sanitizedEntries)
}

export const snakeCaseToCapitalCase = inputString => {
  const words = inputString.split('_');
  const capitalCaseString = words.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
  return capitalCaseString;
}

export const isValidEmail = str => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
  return emailRegex.test(str)
}

export const getAlphabets = () => Array.from({ length: 26 }, (v, i) => String.fromCharCode(i + 65))