import React, { useEffect, useState } from 'react'
import { getShippingRates } from '@/store/slices/checkout'
import { useDispatch } from 'react-redux'
import toast from 'react-hot-toast'
import useAuthentication from '@/hooks/authentication'
import useLoader from '@/hooks/loader'
import { numberFormatter } from '@/utils/common'

import BottomSheet from '@/components/BottomSheet'

const CheckoutShippingOptionModal = ({
  visible,
  onClose,
  destinationAddressId,
  items,
  onSelectShippingMethod,
  orderId
}) => {
  const dispatch = useDispatch()

  const { unauthorized } = useAuthentication()

  const [, setIsLoading] = useLoader()

  const [shippingRatesDetail, setShippingRatesDetail] = useState(null)

  useEffect(() => {
    if (!visible) {
      return
    }

    fetchShippingRates()
  }, [visible])

  useEffect(() => {
    setShippingRatesDetail(null)
  }, [destinationAddressId])

  const fetchShippingRates = async () => {
    try {
      setIsLoading(true)

      let params = {
        items,
        ...(orderId ? { orderId } : { destinationAddressId })
      }
      const { data } = await dispatch(getShippingRates(params)).unwrap()
      setShippingRatesDetail(data)

      setIsLoading(false)
    } catch(error) {
      unauthorized(error, () => {
        console.log(error)
        setIsLoading(false)
        toast.error('Something went wrong, please try again')
      })
    }
  }

  return (
    <BottomSheet
      visible={visible}
      onClose={onClose}
    >
      <div className="text-center font-semibold text-xl mb-5">
        Shipping Options
      </div>

      {
        shippingRatesDetail?.rates?.length && (
          <section>
            {
              shippingRatesDetail.rates.map((rate, i) => (
                <button
                  key={i}
                  type="button"
                  className="flex justify-between w-full items-center border border-black-multi p-3 my-2 cursor-pointer"
                  onClick={() => {
                    onSelectShippingMethod(rate)
                    onClose()
                  }}
                >
                  <div className="flex flex-col items-start">
                    <div className="flex items-center">
                      <div className="font-semibold">
                        {rate.courierName}
                      </div>
                      <small className="text-gray-multi ml-1">
                        ({rate.duration})
                      </small>
                    </div>
                    <small>
                      {rate.courierServiceName}
                    </small>
                  </div>

                  <div>
                    {numberFormatter(rate.price, 'Rp')}
                  </div>
                </button>
              ))
            }
          </section>
        )
      }
    </BottomSheet>
  )
}

export default CheckoutShippingOptionModal