import React, { useRef } from 'react'
import Slider from 'react-slick'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import useResponsive from '@/hooks/responsive'

import ProductCard from '@/components/ProductCard'

const FeaturedProductItem = ({ title, products }) => {
  const { isDesktop } = useResponsive()

  const slider = useRef(null)

  const sliderContent = slideToShow => (
    <>
      <button
        className="absolute p-2 left-4 top-1/2 -translate-y-1/2 text-lg text-black-multi z-10 rounded-full bg-white-multi bg-opacity-50 lg:text-3xl lg:left-12"
        type="button"
        onClick={() => slider.current?.slickPrev()}
      >
        <FaChevronLeft />
      </button>
      <button
        className="absolute p-2 right-4 top-1/2 -translate-y-1/2 text-lg text-black-multi z-10 rounded-full bg-white-multi bg-opacity-50 lg:text-3xl lg:right-12"
        type="button"
        onClick={() => slider.current?.slickNext()}
      >
        <FaChevronRight />
      </button>

      <Slider className="lg:w-4/5 lg:mx-auto" ref={slider} {...{
        slidesToShow: slideToShow,
        slidesToScroll: slideToShow,
        autoplay: false,
        dots: false,
        arrows: false,
        swipeToSlide: false
      }}>
        {
          products.map((product, i) => (
            <div key={product.sku} className="px-2 h-full">
              <ProductCard
                id={product.sku}
                name={product.displayName}
                brand={product.brand}
                price={product.price.offer}
                originalPrice={product.price.original}
                images={product.images}
                availability={product.availability}
                stock={product.stock}
                hidden={product.hidden}
              />
            </div>
          ))
        }
      </Slider>
    </>
  )

  const containerContent = () => (
    <section className="product-container lg:w-4/5 lg:mx-auto">
      {
        products.map(product => (
          <ProductCard
            key={product.sku}
            id={product.sku}
            name={product.displayName}
            brand={product.brand}
            price={product.price.offer}
            originalPrice={product.price.original}
            images={product.images}
            availability={product.availability}
            stock={product.stock}
            hidden={product.hidden}
          />
        ))
      }
    </section>
  )

  return (
    <section className="relative w-full bg-black-multi p-5 flex flex-col mt-6 lg:py-12 lg:px-0 lg:mt-10">
      <div className="text-xl text-yellow-multi mb-4 font-semibold lg:text-center lg:mb-6">
        {title}
      </div>

      {
        isDesktop && products.length <= 6 && containerContent()
      }

      {
        isDesktop && products.length > 6 && sliderContent(6)
      }

      {
        !isDesktop && products.length > 2 && sliderContent(2)
      }

      {
        !isDesktop && products.length <= 2 && containerContent()
      }
    </section>
  )
}

export default FeaturedProductItem