import React, { useState } from 'react'
import { numberFormatter, formatDate } from '@/utils/common'
import useDidUpdateEffect from '@/hooks/did-update-effect'

import CheckoutShippingOptionModal from '@/components/CheckoutShippingOptionModal'

const CheckoutItem = ({
  id,
  index,
  products,
  subtotal,
  destinationAddressId,
  onChangeShippingMethod,
  shippingMethod
}) => {

  const [visibleShippingOptionModal, setVisibleShippingOptionModal] = useState(false)
  const [selectedShippingMethod, setSelectedShippingMethod] = useState()

  useDidUpdateEffect(() => {
    onChangeShippingMethod(id, selectedShippingMethod)
  }, [selectedShippingMethod])

  const getSubtotalPrice = subtotal => numberFormatter(subtotal.reduce((acc, curr) => acc + curr.nominal, 0), 'Rp')

  const getShippingItems = () => products?.map(product => ({ sku: product.sku, quantity: product.quantity }))

  return (
    <>
      <CheckoutShippingOptionModal
        visible={visibleShippingOptionModal}
        onClose={() => setVisibleShippingOptionModal(false)}
        destinationAddressId={destinationAddressId}
        items={getShippingItems()}
        onSelectShippingMethod={setSelectedShippingMethod}
      />

      <div>
        <div className="mt-2 font-semibold">
          Order {index + 1}
        </div>

        {
          id !== 'READY_STOCK' && (
            <div className="text-xs mt-1 text-gray-multi-4">
              Est. arrival date &nbsp;
              {formatDate(new Date(+id), true)}
            </div>
          )
        }

        <div className="text-sm mt-2 lg:mt-4 flex flex-col gap-y-8 2xl:w-2/3">
          {
            products?.map((product, i) => (
              <div
                key={i}
                className="flex items-start"
              >
                <img
                  src={product.images[0]}
                  className="object-contain w-[28%] aspect-square lg:w-[20%] 2xl:w-[24%]"
                  loading="lazy"
                />

                <div className="w-full ml-3 flex flex-col lg:ml-4">
                  <div
                    className="font-semibold line-clamp-2"
                    title={product.displayName}
                  >
                    {product.displayName}
                  </div>

                  <div className="my-2.5 2xl:my-4">
                    <div className="flex justify-between w-1/2 2xl:w-1/3">
                      <div>
                        {numberFormatter(product.price?.offer, 'Rp')}
                      </div>
                      <div>
                        x{product.quantity}
                      </div>
                    </div>

                    {
                      product.downPayment && (
                        <div className="text-xs text-gray-multi-4">
                          50% Down Payment
                        </div>
                      )
                    }
                  </div>
                </div>
              </div>
            ))
          }
        </div>

        {
          !!shippingMethod && (
            <div className="mt-6 flex flex-col items-end gap-y-2">
              <div className="flex justify-between w-full items-center border border-black-multi p-3">
                <div className="flex flex-col items-start">
                  <div className="flex items-center">
                    <div className="font-semibold">
                      {shippingMethod.courierName}
                    </div>
                    <small className="text-gray-multi ml-1">
                      ({shippingMethod.duration})
                    </small>
                  </div>
                  <small>
                    {shippingMethod.courierServiceName}
                  </small>
                </div>

                <div>
                  {numberFormatter(shippingMethod.price, 'Rp')}
                </div>
              </div>

              <button
                type="button"
                className="text-[11px] text-black-multi font-semibold"
                onClick={() => setVisibleShippingOptionModal(true)}
              >
                Change
              </button>
            </div>
          )
        }
        {
          !shippingMethod && id === 'READY_STOCK' && (
            <button
              type="button"
              className="mt-6 button-secondary w-full lg:w-1/3"
              onClick={() => setVisibleShippingOptionModal(true)}
            >
              Choose shipping method
            </button>
          )
        }
        {
          id !== 'READY_STOCK' && (
            <div className="text-gray-multi-3 italic text-xs mt-3 lg:text-sm">
              Shipping option will be available and should be paid later when the product has arrrived.
            </div>
          )
        }

        <div className="mt-6">
          <div className="flex justify-between font-semibold">
            <div>
              Subtotal
            </div>

            <div>
              {getSubtotalPrice(subtotal)}
            </div>
          </div>
          {
            subtotal?.map(subtotal => (
              <div
                key={subtotal.id}
                className="flex justify-between text-sm"
              >
                <div>
                  {subtotal.text}
                </div>
                <div>
                  {numberFormatter(subtotal.nominal, 'Rp')}
                </div>
              </div>
            ))
          }
        </div>

        <hr className="border-gray-multi-4 mt-4 mb-4" />
      </div>
    </>
  )
}

export default CheckoutItem