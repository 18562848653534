import React, { useEffect, useState } from 'react'
import { FaChevronLeft } from 'react-icons/fa'

import SideNavigation from '@/components/SideNavigation'
import Checkbox from '@/components/Checkbox'

const FullAttributeFilter = ({
  visible,
  onClose,
  type: {
    value,
    title,
    items,
    initialItems
  },
  onAttributeFilterChange
}) => {

  const [selectedAttributes, setSelectedAttributes] = useState([])

  useEffect(() => {
    if (visible) {
      setSelectedAttributes(initialItems)
    }
  }, [visible])

  const handleAttributeOnChange = e => {
    const { value, checked } = e.target
    if (checked) {
      setSelectedAttributes([
        ...selectedAttributes,
        value
      ])
    } else {
      const filteredSelectedAttributes = selectedAttributes.filter(att => att !== value)
      setSelectedAttributes(filteredSelectedAttributes)
    }
  }

  const apply = () => {
    onAttributeFilterChange(value, selectedAttributes)
    onClose()
    setSelectedAttributes([])
  }

  return (
    <SideNavigation
      visible={visible}
      direction={1}
      onClose={onClose}
    >
      <div className="flex items-center mb-5">
        <button
          type="button"
          onClick={apply}
        >
          <FaChevronLeft className="text-yellow-multi text-sm mt-0.5" />
        </button>

        <div className="font-semibold text-yellow-multi text-xl ml-3">
          Filter
        </div>
      </div>

      <div className="text-yellow-multi font-semibold">
        {title}
      </div>

      <div className="mt-2">
        {
          items?.map(attribute => (
            <div
              key={attribute.id}
              className="mb-2.5"
            >
              <Checkbox
                label={attribute.name}
                id={`${attribute.id}-FullAttributeFilter`}
                value={attribute.id}
                dark
                onChange={handleAttributeOnChange}
                checked={selectedAttributes.some(a => a === attribute.id)}
              />
            </div>
          ))
        }
      </div>
    </SideNavigation>
  )
}

export default FullAttributeFilter