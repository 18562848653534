import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { deleteFromCart, getCartItems } from '@/store/slices/cart'
import toast from 'react-hot-toast'
import config from '@/constant/config'
import useLoader from '@/hooks/loader'
import useAuthentication from '@/hooks/authentication'
import { BsCartX } from 'react-icons/bs'
import { numberFormatter } from '@/utils/common'
import useResponsive from '@/hooks/responsive'
import classNames from 'classnames'

import GeneralHeader from '@/components/GeneralHeader'
import TitleHeader from '@/components/TitleHeader'
import CartCard from '@/components/CartCard'
import Ticker from '@/components/Ticker'
import Footer from '@/components/Footer'

const { product, path } = config

const CartPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { isDesktop } = useResponsive()

  const { unauthorized } = useAuthentication()
  const [isLoading, setIsLoading] = useLoader()

  const [availableCartItems, setAvailableCartItems] = useState([])
  const [unavailableCartItems, setUnavailableCartItems] = useState([])
  const [totalPrice, setTotalPrice] = useState()
  const [totalPriceItem, setTotalPriceItem] = useState({})

  const cartItemsRefs = useRef({})

  useEffect(() => {
    fetchCartItems()
  }, [])

  useEffect(() => {
    setTotalPrice(Object.values(totalPriceItem)
      .filter(item => !!item)
      .reduce((a, b) => a + b, 0))
  }, [totalPriceItem])

  const fetchCartItems = async () => {
    try {
      setIsLoading(true)

      const { data } = await dispatch(getCartItems()).unwrap()

      const { available = [], unavailable = [] } = data.reduce((acc, curr) => {
        const productVisibility = curr.stock === 0 || curr.hidden ? 'unavailable' : 'available'
        return{
          ...acc,
          [productVisibility]: [
            ...(acc[productVisibility] || []),
            curr
          ]
        }
      }, {})
      setUnavailableCartItems(unavailable)

      const reducedAvailableCartItems = available.reduce((acc, curr) => ({
        ...acc,
        [curr.availability]: acc[curr.availability] ? [
          ...acc[curr.availability],
          curr
        ] : [curr]
      }), {})
      setAvailableCartItems(Object.entries(reducedAvailableCartItems))

      setIsLoading(false)
    } catch(error) {
      unauthorized(error, () => {
        setIsLoading(false)
        console.log(error)
        toast.error('Something went wrong, please try again')
      })
    }
  }

  const getUnavailableInfo = product => {
    if (product.stock === 0) {
      return 'Item is out of stock'
    }
    return 'Item is not available'
  }

  const hasOutOfStock = () => unavailableCartItems.some(item => item.stock === 0)

  const removeAllUnavailableItems = async () => {
    try {
       setIsLoading(true)

       const toBeRemovedSkus = unavailableCartItems.map(item => item.sku)
       await dispatch(deleteFromCart(toBeRemovedSkus)).unwrap()

       toast.success('Products removed from cart')

       await fetchCartItems()

       setIsLoading(false)
    } catch(error) {
      unauthorized(error, () => {
        setIsLoading(false)
        console.log(error)
        toast.error('Something went wrong, please try again')
      })
    }
  }

  const getTotalPrice = (sku, price) => {
    setTotalPriceItem(prev => ({
      ...prev,
      [sku]: price
    }))
  }

  const onSuccessRemoveFromCart = async sku => {
    await fetchCartItems()

    setTotalPriceItem({
      ...totalPriceItem,
      [sku]: null
    })
  }

  const validateValidItems = () => {
    const [invalidItemKey] = availableCartItems
      .map(([availability, items]) => [
        availability,
        items.map((item, index) => ({ ...item, index }))
          .filter(item => item.stock > 0 && item.quantity === 0)
      ])
      .filter(([, invalidItems]) => invalidItems.length)
      .map(([availability, invalidItems]) => [availability, invalidItems[0]])
      .map(([availability, item]) => `${availability}-${item.index}`)

    if (invalidItemKey) {
      cartItemsRefs.current[invalidItemKey].scrollIntoView({
        behaviour: 'smooth',
        block: 'center'
      })
      toast.error('Please add at least 1 quantity')
      return false
    }
    return true
  }

  const proceedToPayment = async () => {
    try {
      await fetchCartItems()

      const isValid = validateValidItems()

      if (!isValid) {
        return
      }

      navigate(path.checkout)
    } catch(error) {
      unauthorized(error, () => {
        console.log(error)
        setIsLoading(false)
        toast.error('Something went wrong, please try again')
      })
    }
  }

  return (
    <div className="flex flex-col min-h-screen">
      {
        isDesktop ? (
          <GeneralHeader />
        ) : (
          <TitleHeader title="Cart"/>
        )
      }

      {
        !isDesktop && hasOutOfStock() && (
          <Ticker>
            <div className="text-xs font-semibold">
              You have out of stock item(s) in your cart
            </div>
          </Ticker>
        )
      }

      <section className="bg-white-multi grow p-5 relative flex lg:justify-center lg:py-8">
        {
          !isLoading && !availableCartItems.length && !unavailableCartItems.length && (
            <div className="self-center my-auto flex flex-col items-center text-gray-multi w-full">
              <BsCartX className="text-[6rem] mb-4" />
              <div>
                You have no items in your carts
              </div>
            </div>
          )
        }

        {
          (!!availableCartItems.length || !!unavailableCartItems.length) && (
            <section className="flex lg:items-start lg:gap-x-8 lg:w-[90%] 2xl:w-[70%]">
              <section className="w-full lg:w-2/3">
                {
                  isDesktop && (
                    <div className="font-semibold text-xl mb-6">
                      My Cart
                    </div>
                  )
                }

                {
                  availableCartItems.map(([availability, items]) => (
                    <section
                      key={availability}
                      className="pb-2 lg:mb-4"
                    >
                      <div className="font-semibold">
                        {product.availability[availability]}
                      </div>

                      {
                        items.map((item, index) => (
                          <div
                            className="py-2"
                            key={item.sku}
                            ref={el => cartItemsRefs.current[`${availability}-${index}`] = el}
                          >
                            <CartCard
                              sku={item.sku}
                              imageUrl={(item.images || [])[0]}
                              downPayment={item.downPayment}
                              displayName={item.displayName}
                              price={item.price?.offer}
                              originalPrice={item.price?.original}
                              stock={item.stock}
                              maxQuantityPerOrder={item.maxQuantityPerOrder}
                              availability={item.availability}
                              quantity={item.quantity}
                              onSuccessRemoveFromCart={onSuccessRemoveFromCart}
                              getPrice={getTotalPrice}
                            />
                          </div>
                        ))
                      }
                    </section>
                  ))
                }

                {
                  !!unavailableCartItems?.length && (
                    <section>
                      <div className="flex justify-between">
                        <div className="font-semibold">
                          Unavailable
                        </div>

                        <button
                          type="button"
                          onClick={removeAllUnavailableItems}
                          className="text-gray-multi-4 text-xs"
                        >
                          Remove all
                        </button>
                      </div>

                      {
                        unavailableCartItems.map(item => (
                          <div
                            className="py-2"
                            key={item.sku}
                          >
                            <CartCard
                              sku={item.sku}
                              imageUrl={(item.images || [])[0]}
                              downPayment={item.downPayment}
                              displayName={item.displayName}
                              price={item.price?.offer}
                              stock={item.stock}
                              maxQuantityPerOrder={item.maxQuantityPerOrder}
                              quantity={item.quantity}
                              onSuccessRemoveFromCart={fetchCartItems}
                              disabled
                            />

                            <div className="text-xs mt-1">
                              {getUnavailableInfo(item)}
                            </div>
                          </div>
                        ))
                      }
                    </section>
                  )
                }
              </section>

              {
                isDesktop && !!availableCartItems.length && (
                  <section className="sticky top-20 left-0 w-1/3 ml-auto self-start 2xl:w-1/4">
                    <div className="border border-gray-multi p-4 mb-4">
                      <div className="font-semibold">
                        Total
                      </div>

                      {
                        !!totalPrice && (
                          <div className="text-red-multi font-semibold text-xl my-8">
                            {numberFormatter(totalPrice, 'Rp')}
                          </div>
                        )
                      }

                      <button
                        type="button"
                        className={classNames({
                          'button-primary mt-2': true,
                          disabled: !totalPrice
                        })}
                        disabled={!totalPrice}
                        onClick={proceedToPayment}
                      >
                        Proceed to Payment
                      </button>
                    </div>

                    {
                      hasOutOfStock() && (
                        <Ticker className="rounded-lg">
                          <div className="text-xs text-left font-semibold">
                            You have out of stock item(s) in your cart
                          </div>
                        </Ticker>
                      )
                    }
                  </section>
                )
              }
            </section>
          )
        }

      </section>

      {
        !isDesktop && (!!availableCartItems.length || !!unavailableCartItems.length) && (
          <section className="sticky bottom-0 left-0 bg-white-multi px-4 py-3 border-t-2 border-black-multi">
            <div className="flex justify-between items-center">
              <div className="text-gray-multi">
                TOTAL
              </div>

              {
                !!totalPrice && (
                  <div className="text-red-multi font-semibold text-xl">
                    {numberFormatter(totalPrice, 'Rp')}
                  </div>
                )
              }
            </div>

            <button
              type="button"
              className={classNames({
                'button-primary mt-2': true,
                disabled: !totalPrice
              })}
              disabled={!totalPrice}
              onClick={proceedToPayment}
            >
              Proceed to Payment
            </button>
          </section>
        )
      }

      {
        isDesktop && <Footer />
      }
    </div>
  )
}

export default CartPage