import React, { useState } from 'react'
import { BsCalendarPlus } from 'react-icons/bs'
import Calendar from 'react-calendar'
import { DateTime } from 'luxon'
import { motion, AnimatePresence } from 'framer-motion'

import 'react-calendar/dist/Calendar.css'

const DatePicker = ({ value, onChange, format = 'dd/MM/yyyy', placeholder = '', ...props }) => {
  const [visibleCalendar, setVisibleCalendar] = useState(false)

  const getValue = () => {
    if (!value) {
      return (
        <div className="text-gray-400">
          {placeholder}
        </div>
      )
    }
    return DateTime.fromJSDate(value)
      .setZone('Asia/Jakarta')
      .toFormat(format)
  }

  const onClickDay = value => {
    onChange(value)
    setVisibleCalendar(false)
  }

  return (
    <div className="relative">
      <div className="border border-black-multi w-full bg-white-multi text-sm flex items-center">
        <button
          type="button"
          className="p-3 bg-white-multi outline-none cursor-pointer grow text-left"
          onClick={() => setVisibleCalendar(true)}
        >
          {getValue()}
        </button>
        <BsCalendarPlus className="m-3" />
      </div>

      <AnimatePresence mode="wait">
        {
          visibleCalendar && (
            <>
              <div
                className="fixed top-0 left-0 h-screen w-full z-10"
                onClick={() => setVisibleCalendar(false)}
              />

              <motion.div
                className="absolute w-full left-0 z-10"
                animate={{
                  y: 0,
                  opacity: 1,
                }}
                initial={{
                  y: '-10%',
                  opacity: 1
                }}
                exit={{
                  y: '-10%',
                  opacity: 0,
                  transition: {
                    duration: 0.1
                  }
                }}
                transition={{
                  duration: 0.2
                }}
              >
                <Calendar
                  value={value}
                  onClickDay={onClickDay}
                  {...props}
                />
              </motion.div>
            </>
          )
        }
      </AnimatePresence>
    </div>
  )
}

export default DatePicker