import React, { useEffect, useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  getAddress,
  updateAddress,
  deleteAddress
} from '@/store/slices/address'
import {
  getProvinces,
  getRegencies,
  getDistricts,
  getVillages
} from '@/store/slices/region'
import { toCapitalCase } from '@/utils/common'
import config from '@/constant/config'
import toast from 'react-hot-toast'
import useLoader from '@/hooks/loader'
import useAuthentication from '@/hooks/authentication'
import useResponsive from '@/hooks/responsive'

import GeneralHeader from '@/components/GeneralHeader'
import Checkbox from '@/components/Checkbox'
import TitleHeader from '@/components/TitleHeader'
import Dropdown from '@/components/Dropdown'
import BottomSheet from '@/components/BottomSheet'
import NumberInput from '@/components/NumberInput'
import Footer from '@/components/Footer'
import AddressMap from '@/components/AddressMap'

const { path } = config

const PHONE_REGEX = /^(\+62|62|0)8[1-9][0-9]{6,10}$/

const regionValidationSchema = yup.object().shape({
  key: yup.string().required(),
  value: yup.string().required(),
  formField: yup.string().notRequired()
})

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  recipientName: yup.string().required(),
  recipientPhone: yup.string()
    .matches(PHONE_REGEX)
    .required(),
  detail: yup.string().required(),
  province: regionValidationSchema,
  regency: regionValidationSchema,
  district: regionValidationSchema,
  village: regionValidationSchema,
  postalCode: yup.string().required(),
  notes: yup.string().notRequired(),
  primary: yup.boolean().required(),
  latitude: yup.number().required(),
  longitude: yup.number().required()
})

const EditAddressPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { isDesktop } = useResponsive()

  const { addressId } = useParams()

  const [visibleDeleteConfirmationModal, setVisibleDeleteConfirmationModal] = useState(false)
  const [addressDetail, setAddressDetail] = useState()
  const [provinces, setProvinces] = useState([])
  const [regencies, setRegencies] = useState([])
  const [districts, setDistricts] = useState([])
  const [villages, setVillages] = useState([])
  const [visibleAddressMapModal, setVisibleAddressMapModal] = useState(false)

  const { unauthorized } = useAuthentication()
  const [, setIsLoading] = useLoader()

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    control,
    resetField,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  })

  const selectedProvince = useWatch({
    control,
    name: 'province'
  })

  const selectedRegency = useWatch({
    control,
    name: 'regency'
  })

  const selectedDistrict = useWatch({
    control,
    name: 'district'
  })

  const selectedVillage = useWatch({
    control,
    name: 'village'
  })

  const latitude = useWatch({
    control,
    name: 'latitude'
  })

  const longitude = useWatch({
    control,
    name: 'longitude'
  })

  useEffect(() => {
    register('province')
    register('regency')
    register('district')
    register('village')
    register('latitude')
    register('longitude')
    fetchAddress()
    fetchProvinces()
  }, [])

  useEffect(() => {
    if (!addressDetail) {
      return
    }

    reset({
      name: addressDetail.name,
      recipientName: addressDetail.recipient.name,
      recipientPhone: addressDetail.recipient.phone,
      detail: addressDetail.detail,
      province: {
        key: addressDetail.province?.id,
        value: toCapitalCase(addressDetail.province?.name),
        formField: 'province'
      },
      regency: {
        key: addressDetail.regency?.id,
        value: toCapitalCase(addressDetail.regency?.name),
        formField: 'regency'
      },
      district: {
        key: addressDetail.district?.id,
        value: toCapitalCase(addressDetail.district?.name),
        formField: 'district'
      },
      village: {
        key: addressDetail.village?.id,
        value: toCapitalCase(addressDetail.village?.name),
        formField: 'village'
      },
      notes: addressDetail.notes,
      postalCode: addressDetail.postalCode,
      primary: addressDetail.primary,
      latitude: addressDetail.latitude,
      longitude: addressDetail.longitude
    })
  }, [addressDetail])

  useEffect(() => {
    if (!selectedProvince?.key) {
      return
    }
    fetchRegencies()
    resetField('regency')
    resetField('district')
    resetField('village')
  }, [selectedProvince])

  useEffect(() => {
    if (!selectedRegency?.key) {
      return
    }
    fetchDistricts()
    resetField('district')
    resetField('village')
  }, [selectedRegency])

  useEffect(() => {
    if (!selectedDistrict?.key) {
      return
    }
    fetchVillages()
    resetField('village')
  }, [selectedDistrict])

  const fetchAddress = async () => {
    try {
      setIsLoading(true)

      const { data } = await dispatch(getAddress(addressId)).unwrap()
      setAddressDetail(data)

      setIsLoading(false)
    } catch(error) {
      unauthorized(error, () => {
        setIsLoading(false)
        if (error.data.error === 'ADDRESS_NOT_EXIST') {
          navigate(path.editProfile)
          return
        }
        toast.error('Something went wrong, please try again')
      })
    }
  }

  const fetchProvinces = async () => {
    try {
      const response =  await dispatch(getProvinces()).unwrap()
      setProvinces(response.map(p => ({
        key: p.id,
        value: toCapitalCase(p.name),
        formField: 'province'
      })))
    } catch(error) {
      toast.error('Something went wrong, please try again')
    }
  }

  const fetchRegencies = async () => {
    try {
      const response =  await dispatch(getRegencies(selectedProvince.key)).unwrap()
      setRegencies(response.map(p => ({
        key: p.id,
        value: toCapitalCase(p.name),
        formField: 'regency'
      })))
    } catch(error) {
      toast.error('Something went wrong, please try again')
    }
  }

  const fetchDistricts = async () => {
    try {
      const response =  await dispatch(getDistricts(selectedRegency.key)).unwrap()
      setDistricts(response.map(p => ({
        key: p.id,
        value: toCapitalCase(p.name),
        formField: 'district'
      })))
    } catch(error) {
      toast.error('Something went wrong, please try again')
    }
  }

  const fetchVillages = async () => {
    try {
      const response =  await dispatch(getVillages(selectedDistrict.key)).unwrap()
      setVillages(response.map(p => ({
        key: p.id,
        value: toCapitalCase(p.name),
        formField: 'village'
      })))
    } catch(error) {
      toast.error('Something went wrong, please try again')
    }
  }

  const onChangeLatLong = (position = {}) => {
    const { lat, lng }  = position
    setValue('latitude', lat)
    setValue('longitude', lng)
    trigger('latitude')
    trigger('longitude')
    setVisibleAddressMapModal(false)
  }

  const doUpdateAddress = async form => {
    try {
      setIsLoading(true)

      const requestBody = {
        name: form.name,
        recipient: {
          name: form.recipientName,
          phone: form.recipientPhone
        },
        detail: form.detail,
        province: form.province?.key,
        regency: form.regency?.key,
        district: form.district?.key,
        village: form.village?.key,
        notes: form.notes,
        postalCode: form.postalCode,
        primary: form.primary,
        latitude: form.latitude,
        longitude: form.longitude
      }
      await dispatch(updateAddress({
        addressId: addressDetail.id,
        body: requestBody
      })).unwrap()

      setIsLoading(false)

      toast.success('Address updated')

      navigate(path.editProfile)

    } catch(error) {
      unauthorized(error, () => {
        setIsLoading(false)
        if (error.data.error === 'INVALID_REGION') {
          toast.error('Invalid region')
          return
        }

        if (error.data.error === 'INVALID_POSTAL_CODE') {
          toast.error('Please check your postal code and try again')
          return
        }

        toast.error('Something went wrong, please try again')
      })
    }
  }

  const doDeleteAddress = async () => {
    try {
      setIsLoading(true)

      await dispatch(deleteAddress(addressDetail.id)).unwrap()
      navigate(path.editProfile)
      toast.success('Address deleted')

      setIsLoading(false)
    } catch(error) {
      unauthorized(error, () => {
        setIsLoading(false)
        setVisibleDeleteConfirmationModal(false)
        if (error?.data?.error === 'LAST_ADDRESS') {
          toast.error('Unable to delete only address')
          return
        }
        toast.error('Something went wrong, please try again')
      })
    }
  }

  const preventEnter = e => {
    if (e.key === 'Enter') {
      e.preventDefault()
    }
  }

  const regionChangeHandler = selectedItem => {
    if (!selectedItem.formField) {
      return
    }
    setValue(selectedItem.formField, selectedItem)
    trigger(selectedItem.formField)
    resetRegion(selectedItem)
  }

  const resetRegion = selectedItem => {
    const resetField = ['province', 'regency', 'district', 'village']
    const index = resetField.findIndex(f => f === selectedItem.formField)
    resetField.slice(index + 1, resetField.length).forEach(field => {
      setValue(field, {})
    })
  }

  return (
    <>
      <BottomSheet
        visible={visibleDeleteConfirmationModal}
        onClose={() => setVisibleDeleteConfirmationModal(false)}
      >
        <div className="flex flex-col justify-center text-sm">
          <div className="font-semibold text-lg mb-1">
            Delete
          </div>

          <div>
            Delete address?
          </div>

          <div className="flex gap-3 mt-3">
            <button
              type="button"
              className="button-secondary"
              onClick={() => setVisibleDeleteConfirmationModal(false)}
            >
              Cancel
            </button>
            <button
              type="button"
              className="button-primary"
              onClick={doDeleteAddress}
            >
              Delete
            </button>
          </div>
        </div>
      </BottomSheet>

      <div className="flex flex-col h-screen">
        {
          isDesktop ? (
            <GeneralHeader />
          ) : (
            <TitleHeader title="Update Address"/>
          )
        }

        <section className="bg-white-multi grow">
          {
            !!addressDetail?.id && (
              <form
                onSubmit={handleSubmit(doUpdateAddress)}
                className="p-5 lg:py-8 lg:px-20 h-full flex flex-col"
              >
                {
                  visibleAddressMapModal && (
                    <AddressMap
                      visible={visibleAddressMapModal}
                      onClose={onChangeLatLong}
                      {...latitude && longitude && { position: { lat: latitude, lng: longitude } }}
                    />
                  )
                }
                <div className="grid grid-cols-1 gap-y-4 mb-4 lg:grid-cols-2 lg:gap-x-12">
                  <div>
                    <div className="font-semibold mb-1.5">
                      Address Name
                    </div>
                    <input
                      type="text"
                      placeholder="Enter a name for this address (e.g., Home, Office)"
                      className="input-primary"
                      {...register('name')}
                    />
                    {
                      errors.name && (
                        <small className="text-red-multi italic">
                          Address name cannot be empty
                        </small>
                      )
                    }
                  </div>

                  <div>
                    <div className="font-semibold mb-1.5">
                      Recipient Name
                    </div>
                    <input
                      type="text"
                      placeholder="Enter recipient's name"
                      className="input-primary"
                      {...register('recipientName')}
                    />
                    {
                      errors.recipientName && (
                        <small className="text-red-multi italic">
                          Recipient name cannot be empty
                        </small>
                      )
                    }
                  </div>

                  <div>
                    <div className="font-semibold mb-1.5">
                      Recipient Phone Number
                    </div>
                    <NumberInput
                      name="recipientPhone"
                      control={control}
                      allowLeadingZeros
                      placeholder="Enter recipient's phone number"
                      className="input-primary"
                    />
                    {
                      errors.recipientPhone && (
                        <small className="text-red-multi italic">
                          Invalid recipient phone number
                        </small>
                      )
                    }
                  </div>

                  <div>
                    <div className="font-semibold mb-1.5">
                      Address
                    </div>
                    <textarea
                      placeholder="Enter detailed address (street, building, apartment)"
                      onKeyDown={preventEnter}
                      className="input-primary"
                      {...register('detail')}
                    />
                    {
                      errors.detail && (
                        <small className="text-red-multi italic">
                          Address detail cannot be empty
                        </small>
                      )
                    }
                  </div>

                  <div>
                    <div className="font-semibold mb-1.5">
                      Province
                    </div>
                    <Dropdown
                      name="Select your province"
                      items={provinces}
                      onItemChange={regionChangeHandler}
                      error={!!errors.province}
                      value={selectedProvince}
                    />
                    {
                      errors.province && (
                        <small className="text-red-multi italic">
                          Province cannot be empty
                        </small>
                      )
                    }
                  </div>

                  {
                    !!selectedProvince && (
                      <div>
                        <div className="font-semibold mb-1.5">
                          Regency
                        </div>
                        <Dropdown
                          name="Select your regency"
                          items={regencies}
                          onItemChange={regionChangeHandler}
                          error={!!errors.regency}
                          value={selectedRegency}
                        />
                        {
                          errors.regency && (
                            <small className="text-red-multi italic">
                              Regency cannot be empty
                            </small>
                          )
                        }
                      </div>
                    )
                  }

                  {
                    !!selectedRegency && (
                      <div>
                        <div className="font-semibold mb-1.5">
                          District
                        </div>
                        <Dropdown
                          name="Select your district"
                          items={districts}
                          onItemChange={regionChangeHandler}
                          error={!!errors.district}
                          value={selectedDistrict}
                        />
                        {
                          errors.district && (
                            <small className="text-red-multi italic">
                              District cannot be empty
                            </small>
                          )
                        }
                      </div>
                    )
                  }

                  {
                    !!selectedDistrict && (
                      <div>
                        <div className="font-semibold mb-1.5">
                          Village
                        </div>
                        <Dropdown
                          name="Select your village"
                          items={villages}
                          onItemChange={regionChangeHandler}
                          error={!!errors.village}
                          value={selectedVillage}
                        />
                        {
                          errors.village && (
                            <small className="text-red-multi italic">
                              Village cannot be empty
                            </small>
                          )
                        }
                      </div>
                    )
                  }

                  <div>
                    <div className="font-semibold mb-1.5">
                      Postal Code
                    </div>
                    <NumberInput
                      name="postalCode"
                      control={control}
                      allowLeadingZeros
                      placeholder="Enter postal code"
                      className="input-primary"
                    />
                    {
                      errors.postalCode && (
                        <small className="text-red-multi italic">
                          Postal code cannot be empty
                        </small>
                      )
                    }
                  </div>

                  <div>
                    <div className="font-semibold mb-1.5">
                      Notes
                    </div>
                    <input
                      type="text"
                      placeholder="Add any additional notes (optional)"
                      className="input-primary"
                      {...register('notes')}
                    />
                  </div>

                  <div className="flex flex-col">
                    <div className="font-semibold mb-1.5">
                      Location
                    </div>
                    <button
                      type="button"
                      className="button-secondary !w-1/4"
                      onClick={() => setVisibleAddressMapModal(true)}
                    >
                      {
                        (!!latitude && !!longitude) ? 'Change Location' : 'Pinpoint Location'
                      }
                    </button>
                    {
                      (errors.latitude || errors.longitude) && (
                        <small className="text-red-multi italic">
                          Please pinpoint your exact location
                        </small>
                      )
                    }
                  </div>
                </div>

                {
                  !addressDetail?.primary && (
                    <div className="mb-4">
                      <Checkbox
                        label="Set as primary address"
                        id="edit-address-primary-checkbox"
                        {...register('primary')}
                      />
                    </div>
                  )
                }

                <div className="flex mt-auto gap-x-2 lg:w-1/2 lg:gap-x-4">
                  <button
                    type="submit"
                    className="button-primary"
                  >
                    Save
                  </button>
                  <button
                    type="button"
                    className="button-secondary"
                    onClick={() => setVisibleDeleteConfirmationModal(true)}
                  >
                    Delete
                  </button>
                  {
                    isDesktop && (
                      <button
                        type="button"
                        className="button-primary dark"
                        onClick={() => navigate(-1)}
                      >
                        Cancel
                      </button>
                    )
                  }
                </div>
              </form>
            )
          }
        </section>

        {
          isDesktop && <Footer />
        }
      </div>
    </>
  )
}

export default EditAddressPage