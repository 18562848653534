import { useNavigate, createSearchParams, useLocation } from 'react-router-dom'
import config from '@/constant/config'
import useLoader from '@/hooks/loader'

const { path } = config

const useAuthentication = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [, setIsLoading] = useLoader()

  return {
    unauthorized: (error, cb) => {
      if (error?.data?.error === 'UNAUTHORIZED') {
        navigate({
          pathname: path.login,
          search: createSearchParams({
            page: window.btoa(location.pathname)
          }).toString()
        })
        setIsLoading(false)
        return
      }
      cb()
    }
  }
}

export default useAuthentication