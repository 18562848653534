import React, { useEffect, useState } from 'react'
import toast from 'react-hot-toast'
import { FaChevronLeft } from 'react-icons/fa'
import { useParams, useNavigate } from 'react-router'
import { useDispatch } from 'react-redux';
import { getCustomLayout } from '@/store/slices/custom-layout'
import useLoader from '@/hooks/loader'
import useResponsive from '@/hooks/responsive'

import BaseHeader from '@/components/BaseHeader'
import GeneralHeader from '@/components/GeneralHeader'
import Footer from '@/components/Footer'
import BannerComponent from '@/components/CustomLayout/BannerComponent'
import CarouselComponent from '@/components/CustomLayout/CarouselComponent'
import TextComponent from '@/components/CustomLayout/TextComponent'
import ProductSetComponent from '@/components/CustomLayout/ProductSetComponent'

const CustomLayoutPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { slug } = useParams()
  const { isDesktop } = useResponsive()

  const [customLayout, setCustomLayout] = useState({})
  const [, setIsLoading] = useLoader()

  useEffect(() => {
    fetchCustomLayout()
  }, [])

  const fetchCustomLayout = async () => {
    try {
      setIsLoading(true)
      const { data } = await dispatch(getCustomLayout(slug)).unwrap()
      setCustomLayout(data)
      setIsLoading(false)
    } catch(err) {
      setIsLoading(false)
      toast.error('Something went wrong, please try again')
    }
  }

  const renderComponent = component => {
    if (component.type === 'BANNER') {
      return <BannerComponent component={component} />
    }
    if (component.type === 'CAROUSEL') {
      return <CarouselComponent component={component} />
    }
    if (component.type === 'PRODUCT_SET') {
      return <ProductSetComponent component={component} />
    }
    if (component.type === 'TEXT') {
      return <TextComponent component={component} />
    }
    return <div />
  }

  return (
    <div className="flex flex-col min-h-screen">
      {
        isDesktop ? (
          <GeneralHeader />
        ) : (
          <BaseHeader>
            <button
              type="button"
              onClick={() => navigate(-1)}
            >
              <FaChevronLeft className="text-lg" />
            </button>
          </BaseHeader>
        )
      }

      <section className="relative bg-white-multi grow">
        <section className="w-11/12 lg:w-3/5 mx-auto my-4 lg:my-8">
          <div className="font-bold lg:text-3xl">
            {customLayout.title}
          </div>

          <section>
            {
              customLayout.components?.map((component, index) => (
                <div
                  key={index}
                  className="my-4 lg:my-8"
                >
                  {renderComponent(component)}
                </div>
              ))
            }
          </section>
        </section>
      </section>

      <Footer />
    </div>
  )
}

export default CustomLayoutPage