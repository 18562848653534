import React, { useEffect, useState } from 'react'
import { FaChevronLeft } from 'react-icons/fa'
import config from '@/constant/config'
import { useDispatch } from 'react-redux'
import { getCategories } from '@/store/slices/category'
import { getBrands } from '@/store/slices/brand'
import { getSeries } from '@/store/slices/series'
import { getCharacters } from '@/store/slices/character'
import { useForm, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import SideNavigation from '@/components/SideNavigation'
import Checkbox from '@/components/Checkbox'
import NumberInput from '@/components/NumberInput'
import FullAttributeFilter from '@/components/FullAttributeFilter'

const { availabilities } = config.product

const validationSchema = yup.object().shape({
  availability: yup.array().of(yup.string()).notRequired(),
  category: yup.array().of(yup.string()).notRequired(),
  brand: yup.array().of(yup.string()).notRequired(),
  series: yup.array().of(yup.string()).notRequired(),
  character: yup.array().of(yup.string()).notRequired(),
  minPrice: yup.string().notRequired().when('maxPrice', ([maxPrice], field) => {
    if (maxPrice) {
      return field.test('validMinPrice', 'Invalid min price', minPrice => {
        return !minPrice || +minPrice < +maxPrice
      })
    }
    return field.notRequired()
  }),
  maxPrice: yup.string().notRequired()
})

const Container = ({ title, children, fetchMore, noLine = false, hasMore = false, showCounter, count }) => (
  <section>
    <div className="flex items-center w-full mb-3">
      <div className="text-base text-yellow-multi font-semibold">
        {title}
      </div>

      {
        showCounter && (
          <div className="ml-2.5 w-4 h-4 flex items-center justify-center bg-yellow-multi rounded-full text-xs font-semibold">
            {count}
          </div>
        )
      }

      {
        hasMore && (
          <button
            type="button"
            className="text-gray-multi text-xs ml-auto"
            onClick={() => fetchMore && fetchMore()}
          >
            More
          </button>
        )
      }
    </div>
    {children}

    {
      !noLine && (
        <div className="w-full h-[1px] bg-gray-multi mt-5 mb-3" />
      )
    }
  </section>
)

const ProductSearchFilterMobile = ({ visible, onClose, filters, setFilters }) => {
  const dispatch = useDispatch()

  const [categories, setCategories] = useState([])
  const [brands, setBrands] = useState([])
  const [series, setSeries] = useState([])
  const [characters, setCharacters] = useState([])
  const [visibleAttributeListModal, setVisibleAttributeListModal] = useState(false)
  const [attributeFilterType, setAttributeFilterType] = useState({})

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
    setValue
  } = useForm({
    resolver: yupResolver(validationSchema),
    shouldFocusError: false,
    defaultValues: {
      availability: [],
      category: [],
      brand: [],
      series: [],
      character: []
    }
  })

  const selectedCategories = useWatch({
    control,
    name: 'category'
  })

  const selectedBrands = useWatch({
    control,
    name: 'brand'
  })

  const selectedSeries = useWatch({
    control,
    name: 'series'
  })

  const selectedCharacters = useWatch({
    control,
    name: 'character'
  })

  useEffect(() => {
    if (!visible) {
      return
    }
    reset({
      availability: filters.availability,
      category: filters.category,
      brand: filters.brand,
      series: filters.series,
      character: filters.character,
      minPrice: filters.minPrice,
      maxPrice: filters.maxPrice
    })
    fetchCategories()
    fetchBrands()
    fetchSeries()
    fetchCharacters()
  }, [visible])

  const fetchCategories = async () => {
    const { data } = await dispatch(getCategories()).unwrap()
    setCategories(data)
  }

  const fetchBrands = async () => {
    const { data } = await dispatch(getBrands()).unwrap()
    setBrands(data)
  }

  const fetchSeries = async () => {
    const { data } = await dispatch(getSeries()).unwrap()
    setSeries(data)
  }

  const fetchCharacters = async () => {
    const { data } = await dispatch(getCharacters()).unwrap()
    setCharacters(data)
  }

  const sortDescendingByTruthyValues = (currentElement, nextElement) => {
    if (currentElement.selected && !nextElement.selected) {
      return -1
    }
    if (!currentElement.selected && nextElement.selected) {
      return 1
    }
    return 0
  }

  const highlightedCategories = () => {
    return categories.map(c => ({ ...c, selected: selectedCategories.includes(c.id) }))
      .sort(sortDescendingByTruthyValues)
      .slice(0, 4)
  }

  const highlightedBrands = () => {
    return brands.map(b => ({ ...b, selected: selectedBrands.includes(b.id) }))
      .sort(sortDescendingByTruthyValues)
      .slice(0, 4)
  }

  const highlightedSeries = () => {
    return series.map(s => ({ ...s, selected: selectedSeries.includes(s.id) }))
      .sort(sortDescendingByTruthyValues)
      .slice(0, 4)
  }

  const highlightedCharacters = () => {
    return characters.map(c => ({ ...c, selected: selectedCharacters.includes(c.id) }))
      .sort(sortDescendingByTruthyValues)
      .slice(0, 4)
  }

  const apply = form => {
    setFilters && setFilters({
      availability: form.availability,
      category: form.category,
      brand: form.brand,
      series: form.series,
      character: form.character,
      minPrice: form.minPrice,
      maxPrice: form.maxPrice
    })
    onClose()
  }

  const resetFilter = () => {
    reset({
      availability: [],
      category: [],
      brand: [],
      series: [],
      character: [],
      minPrice: null,
      maxPrice: null
    })
    setFilters && setFilters({
      availability: [],
      category: [],
      brand: [],
      series: [],
      character: [],
      minPrice: null,
      maxPrice: null
    })
    onClose()
  }

  const fetchMoreAttributes = type => {
    setVisibleAttributeListModal(true)

    if (type === 'category') {
      setAttributeFilterType({
        value: 'category',
        title: 'Category',
        items: categories,
        initialItems: selectedCategories
      })
      return
    }
    if (type === 'brand') {
      setAttributeFilterType({
        value: 'brand',
        title: 'Brand',
        items: brands,
        initialItems: selectedBrands
      })
      return
    }
    if (type === 'series') {
      setAttributeFilterType({
        value: 'series',
        title: 'Series',
        items: series,
        initialItems: selectedSeries
      })
      return
    }
    if (type === 'character') {
      setAttributeFilterType({
        value: 'character',
        title: 'Character',
        items: characters,
        initialItems: selectedCharacters
      })
      return
    }

    setAttributeFilterType({})
  }

  const handleAttributeFilterChange = (type, value = []) => {
    setValue(type.toLowerCase(), value)
  }

  return (
    <>
      <SideNavigation
        visible={visible}
        direction={1}
        onClose={onClose}
      >
        <form onSubmit={handleSubmit(apply)}>
          <div className="flex items-center mb-5">
            <button
              type="button"
              onClick={onClose}
            >
              <FaChevronLeft className="text-yellow-multi text-sm mt-0.5" />
            </button>

            <div className="font-semibold text-yellow-multi text-xl ml-3">
              Filter
            </div>

            <button
              type="button"
              className="text-gray-multi ml-auto text-xs"
              onClick={resetFilter}
            >
              Reset
            </button>
          </div>

          <Container title="Availability">
            {
              availabilities.map(availability => (
                <div
                  key={availability.key}
                  className="mb-2.5"
                >
                  <Checkbox
                    label={availability.value}
                    id={availability.key}
                    value={availability.key}
                    dark
                    {...register('availability')}
                  />
                </div>
              ))
            }
          </Container>

          <Container
            title="Category"
            hasMore={categories.length > 4}
            fetchMore={() => fetchMoreAttributes('category')}
            showCounter={selectedCategories.length > 0}
            count={selectedCategories.length}
          >
            {
              highlightedCategories().map(category => (
                <div
                  key={category.id}
                  className="mb-2.5"
                >
                  <Checkbox
                    label={category.name}
                    id={category.id}
                    value={category.id}
                    dark
                    {...register('category')}
                  />
                </div>
              ))
            }
          </Container>

          <Container
            title="Brand"
            hasMore={brands.length > 4}
            fetchMore={() => fetchMoreAttributes('brand')}
            showCounter={selectedBrands.length > 0}
            count={selectedBrands.length}
          >
            {
              highlightedBrands().map(brand => (
                <div
                  key={brand.id}
                  className="mb-2.5"
                >
                  <Checkbox
                    label={brand.name}
                    id={brand.id}
                    value={brand.id}
                    dark
                    {...register('brand')}
                  />
                </div>
              ))
            }
          </Container>

          <Container
            title="Series"
            hasMore={series.length > 4}
            fetchMore={() => fetchMoreAttributes('series')}
            showCounter={selectedSeries.length > 0}
            count={selectedSeries.length}
          >
            {
              highlightedSeries().map(s => (
                <div
                  key={s.id}
                  className="mb-2.5"
                >
                  <Checkbox
                    label={s.name}
                    id={s.id}
                    value={s.id}
                    dark
                    {...register('series')}
                  />
                </div>
              ))
            }
          </Container>

          <Container
            title="Character"
            hasMore={characters.length > 4}
            fetchMore={() => fetchMoreAttributes('character')}
            showCounter={selectedCharacters.length > 0}
            count={selectedCharacters.length}
          >
            {
              highlightedCharacters().map(character => (
                <div
                  key={character.id}
                  className="mb-2.5"
                >
                  <Checkbox
                    label={character.name}
                    id={character.id}
                    value={character.id}
                    dark
                    {...register('character')}
                  />
                </div>
              ))
            }
          </Container>

          <Container
            title="Price"
            noLine
          >
            <div className="grid grid-cols-2 gap-3 w-full text-white-multi text-sm">
              <div>
                <div>
                  Min. Price
                </div>

                <div className="flex items-center mt-2">
                  <div>
                    Rp
                  </div>

                  <NumberInput
                    name="minPrice"
                    control={control}
                    separator="."
                    className="mx-3 py-1.5 w-full bg-black-multi text-sm outline-none"
                  />
                </div>
                <div className="w-full h-[0.5px] bg-gray-multi mt-1.5" />
              </div>

              <div>
                <div>
                  Max. Price
                </div>

                <div className="flex items-center mt-2">
                  <div>
                    Rp
                  </div>

                  <NumberInput
                    name="maxPrice"
                    control={control}
                    separator="."
                    className="mx-3 py-1.5 w-full bg-black-multi text-sm outline-none"
                  />
                </div>
                <div className="w-full h-[0.5px] bg-gray-multi mt-1.5" />
              </div>

              {
                errors.minPrice && (
                  <small className="text-red-multi col-span-2">
                    Min. price must be less than max. price
                  </small>
                )
              }
            </div>
          </Container>

          <button
            type="submit"
            className="button-primary dark font-[500] mt-4"
          >
            Apply Filters
          </button>
        </form>

      </SideNavigation>

      <FullAttributeFilter
        visible={visibleAttributeListModal}
        onClose={() => setVisibleAttributeListModal(false)}
        type={attributeFilterType}
        onAttributeFilterChange={handleAttributeFilterChange}
      />
    </>
  )
}

export default ProductSearchFilterMobile