import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '@/axios'
import config from '@/constant/config'
import { toResponse } from '@/utils/common'

const { api } = config

export const getStoreLocations = createAsyncThunk(
  'storeLocation/getStoreLocations',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(api.storeLocation)
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const storeLocationSlice = createSlice({
  name: 'storeLocation',
  initialState: {},
  reducers: {}
})

export default storeLocationSlice.reducer