import { createSlice } from '@reduxjs/toolkit'

export const loaderSlice = createSlice({
  name: 'loader',
  initialState: {
    isLoading: false
  },
  reducers: {
    setIsLoading(state, actions) {
      state.isLoading = actions.payload
    }
  }
})

export const isLoadingSelector = state => state.loader.isLoading

export const { setIsLoading } = loaderSlice.actions

export default loaderSlice.reducer