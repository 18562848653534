import React from 'react'

import ProductCarousel from '@/components/ProductCarousel'

const colors = [
  'bg-[#B2D2A4]',
  'bg-[#F4B183]',
  'bg-[#E6A57E]',
  'bg-[#A8C3E4]',
  'bg-[#D3A4B8]',
  'bg-[#F2C14E]',
  'bg-[#A9B6C1]',
  'bg-[#D1A3A4]',
  'bg-[#B9C9BA]',
  'bg-[#E1B07E]'
]

const ProductSetComponent = ({ component }) => {
  return (
    <div className={['p-3 lg:p-6 border border-black-multi shadow', colors[8]].join(' ')}>
      <ProductCarousel products={component.products} />
    </div>
  )
}

export default ProductSetComponent