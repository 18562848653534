import React from 'react'

const BACKGROUND = {
  success: 'bg-green-multi',
  warning: 'bg-orange-multi',
  error: 'bg-red-multi'
}

const Label = ({ type, children, className = '' }) => {
  const bg = BACKGROUND[type]
  return (
    <div className={`rounded-full text-center text-white-primary text-[11px] px-2 py-1 ${bg} ` + className}>
      {children}
    </div>
  )
}

export default Label