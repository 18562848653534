import React, { useState } from 'react'
import { useForm, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useNavigate } from 'react-router-dom'
import config from '@/constant/config'
import { useDispatch } from 'react-redux'
import { saveUser } from '@/store/slices/user'
import { resendRegistrationOtp, validateRegistrationOtp } from '@/store/slices/otp'
import toast from 'react-hot-toast'
import { FaFacebookSquare } from 'react-icons/fa'
import { BsGoogle } from 'react-icons/bs'
import useLoader from '@/hooks/loader'
import useResponsive from '@/hooks/responsive'
import loginIllustration from '@/assets/login-illustration.png'

import GeneralHeader from '@/components/GeneralHeader'
import TitleHeader from '@/components/TitleHeader'
import OtpModal from '@/components/OtpModal'
import Footer from '@/components/Footer'

const validationSchema = yup.object().shape({
  email: yup.string().email().required(),
  name: yup.string().required(),
  password: yup.string()
    .min(8)
    .matches(/[A-Z]/)
    .required(),
  confirmPassword: yup.string()
    .test('validPassword', 'Password is different', function(confirmPassword) {
      return confirmPassword === this.parent.password
    })
    .required('Confirm password must not empty'),
})

const { path } = config

const RegisterPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { isDesktop } = useResponsive()

  const [, setIsLoading] = useLoader()
  const [visibleVerificationModal, setVisibleVerificationModal] = useState(false)
  const [registerOtpId, setRegisterOtpId] = useState('')

  const {
    register,
    handleSubmit,
    formState: { errors },
    control
  } = useForm({
    resolver: yupResolver(validationSchema)
  })

  const emailForm = useWatch({
    control,
    name: 'email'
  })

  const doRegister = async form => {
    try {
      if (registerOtpId) {
        setVisibleVerificationModal(true)
        return
      }

      setIsLoading(true)

      const { data } = await dispatch(saveUser({
        email: form.email,
        password: form.password,
        name: form.name
      })).unwrap()

      setRegisterOtpId(data)
      setVisibleVerificationModal(true)

      setIsLoading(false)
    } catch(error) {
      setIsLoading(false)
      if (error.data && error.data.error === 'USER_ALREADY_EXIST') {
        toast.error('User with this email already exist')
        return
      }
      toast.error('Something went wrong, please try again')
    }
  }

  const doGoogleLogin = async () => {
    try {
      window.open(api.googleLogin, '_self')
    } catch(error) {
    }
  }

  const doFacebookLogin = async () => {
    try {
      window.open(api.facebookLogin, '_self')
    } catch(error) {
    }
  }

  const resendOtp = async otpId => {
    await dispatch(resendRegistrationOtp({
      otpId
    })).unwrap()
  }

  const verifyOtp = async (otp, otpId) => {
    await dispatch(validateRegistrationOtp({
      email: emailForm,
      token: otp,
      otpId
    })).unwrap()

    navigate(path.registerCompletion, {
      state: { email: emailForm }
    })
  }

  return (
    <>
      {
        registerOtpId && (
          <OtpModal
            visible={visibleVerificationModal}
            onClose={() => setVisibleVerificationModal(false)}
            otpId={registerOtpId}
            resend={resendOtp}
            verify={verifyOtp}
          />
        )
      }

      <div className="flex flex-col h-screen">
        {
          isDesktop ? (
            <GeneralHeader />
          ) : (
            <TitleHeader title="Sign Up"/>
          )
        }

        <section className="bg-white-multi grow flex">
          {
            isDesktop && (
              <div className="w-1/2 flex justify-center items-center">
                <img
                  src={loginIllustration}
                  className="w-96"
                  alt="illustration"
                />
              </div>
            )
          }

          <form
            onSubmit={handleSubmit(doRegister)}
            className="w-full p-5 flex flex-col h-full lg:py-8 lg:px-20 lg:w-1/2"
          >
            {
              isDesktop && (
                <div className="lg:text-2xl lg:font-semibold lg:mb-8">
                  Sign Up
                </div>
              )
            }

            <div className="mb-4">
              <div className="font-semibold mb-1.5">
                Email
              </div>
              <input
                type="text"
                placeholder="Enter your email address"
                className="input-primary"
                {...register('email')}
              />
              {
                errors.email && (
                  <small className="text-red-multi italic">
                    Incorrect email
                  </small>
                )
              }
            </div>

            <div className="mb-4">
              <div className="font-semibold mb-1.5">
                Name
              </div>
              <input
                type="text"
                placeholder="Enter your full name"
                className="input-primary"
                {...register('name')}
              />
              {
                errors.name && (
                  <small className="text-red-multi italic">
                    Name cannot be empty
                  </small>
                )
              }
            </div>

            <div className="mb-4">
              <div className="font-semibold mb-1.5">
                Password
              </div>
              <input
                type="password"
                placeholder="Enter your password"
                className="input-primary"
                {...register('password')}
              />
              {
                errors.password && (
                  <small className="text-red-multi italic">
                    Password must contain minimal 8 characters and 1 uppercase letter
                  </small>
                )
              }
            </div>

            <div>
              <div className="font-semibold mb-1.5">
                Confirm Password
              </div>
              <input
                type="password"
                placeholder="Confirm your password"
                className="input-primary"
                {...register('confirmPassword')}
              />
              {
                errors.confirmPassword && (
                  <small className="text-red-multi italic">
                    Password must match the password you've entered
                  </small>
                )
              }
            </div>

            {
              isDesktop && (
                <div className="my-8 self-start">
                  <button
                    type="submit"
                    className="button-primary"
                  >
                    Sign Up
                  </button>

                  <div className="text-xs justify-center mb-2 flex lg:justify-start lg:mt-6 lg:mb-0">
                    Already have an account?
                    <button
                      className="ml-1 underline"
                      type="button"
                      onClick={() => navigate(path.login)}
                    >
                      Log in here
                    </button>
                  </div>
                </div>
              )
            }

            <div className="flex flex-col my-8 lg:mt-0">
              <div className="flex items-center">
                <div className="w-1/3 bg-black-multi" style={{ height: '1px' }} />

                <div className="text-xs w-1/3 text-center lg:text-sm">
                  Sign up with
                </div>

                <div className="w-1/3 bg-black-multi" style={{ height: '1px' }} />
              </div>

              <div className="flex w-1/3 my-4 mx-auto items-center justify-evenly text-lg lg:text-xl">
                <button
                  type="button"
                  onClick={doFacebookLogin}
                >
                  <FaFacebookSquare />
                </button>

                <button
                  type="button"
                  onClick={doGoogleLogin}
                >
                  <BsGoogle />
                </button>
              </div>
            </div>

            {
              !isDesktop && (
                <div className="mt-auto">
                  <div className="text-xs justify-center mb-2 flex">
                    Already have an account?
                    <button
                      className="ml-1 underline"
                      type="button"
                      onClick={() => navigate(path.login)}
                    >
                      Log in here
                    </button>
                  </div>

                  <button
                    type="submit"
                    className="button-primary"
                  >
                    Sign Up
                  </button>
                </div>
              )
            }
          </form>
        </section>

        {
          isDesktop && <Footer />
        }
      </div>
    </>
  )
}

export default RegisterPage