import React, { forwardRef, useEffect, useState } from 'react'
import { FaTimes } from 'react-icons/fa'
import { getAlphabets } from '@/utils/common'

import Checkbox from '@/components/Checkbox'

const SortedAlphabetFilterModal = forwardRef(({ title, items, initialItems = [], onClose, onApply }, ref) => {
  const [selectedItems, setSelectedItems] = useState(initialItems)

  const [sortedItems, setSortedItems] = useState([])

  useEffect(() => {
    setSortedItems(
      getAlphabets().map(alphabet => ({
        alphabet,
        items: items.filter(item => item.name[0] === alphabet)
          .map(item => ({
            ...item,
            checked: selectedItems.includes(item.id)
          }))
      }))
      .filter(o => !!o.items.length)
    )
  }, [])

  const updateSortedItems = (checked, item, alphabet) => {
    return sortedItems.map(o => {
      if (o.alphabet !== alphabet) {
        return o
      }

      return {
        ...o,
        items: o.items.map(it => {
          if (it.id !== item.id) {
            return it
          }

          return {
            ...it,
            checked
          }
        })
      }
    })
  }

  const handleSelectItem = (e, item, alphabet) => {
    if (e.target.checked) {
      setSortedItems(updateSortedItems(true, item, alphabet))
      setSelectedItems([
        ...selectedItems,
        item.id
      ])
    } else {
      setSortedItems(updateSortedItems(false, item, alphabet))
      setSelectedItems(selectedItems.filter(i => i !== item.id))
    }
  }

  return (
    <>
      <div
        className="fixed top-0 left-0 z-10 w-screen h-screen"
        onClick={() => onClose && onClose()}
      />

      <div className={
        'absolute left-0 bottom-10 z-10 flex flex-col ' +
        'lg:bg-white-multi lg:border lg:border-gray-multi lg:w-full lg:max-h-[45vh]'
      }>
        <div className="pt-3 px-3 flex justify-between mb-2 bg-white-multi">
          <div className="font-semibold">
            {title}
          </div>

          <div className="flex items-center gap-x-2">
            <button
              type="button"
              className="text-sm mb-1 text-gray-multi-3"
              onClick={() => onApply && onApply(selectedItems)}
            >
              Apply
            </button>
            <button
              type="button"
              className="ml-auto"
              onClick={() => onClose && onClose()}
            >
              <FaTimes />
            </button>
          </div>
        </div>

        <section className="px-3 scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100 lg:overflow-y-auto">
          {
            sortedItems.map(({ alphabet, items }) => (
              <div
                key={alphabet}
                className="mb-2"
              >
                <div className="font-semibold text-gray-multi">
                  {alphabet}
                </div>
                <div className="mt-1.5 grid grid-cols-2 gap-x-3">
                  {
                    items.map(item => (
                      <div
                        key={item.id}
                        className="mb-2.5"
                      >
                        <Checkbox
                          label={item.name}
                          id={item.id}
                          value={item.id}
                          ref={ref}
                          onChange={e => handleSelectItem(e, item, alphabet)}
                          checked={item.checked}
                        />
                      </div>
                    ))
                  }
                </div>
              </div>
            ))
          }
        </section>
      </div>
    </>
  )
})

export default SortedAlphabetFilterModal