import React, { useEffect, useState, useRef } from 'react'
import { useNavigate, createSearchParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { getBanners } from '@/store/slices/banner'
import { getProducts } from '@/store/slices/product'
import { userSelector } from '@/store/slices/user'
import { getHomepageProductConfig } from '@/store/slices/config'
import { subscribeNewsletter } from '@/store/slices/newsletter'
import { getFeaturedProducts } from '@/store/slices/featured-product'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import { isValidEmail } from '@/utils/common'
import config from '@/constant/config'
import toast from 'react-hot-toast'
import InfiniteScroll from 'react-infinite-scroll-component'
import useResponsive from '@/hooks/responsive'
import classNames from 'classnames'
import Slider from 'react-slick'
import useDidUpdateEffect from '@/hooks/did-update-effect'
import useLoader from '@/hooks/loader'

import GeneralHeader from '@/components/GeneralHeader'
import Footer from '@/components/Footer'
import ProductCard from '@/components/ProductCard'
import ProductCardLoader from '@/components/ProductCardLoader'
import Ticker from '@/components/Ticker'
import FeaturedProductItem from '@/components/FeaturedProductItem'

const { availabilities } = config.product

const { path } = config

const HomePage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { user } = useSelector(userSelector)
  const slider = useRef(null)

  const { isDesktop } = useResponsive()
  const [, setIsLoading] = useLoader()

  const [banners, setBanners] = useState([])
  const [latestProducts, setLatestProducts] = useState([])
  const [products, setProducts] = useState([])
  const [availabilityFilters, setAvailabilityFilters] = useState(availabilities)
  const [selectedAvailability, setSelectedAvailability] = useState()
  const [productPage, setProductPage] = useState({})
  const [isFetchingBanner, setIsFetchingBanner] = useState(false)
  const [isFetchingLatestProduct, setIsFetchingLatestProduct] = useState(false)
  const [isFetchingProduct, setIsFetchingProduct] = useState(false)
  const [isFetchingFeaturedProduct, setIsFetchingFeaturedProduct] = useState(false)
  const [emailSubscription, setEmailSubscription] = useState('')
  const [featuredProducts, setFeaturedProducts] = useState([])

  useEffect(() => {
    try {
      fetchAvailabilityConfigs()
      fetchBanners()
      fetchLatestProducts()
      fetchFeaturedProducts()
    } catch(error) {
      setIsFetchingBanner(false)
      setIsFetchingLatestProduct(false)
      setIsFetchingFeaturedProduct(false)
      toast.error('Something went wrong, please try again')
    }
  }, [])

  useEffect(() => {
    if (!selectedAvailability) return
    updateProductOnAvailabilityChanged()
  }, [selectedAvailability])

  useDidUpdateEffect(() => {
    setSelectedAvailability(availabilityFilters[0].key)
  }, [availabilityFilters])

  const updateProductOnAvailabilityChanged = async () => {
    await fetchProducts(1, false)
  }

  const fetchAvailabilityConfigs = async () => {
    const { data } = await dispatch(getHomepageProductConfig()).unwrap()
    setAvailabilityFilters(availabilityFilters.filter(av => data.includes(av.key)))
  }

  const fetchFeaturedProducts = async () => {
    setIsFetchingFeaturedProduct(true)
    const { data } = await dispatch(getFeaturedProducts()).unwrap()
    setFeaturedProducts(data)
    setIsFetchingFeaturedProduct(false)
  }

  const fetchBanners = async () => {
    setIsFetchingBanner(true)
    const { data } = await dispatch(getBanners()).unwrap()
    setBanners(data)
    setIsFetchingBanner(false)
  }

  const fetchLatestProducts = async () => {
    setIsFetchingLatestProduct(true)

    const { data: latestProducts } = await dispatch(getProducts({
      size: 12,
      page: 1,
      sort: 'LATEST'
    })).unwrap()
    setLatestProducts(latestProducts)

    setIsFetchingLatestProduct(false)
  }

  const fetchProducts = async (page, append = true) => {
    try {
      if (isFetchingProduct) {
        return
      }

      setIsFetchingProduct(true)

      if (!append) {
        setProducts([])
      }

      const requestParam = {
        availability: selectedAvailability,
        page,
        size: isDesktop ? 12 : 10,
        sort: 'MOST_RELEVANT'
      }
      const { data, paging } = await dispatch(getProducts(requestParam)).unwrap()

      setProductPage(paging)

      if (append) {
        setProducts([
          ...products,
          ...data
        ])
      } else {
        setProducts(data)
      }

      setIsFetchingProduct(false)
    } catch(error) {
      setIsFetchingProduct(false)
    }
  }

  const productLoader = () => {
    const size = isDesktop ? 12 : 4
    return [...new Array(size)].map((_, i) => <ProductCardLoader key={i} />)
  }

  const bannerRedirection = banner => {
    const { link } = banner

    if (!link.type || !link.value) {
      return
    }

    if (link.type === 'CUSTOM_LAYOUT') {
      navigate(path.customLayout.replace(':slug', encodeURIComponent(link.value)))
      return
    }

    if (link.type === 'URL') {
      window.open(link.value, '_blank')
      return
    }

    const urlParamKey = link.type.toLowerCase()
    navigate({
      pathname: path.search,
      search: createSearchParams({
        [urlParamKey]: link.value
      }).toString()
    })
  }

  const subscribe = async () => {
    try {
      if (!isValidEmail(emailSubscription)) {
        toast.error('Invalid email')
        return
      }

      setIsLoading(true)

      await dispatch(subscribeNewsletter({
        email: emailSubscription
      })).unwrap()
      toast.success('Subscribed!')
      setEmailSubscription('')

      setIsLoading(false)
    } catch(err) {
      setIsLoading(false)
      const { error = {} } = err?.data
      if (error.some(e => e.path === 'email')) {
        toast.error('Invalid email')
        return
      }
      toast.error('Something went wrong, please try again')
    }
  }

  return (
    <div className="flex flex-col">
      <GeneralHeader />

      <section className="bg-white-multi grow flex flex-col">
        {
          !!user && !user.hasPassword && (
            <Ticker onClick={() => navigate(path.editProfile)}>
              <div className="text-[11px] text-gray-multi font-semibold">
                You don't have a password yet, Set it up now
              </div>
              <FaChevronRight className="ml-auto text-xs text-gray-multi" />
            </Ticker>
          )
        }

        {
          isFetchingBanner && (
            <section className="w-full p-5">
              <div className="w-full h-[171px] mx-auto bg-gray-multi-2 animate-pulse rounded-lg" />
            </section>
          )
        }
        {
          !isFetchingBanner && banners.length >= 3 && (
            <section className="mb-3 py-8 self-center w-full relative">
              <button
                className="absolute p-2 left-4 top-1/2 -translate-y-1/2 text-lg text-black-multi z-10 rounded-full bg-white-multi bg-opacity-50 lg:text-3xl lg:left-8"
                type="button"
                onClick={() => slider.current?.slickPrev()}
              >
                <FaChevronLeft />
              </button>
              <button
                className="absolute p-2 right-4 top-1/2 -translate-y-1/2 text-lg text-black-multi z-10 rounded-full bg-white-multi bg-opacity-50 lg:text-3xl lg:right-8"
                type="button"
                onClick={() => slider.current?.slickNext()}
              >
                <FaChevronRight />
              </button>

              <Slider className="slider-home" ref={slider} {...{
                slidesToShow: isDesktop ? 1.65 : 1,
                slidesToScroll: 1,
                autoplay: true,
                autoplaySpeed: 3000,
                dots: true,
                centerMode: true,
                arrows: false,
                customPaging: i => <div className="dot" />,
                dotsClass: "slick-dots slick-thumb",
                appendDots: dots => (
                  <ul>
                    {
                      dots.map((dot, i) => (
                        <li
                          key={i}
                          onClick={() => slider.current?.slickGoTo(+dot.key)}
                          className={dot.props.className}
                        >
                          {dot.props.children}
                        </li>
                      ))
                    }
                  </ul>
                )
              }}>
                {
                  banners.map((banner, i) => (
                    <img
                      key={i}
                      src={banner.filePath}
                      alt="Banner"
                      loading="lazy"
                      onClick={() => bannerRedirection(banner)}
                      className="px-2"
                    />
                  ))
                }
              </Slider>
            </section>
          )
        }

        <section className="w-full bg-black-multi p-5 flex flex-col lg:py-12 lg:px-0">
          <div className="text-xl text-yellow-multi mb-4 font-semibold lg:text-center lg:mb-6">
            Latest Items
          </div>

          {
            isFetchingLatestProduct && (
              <section className="product-container lg:w-4/5 lg:mx-auto">
                {productLoader()}
              </section>
            )
          }
          {
            !isFetchingLatestProduct && (
              <section className="product-container lg:w-4/5 lg:mx-auto">
                {
                  latestProducts.map(product => (
                    <ProductCard
                      key={product.sku}
                      id={product.sku}
                      name={product.displayName}
                      brand={product.brand}
                      price={product.price.offer}
                      originalPrice={product.price.original}
                      images={product.images}
                      availability={product.availability}
                      stock={product.stock}
                      hidden={product.hidden}
                    />
                  ))
                }
              </section>
            )
          }

          <button
            className="text-sm text-yellow-multi mx-auto tracking-ultra-wide my-8 lg:mb-0"
            type="button"
            onClick={() => navigate({
              pathname: path.search,
              search: createSearchParams({
                sort: 'LATEST'
              }).toString()
            })}
          >
            SEE ALL
          </button>
        </section>

        {
          !isFetchingFeaturedProduct && featuredProducts.map(featuredProduct => (
            <FeaturedProductItem
              key={featuredProduct.id}
              isLoading={isFetchingFeaturedProduct}
              title={featuredProduct.title}
              products={featuredProduct.products}
            />
          ))
        }

        <section className="bg-white-multi px-5 py-7 flex flex-col w-full lg:py-12 lg:w-1/3 lg:mx-auto">
          <div className="text-black-multi font-semibold mb-2 lg:text-lg">
            Subscribe for newsletter
          </div>
          <div className="flex items-center gap-x-2">
            <input
              type="text"
              className="input-primary"
              placeholder="Email"
              onChange={e => setEmailSubscription(e.target.value)}
              value={emailSubscription}
            />
            <div className="w-2/5">
              <button
                className="button-primary"
                onClick={subscribe}
              >
                Submit
              </button>
            </div>
          </div>
        </section>

        <section className="bg-yellow-multi px-5 py-7 flex flex-col w-full lg:px-0 lg:py-12">
          <nav className="flex justify-center lg:mb-4 lg:w-1/4 mx-auto">
            {
              availabilityFilters.map(availability => (
                <button
                  type="button"
                  key={availability.key}
                  className={classNames({
                    'w-1/2 text-sm border border-black-multi px-3 py-2 text-center whitespace-nowrap outline-none transition ease-out duration-150 mr-4': true,
                    'bg-black-multi text-white-primary': selectedAvailability === availability.key,
                    'border-gray-500 text-gray-500': isFetchingProduct && selectedAvailability !== availability.key
                  })}
                  disabled={isFetchingProduct}
                  onClick={() => setSelectedAvailability(availability.key)}
                >
                  {availability.value}
                </button>
              ))
            }
          </nav>

          <InfiniteScroll
            className="product-container mt-4 lg:mx-auto lg:w-4/5"
            dataLength={products.length}
            next={() => fetchProducts(productPage.page + 1 || 1)}
            hasMore={products.length < productPage.totalItem && productPage.page < 10}
            loader={productLoader()}
          >
            {
              products.map(product => (
                <ProductCard
                  key={product.sku}
                  id={product.sku}
                  name={product.displayName}
                  brand={product.brand}
                  price={product.price.offer}
                  originalPrice={product.price.original}
                  images={product.images}
                  availability={product.availability}
                  stock={product.stock}
                  hidden={product.hidden}
                />
              ))
            }
          </InfiniteScroll>
          {
            (productPage.page >= 10 || products.length >= productPage.totalItem) && !isFetchingProduct && (
              <button
                className="text-sm text-black-multi mx-auto tracking-ultra-wide mt-8"
                type="button"
                onClick={() => navigate({
                  pathname: path.search,
                  search: createSearchParams({
                    availability: [selectedAvailability]
                  }).toString()
                })}
              >
                SEE ALL
              </button>
            )
          }
        </section>

      </section>

      <Footer hideSubscribeInput />
    </div>
  )
}

export default HomePage