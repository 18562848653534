import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '@/axios'
import config from '@/constant/config'
import { toResponse } from '@/utils/common'

const { api } = config

export const getHomepageProductConfig = createAsyncThunk(
  'config/getHomepageProductConfig',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(api.config.homepageProduct)
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const configSlice = createSlice({
  name: 'config',
  initialState: {},
  reducers: {}
})

export default configSlice.reducer