import React, { useEffect, useState } from 'react'
import { BiCurrentLocation } from 'react-icons/bi'
import banner from '@/assets/banner-about-us.jpg'
import { useDispatch } from 'react-redux'
import { getStoreLocations } from '@/store/slices/store-location'
import useLoader from '@/hooks/loader'

import GeneralHeader from '@/components/GeneralHeader'
import Footer from '@/components/Footer'

const Location = ({ image, title, address, location }) => (
  <div className="bg-black-multi flex flex-col">
    <img
      alt=""
      src={image}
      className="aspect-square w-[45rem] object-contain"
    />

    <div className="p-3 grow flex flex-col">
      <div className="text-yellow-multi font-semibold text-lg">
        {title}
      </div>

      <div className="text-white-multi text-xs my-4">
        {address}
      </div>

      <a
        href={location}
        target="_blank"
        className="text-xs bg-black-multi py-2.5 w-1/3 rounded-md border border-gray-multi-4 text-gray-multi-4 flex items-center justify-center mt-auto"
      >
        <BiCurrentLocation className="mr-1" />
        Location
      </a>
    </div>
  </div>
)

const AboutUsPage = () => {
  const dispatch = useDispatch()

  const [storeLocations, setStoreLocations] = useState([])
  const [_, setIsLoading] = useLoader(false)
  
  useEffect(() => {
    window.scrollTo(0, 0)
    fetchStoreLocations()
  }, [])

  const fetchStoreLocations = async () => {
    try {
      setIsLoading(true)
      const { data } = await dispatch(getStoreLocations()).unwrap()
      setStoreLocations(data)
      setIsLoading(false)
    } catch(error) {
      setIsLoading(false)
    }
  }

  return (
    <div className="flex flex-col min-h-screen">
      <GeneralHeader />

      <section className="bg-white-multi grow flex flex-col">
        <img
          src={banner}
          className="w-full object-contain lg:object-cover lg:h-[400px]"
          alt=""
        />

        <div className="p-5 lg:p-10 2xl:p-20">
          <div className="font-semibold text-xl lg:text-2xl lg:mb-4">
            Multi Toys
          </div>

          <p className="text-sm mt-3 lg:w-1/2">
            Multi Toys has established since year 2000 and provide a wide range of toys in Indonesia. Specialized in hobby collectibles of various movie characters, animation, cartoons and comic book.
            <br /><br />
            Multi Toys has several stores in several big cities in indonesia, Jakarta, Bandung and Surabaya. Multi Toys also provides online stores to facilitate easier access for customers to purchase their favorite products.
            <br /><br />
            Multi Toys is the authorized distributor in Indonesia for major Toy Companies in the world, such as Bandai, Hot Toys, Beast Kingdom, Good Smile Company, Kotobukiya and many more, continues to trust Multi Toys as their business partner.
            <br /><br />
            Multi Toys also implementing a Store + Cafe hybrid concept superstore to provide local communites a place for gathering and relax.
          </p>
        </div>

        <div className="bg-yellow-multi p-5 lg:p-10 2xl:p-20">
          <div className="font-semibold text-xl mb-4 lg:text-2xl">
            Our Locations
          </div>

          <div className="grid grid-cols-1 gap-4 lg:grid-cols-4">
            {
              storeLocations.map(storeLocation => (
                <Location
                  image={storeLocation.image.url}
                  title={storeLocation.name}
                  address={storeLocation.address}
                  location={storeLocation.locationUrl}
                />
              ))
            }
          </div>
        </div>
      </section>

      <Footer />
    </div>
  )
}

export default AboutUsPage