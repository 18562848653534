import React, { useRef } from 'react'
import Slider from 'react-slick'
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import useResponsive from '@/hooks/responsive'

const CarouselComponent = ({ component }) => {
  const slider = useRef(null)
  const { isDesktop } = useResponsive()

  return (
    <section className="mb-3 pt-2 pb-6 lg:py-6 self-center w-full relative">
      <button
        className="absolute p-2 left-4 top-1/2 -translate-y-1/2 text-lg text-black-multi z-10 rounded-full bg-white-multi bg-opacity-50 lg:text-3xl lg:left-8"
        type="button"
        onClick={() => slider.current?.slickPrev()}
      >
        <FaChevronLeft />
      </button>
      <button
        className="absolute p-2 right-4 top-1/2 -translate-y-1/2 text-lg text-black-multi z-10 rounded-full bg-white-multi bg-opacity-50 lg:text-3xl lg:right-8"
        type="button"
        onClick={() => slider.current?.slickNext()}
      >
        <FaChevronRight />
      </button>

      <Slider
        ref={slider}
        {...{
          slidesToShow: isDesktop ? 1.65 : 1,
          slidesToScroll: 1,
          autoplay: true,
          autoplaySpeed: 3000,
          dots: true,
          centerMode: true,
          arrows: false,
          customPaging: i => <div className="dot" />,
          dotsClass: "slick-dots slick-thumb",
          appendDots: dots => (
            <ul>
              {
                dots.map((dot, i) => (
                  <li
                    key={i}
                    onClick={() => slider.current?.slickGoTo(+dot.key)}
                    className={dot.props.className}
                  >
                    {dot.props.children}
                  </li>
                ))
              }
            </ul>
          )
        }}
      >
        {
          component.images.map((image, i) => (
            <img
              key={i}
              src={image.url}
              alt="Image"
              loading="lazy"
              className="px-2"
            />
          ))
        }
      </Slider>
    </section>
  )
}

export default CarouselComponent