import React from 'react'

const BannerComponent = ({ component }) => {
  return (
    <div className={['grid w-full gap-x-4', `grid-cols-${component.images.length}`].join(' ')}>
      {
        component.images.map((img, index) => (
          <div key={index}>
            <img
              src={img.url}
              className="w-full"
            />
          </div>
        ))
      }
    </div>
  )
}

export default BannerComponent