import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '@/axios'
import config from '@/constant/config'
import { toResponse } from '@/utils/common'

const { api } = config

export const getAddresses = createAsyncThunk(
  'address/getAddresses',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(api.address.base)
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const getAddress = createAsyncThunk(
  'address/getAddress',
  async (addressId, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(api.address.detail(addressId))
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const saveAddress = createAsyncThunk(
  'address/saveAddress',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(api.address.base, body)
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const updateAddress = createAsyncThunk(
  'address/updateAddress',
  async ({ addressId, body }, { rejectWithValue }) => {
    try {
      const { data } = await axios.put(api.address.detail(addressId), body)
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const deleteAddress = createAsyncThunk(
  'address/deleteAddress',
  async (addressId, { rejectWithValue }) => {
    try {
      const { data } = await axios.delete(api.address.detail(addressId))
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const setPrimaryAddress = createAsyncThunk(
  'address/setPrimaryAddress',
  async (addressId, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(api.address.detail(addressId))
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const addressSlice = createSlice({
  name: 'address',
  initialState: {},
  reducers: {}
})

export default addressSlice.reducer