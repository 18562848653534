import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '@/axios'
import config from '@/constant/config'
import { toResponse } from '@/utils/common'

const { api } = config

export const getProducts = createAsyncThunk(
  'product/getProducts',
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(api.product.filter, { params })
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const getProduct = createAsyncThunk(
  'product/getProduct',
  async (sku, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(api.product.detail(sku))
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const productSlice = createSlice({
  name: 'product',
  initialState: {},
  reducers: {}
})

export default productSlice.reducer