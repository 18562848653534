import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '@/axios'
import config from '@/constant/config'
import { toResponse } from '@/utils/common'

const { api } = config

export const isUnclaimedLink = createAsyncThunk(
  'one-time-link/isUnclaimedLink',
  async (linkId, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(api.oneTimeLink.isUnclaimedLink(linkId))
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const claimLink = createAsyncThunk(
  'one-time-link/claim',
  async (linkId, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(api.oneTimeLink.claim(linkId))
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const createLink = createAsyncThunk(
  'one-time-link/create',
  async (body, { rejectWithValue }) => {
    try {
      const { data } = await axios.post(api.oneTimeLink.create, body)
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const oneTimeLinkSlice = createSlice({
  name: 'oneTimeLink',
  initialState: {},
  reducers: {}
})

export default oneTimeLinkSlice.reducer