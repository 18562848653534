import React from 'react'

const FullPageLoader = () => {
  return (
    <>
      <div className="fixed top-0 left-0 h-screen w-screen z-[51] bg-black-multi opacity-30" />
      <div className="fixed h-screen w-screen flex justify-center items-center z-[51]">
        <span className="full-page-loader z-[51]" />
      </div>
    </>
  )
}

export default FullPageLoader