import React from 'react'
import { BiInfoCircle } from 'react-icons/bi'


const Ticker = ({ children, onClick, className }) => {
  return (
    <button
      type="button"
      className={'px-4 py-2 flex items-center bg-gray-multi-2 w-full ' + className}
      onClick={() => onClick && onClick()}
    >
      <BiInfoCircle className="mr-1 text-gray-multi" />
      {children}
    </button>
  )
}

export default Ticker