import React from 'react'
import { motion, AnimatePresence  } from 'framer-motion'
import useResponsive from '@/hooks/responsive'

import ScrollLock from '@/components/ScrollLock'

const BottomSheet = ({ visible, onClose, children, className = '' }) => {
  const { isDesktop } = useResponsive()

  return (
    <>
      <AnimatePresence mode="wait">
        {
          visible && (
            <>
              <motion.div
                className="fixed top-0 left-0 h-screen w-full bg-black-primary opacity-20 z-30"
                onClick={onClose}
                animate={{ opacity: 0.2 }}
                initial={{ opacity: 0 }}
                exit={{ opacity: 0 }}
                transition={{
                  opacity: {
                    duration: 0.06
                  }
                }}
              />

              <motion.section
                className={
                  "fixed bottom-0 left-0 bg-white-multi w-full rounded-tr-3xl rounded-tl-3xl z-30 " +
                  "lg:top-1/2 lg:left-1/2 lg:bottom-[unset] lg:-translate-x-1/2 lg:-translate-y-1/2 lg:w-1/2 xl:w-1/3 lg:shadow lg:rounded-none + " + className
                }
                animate={isDesktop ? { opacity: 1 } : { y: '0%' }}
                initial={isDesktop ? { opacity: 0 } : { y: '100%' }}
                exit={isDesktop ? { opacity: 0 } : { y: '100%' }}
                transition={!isDesktop ? {
                  duration: 0.2,
                  ease: 'easeOut'
                } : null}
              >
                <div className="m-5 px-2 overflow-y-auto max-h-[75vh] scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100">
                  {children}
                </div>
              </motion.section>
            </>
          )
        }
      </AnimatePresence>

      <ScrollLock isLocked={visible} />
    </>
  )
}

export default BottomSheet