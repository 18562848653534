import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '@/axios'
import config from '@/constant/config'
import { toResponse } from '@/utils/common'

const { api } = config

export const getCustomLayout = createAsyncThunk(
  'customLayout/getCustomLayout',
  async (slug, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(api.customLayout(slug))
      return data
    } catch(error) {
      return rejectWithValue(toResponse(error.response))
    }
  }
)

export const customLayoutSlice = createSlice({
  name: 'customLayout',
  initialState: {},
  reducers: {}
})

export default customLayoutSlice.reducer