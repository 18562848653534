import React from 'react'
import { useNavigate } from 'react-router-dom'
import { FaChevronLeft } from 'react-icons/fa'

const TitleHeader = ({ title, backUrl, onBackClick, noBackButton = false, slot }) => {
  const navigate = useNavigate()

  return (
    <section className="sticky top-0 z-20">
      <div className="px-3 py-3.5 flex items-center bg-yellow-multi">
        {
          !noBackButton && (
            <button
              type="button"
              onClick={() => {
                if (onBackClick) {
                  onBackClick()
                  return
                }
                return navigate(backUrl ? backUrl : -1)
              }}
              className="mr-4"
            >
              <FaChevronLeft className="text-lg" />
            </button>
          )
        }

        <div className="font-semibold text-lg">
          {title}
        </div>

        {
          !!slot && slot()
        }
      </div>
      <div className="w-full h-[1.2px] bg-black-multi" />
    </section>
  )
}

export default TitleHeader