import React, { useCallback, useState } from 'react'
import { GoogleMap, LoadScript, Marker, Autocomplete } from '@react-google-maps/api'
import config from '@/constant/config'

import BottomSheet from '@/components/BottomSheet'


const defaultPosition = {
  lat: -6.2295695,
  lng: 106.7471179
}

const AddressMap = ({ position, visible, onClose }) => {
  const apiKey = process.env.REACT_APP_GMAPS_API_KEY

  const [markerPosition, setMarkerPosition] = useState(position)
  const [autocomplete, setAutocomplete] = useState(null)

  const handleMapClick = useCallback((event) => {
    setMarkerPosition({
      lat: event.latLng.lat(),
      lng: event.latLng.lng()
    })
  }, [])

  const handleMarkerDragEnd = useCallback((event) => {
    setMarkerPosition({
      lat: event.latLng.lat(),
      lng: event.latLng.lng()
    })
  }, [])

  const onLoad = useCallback((autocomplete) => {
    setAutocomplete(autocomplete)
  }, [])

  const onPlaceChanged = useCallback(() => {
    if (autocomplete !== null) {
      const place = autocomplete.getPlace()
      if (place.geometry && place.geometry.location) {
        setMarkerPosition({
          lat: place.geometry.location.lat(),
          lng: place.geometry.location.lng()
        })
      }
    } else {
      console.log('Autocomplete is not loaded yet!')
    }
  }, [autocomplete])

  return (
    <BottomSheet
      visible={visible}
      onClose={() => onClose(markerPosition)}
    >
      <div className="flex flex-col">
        <div className="font-semibold text-2xl mb-3">
          Location
        </div>

        <LoadScript
          googleMapsApiKey={apiKey}
          libraries={config.gmaps.libraries}
        >
          <div>
            <Autocomplete
              onLoad={onLoad}
              onPlaceChanged={onPlaceChanged}
            >
              <input
                type="text"
                placeholder="Search for a location"
                className="input-primary mb-2"
                onKeyDown={e => {
                  if (e.key === 'Enter') {
                    e.preventDefault()
                  }
                }}
              />
            </Autocomplete>
            <GoogleMap
              mapContainerStyle={{
                width: '100%',
                height: '400px'
              }}
              center={markerPosition || defaultPosition}
              zoom={15}
              onClick={handleMapClick}
            >
              <Marker
                position={markerPosition || defaultPosition}
                draggable={true}
                onDragEnd={handleMarkerDragEnd}
              />
            </GoogleMap>
          </div>
        </LoadScript>

        <div className="w-1/5 self-end mt-4">
          <button
            type="button"
            className="button-primary"
            onClick={() => onClose(markerPosition)}
          >
            Save
          </button>
        </div>
      </div>
    </BottomSheet>
  )
}

export default AddressMap