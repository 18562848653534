import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { userSelector } from '@/store/slices/user'
import { countCartItems } from '@/store/slices/cart'
import { getCheckoutItems, checkoutPayment } from '@/store/slices/checkout'
import { getAddresses } from '@/store/slices/address'
import { createOrder } from '@/store/slices/order'
import { useNavigate, createSearchParams, useSearchParams, useLocation, Link } from 'react-router-dom'
import { toCapitalCase, numberFormatter } from '@/utils/common'
import toast from 'react-hot-toast'
import config from '@/constant/config'
import useLoader from '@/hooks/loader'
import useAuthentication from '@/hooks/authentication'
import { useForm, useWatch } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import classNames from 'classnames'
import useResponsive from '@/hooks/responsive'
import useDidUpdateEffect from '@/hooks/did-update-effect'

import GeneralHeader from '@/components/GeneralHeader'
import TitleHeader from '@/components/TitleHeader'
import Checkbox from '@/components/Checkbox'
import Footer from '@/components/Footer'
import BottomSheet from '@/components/BottomSheet'
import CheckoutItem from '@/components/CheckoutItem'
import CheckoutAddressModal from '@/components/CheckoutAddressModal'

const { path } = config

const validationSchema = yup.object().shape({
  addressId: yup.string().required(),
  agreeToWaitingListPolicy: yup.boolean().default(false).notRequired(),
  notes: yup.string().notRequired()
})

const CheckoutPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const [searchParams] = useSearchParams()

  const [visibleVoucherModal, setVisibleVoucherModal] = useState(false)
  const [voucherCode, setVoucherCode] = useState('')
  const [isPaying, setIsPaying] = useState(false)

  const { isDesktop } = useResponsive()

  const {
    register,
    handleSubmit,
    setValue,
    control
  } = useForm({
    resolver: yupResolver(validationSchema)
  })

  const agreeToWaitingListPolicy = useWatch({
    control,
    name: 'agreeToWaitingListPolicy'
  })

  const selectedAddressId = useWatch({
    control,
    name: 'addressId'
  })

  const { user } = useSelector(userSelector)

  const { unauthorized } = useAuthentication()
  const [, setIsLoading] = useLoader()
  const [addresses, setAddresses] = useState([])
  const [checkoutDetail, setCheckoutDetail] = useState({})
  const [visibleChooseAddressModal, setVisibleChooseAddressModal] = useState(false)
  const [shippingMethod, setShippingMethod] = useState({})

  useEffect(() => {
    init()
  }, [])

  useDidUpdateEffect(() => {
    getUpdatedShippingRates()
  }, [shippingMethod])

  useEffect(() => {
    const primaryAddress = addresses.find(address => address.primary) || {}
    setValue('addressId', primaryAddress.id)
  }, [addresses])

  const appliedVoucher = checkoutDetail.prices?.find(price => price.id === 'VOUCHER') || {}

  const handleFetchCheckoutItemsError = error => {
    if (error.data?.error === 'NO_ADDRESS') {
      navigate({
        pathname: path.address,
        search: createSearchParams({
          page: window.btoa(location.pathname + location.search)
        }).toString()
      })
      return
    }

    if (error.data?.error === 'NO_PHONE') {
      const pageParam = createSearchParams({
        page: window.btoa(location.pathname + location.search)
      }).toString()

      navigate(`${path.registerCompletion}?${pageParam}`, {
        state: {
          email: user.email
        }
      })
      return
    }
  }

  const init = async () => {
    try {
      setIsLoading(true)

      await fetchAddresses()

      const checkoutDetail = await fetchCheckoutItemsWithParam(voucherCode, true)
      setCheckoutDetail(checkoutDetail)

      setIsLoading(false)
    } catch(error) {
      unauthorized(error, () => {
        setIsLoading(false)
        handleFetchCheckoutItemsError(error)
      })
    }
  }

  const fetchCheckoutItemsWithParam = async (voucher, ignoreShipping = false) => {
    const instantBuySku = searchParams.get('sku')
    const instantBuyQty = searchParams.get('quantity')

    const { data } = await dispatch(getCheckoutItems({
      voucherCode: voucher,
      shipping: ignoreShipping ? undefined : {
        id: shippingMethod.id,
        company: shippingMethod.company,
        type: shippingMethod.type,
        destinationAddressId: selectedAddressId
      },
      ...(instantBuySku &&instantBuyQty ? {
        instantBuy: {
          sku: instantBuySku,
          quantity: instantBuyQty
        }
      } : {})
    })).unwrap()
    return data
  }

  const fetchAddresses = async () => {
    const { data } = await dispatch(getAddresses()).unwrap()
    setAddresses(data)
  }

  const selectedAddress = addresses.find(address => address.id === selectedAddressId) || {}

  const selectedAddressText = `${toCapitalCase(selectedAddress.province?.name)}, ${toCapitalCase(selectedAddress.regency?.name)}, ${toCapitalCase(selectedAddress.district?.name)}, ${toCapitalCase(selectedAddress.village?.name)}, ${selectedAddress.postalCode}`

  const doPay = async form => {
    try {
      setIsLoading(true)

      const instantBuySku = searchParams.get('sku')
      const instantBuyQty = searchParams.get('quantity')

      const { data: checkoutId } = await dispatch(checkoutPayment({
        voucherCode: appliedVoucher.text,
        destinationAddressId: form.addressId,
        shipping: {
          id: shippingMethod.id,
          company: shippingMethod.company,
          type: shippingMethod.type,
          destinationAddressId: form.addressId
        },
        notes: form.notes,
        ...(instantBuySku &&instantBuyQty ? {
          instantBuy: {
            sku: instantBuySku,
            quantity: instantBuyQty
          }
        } : {})
      })).unwrap()

      const { data: invoiceUrl } = await dispatch(createOrder(checkoutId)).unwrap()

      setIsPaying(true)

      await dispatch(countCartItems()).unwrap()

      window.location.href = invoiceUrl

      setIsLoading(false)
    } catch(error) {
      unauthorized(error, () => {
        setIsLoading(false)
        setIsPaying(false)
        console.log(error)

        if (error.data?.error === 'BLANK_SHIPPING_METHOD') {
          toast.error('Choose shipping method')
          return
        }

        if (error.data?.error === 'HAS_INVALID_PRODUCT' || error.data?.error === 'INVALID_SHIPPING_METHOD') {
          toast.error('Something has changed, please refresh the page')
          return
        }
      })
    }
  }

  const applyVoucher = async () => {
    try {
      setIsLoading(true)

      const checkoutDetail = await fetchCheckoutItemsWithParam(voucherCode)
      setCheckoutDetail(checkoutDetail)

      setVisibleVoucherModal(false)
      setVoucherCode('')

      setIsLoading(false)
    } catch(error) {
      unauthorized(error, () => {
        setIsLoading(false)

        if (error?.data?.error === 'VOUCHER_NOT_EXIST') {
          toast.error('The voucher entered doesn\'t exist. Please check and try again.')
          return
        }
        if (error?.data?.error === 'VOUCHER_USED') {
          toast.error('This voucher already been used')
          return
        }
        if (error?.data?.error === 'VOUCHER_NOT_APPLICABLE') {
          toast.error('This voucher doesn\'t apply to the selected products.')
          return
        }
        if (error?.data?.error === 'PRICE_NOT_SUFFICIENT') {
          toast.error('Spend more to use voucher')
          return
        }

        console.log(error)
        toast.error('Something went wrong, please try again')
      })
    }
  }

  const removeVoucher = async () => {
    try {
      setIsLoading(true)

      const checkoutDetail = await fetchCheckoutItemsWithParam(null)
      setCheckoutDetail(checkoutDetail)

      setIsLoading(false)
    } catch(error) {
      unauthorized(error, () => {
        setIsLoading(false)
        console.log(error)
        toast.error('Something went wrong, please try again')
      })
    }
  }

  const appendShippingMethod = (id, shipping) => {
    if (!shipping || !id || id !== 'READY_STOCK') {
      return
    }

    setShippingMethod({
      id,
      company: shipping.company,
      type: shipping.type
    })
  }

  const resetShippingOnChangingAddress = async () => {
    try {
      const checkoutDetail = await fetchCheckoutItemsWithParam(appliedVoucher.text, true)
      setCheckoutDetail(checkoutDetail)
      setShippingMethod({})
    } catch(error) {
      unauthorized(error, () => {
        setIsLoading(false)
        console.log(error)
        toast.error('Something went wrong, please try again')
      })
    }
  }

  const getUpdatedShippingRates = async () => {
    try {
      setIsLoading(true)

      const checkoutDetail = await fetchCheckoutItemsWithParam(appliedVoucher.text)
      setCheckoutDetail(checkoutDetail)

      setIsLoading(false)
    } catch(error) {
      unauthorized(error, () => {
        setIsLoading(false)
        console.log(error)
        toast.error('Something went wrong, please try again')
      })
    }
  }

  const disablePayNowButton = () => (checkoutDetail.hasWaitingList && !agreeToWaitingListPolicy) || !checkoutDetail.totalPrice || isPaying

  return (
    <>
      {
        visibleChooseAddressModal && (
          <CheckoutAddressModal
            visible={visibleChooseAddressModal}
            onClose={() => setVisibleChooseAddressModal(false)}
            addresses={addresses}
            register={register}
            selectedAddress={selectedAddressId}
            onAddressChange={resetShippingOnChangingAddress}
          />
        )
      }

      {
        visibleVoucherModal && (
          <BottomSheet
            visible={visibleVoucherModal}
            onClose={() => setVisibleVoucherModal(false)}
          >
            <section className="p-5 flex flex-col gap-y-8">
              <div className="text-2xl font-semibold">
                Add Voucher
              </div>

              <input
                type="text"
                className="input-primary"
                onChange={e => setVoucherCode(e.target.value.toUpperCase())}
                value={voucherCode}
              />

              <button
                type="button"
                className="button-primary"
                onClick={applyVoucher}
              >
                Apply
              </button>
            </section>
          </BottomSheet>
        )
      }

      <form
        className="flex flex-col min-h-screen"
        onSubmit={handleSubmit(doPay)}
      >
        {
          isDesktop ? (
            <GeneralHeader />
          ) : (
            <TitleHeader title="Payment Summary" />
          )
        }

        <section className="bg-white-multi grow p-5 relative lg:py-8 flex justify-center">
          <section className="flex w-full lg:items-start lg:gap-x-8 lg:w-[90%] 2xl:w-[70%]">
            <section className="w-full flex flex-col lg:w-2/3">
              {
                isDesktop && (
                  <div className="font-semibold text-2xl mb-4">
                    Payment Summary
                  </div>
                )
              }

              <section className="mb-4">
                <div className="flex justify-between items-center mb-3">
                  <div className="font-semibold">
                    Address
                  </div>

                  <button
                    type="button"
                    className="text-xs text-yellow-multi-2"
                    onClick={() => setVisibleChooseAddressModal(true)}
                  >
                    Change
                  </button>
                </div>

                <div className="text-sm font-semibold">
                  {selectedAddress.name}
                </div>

                <div className="text-sm">
                  <div>
                    {selectedAddress.detail}
                  </div>
                  <div>
                    {selectedAddressText}
                  </div>

                  {
                    !!selectedAddress.notes && (
                      <small className="italic text-gray-multi">
                        {selectedAddress.notes}
                      </small>
                    )
                  }
                </div>

                <hr className="mt-3 border-gray-multi-4" />
              </section>

              <section className="mb-4">
                <div className="font-semibold mb-1.5">
                  Products
                </div>

                {
                  checkoutDetail?.items?.map((checkoutItem, index) => (
                    <CheckoutItem
                      key={checkoutItem.id}
                      id={checkoutItem.id}
                      index={index}
                      products={checkoutItem.products}
                      subtotal={checkoutItem.subtotal}
                      destinationAddressId={selectedAddressId}
                      onChangeShippingMethod={appendShippingMethod}
                      shippingMethod={checkoutItem.shipping}
                    />
                  ))
                }
              </section>

              {
                !isDesktop && checkoutDetail.hasWaitingList && (
                  <section className="mb-4 p-3 bg-gray-multi-2 rounded-lg flex items-center">
                    <div className="w-[10%]">
                      <Checkbox
                        label=""
                        id="waiting-list-warning"
                        size="xs"
                        {...register('agreeToWaitingListPolicy')}
                      />
                    </div>
                    <div className="text-xs">
                      I understand Waiting List items may not always be available, and I agree to the <Link to={path.tnc} className="text-blue-800">refund policy</Link>.
                    </div>
                  </section>
                )
              }
              {
                !isDesktop && !checkoutDetail.hasWaitingList && (
                  <section className="mb-4 p-3 bg-gray-multi-2 rounded-lg flex text-xs">
                    <div>
                      By proceeding, you agree to our <Link to={path.tnc} className="text-blue-800">terms and conditions</Link>.
                    </div>
                  </section>
                )
              }

              {
                !isDesktop && (
                  <section className="mb-4">
                    <div className="font-semibold mb-1.5">
                      Order Detail
                    </div>

                    {
                      checkoutDetail?.prices?.map(price => (
                        <div
                          key={price.id}
                          className={classNames({
                            'text-sm flex justify-between my-1': true,
                            'text-green-multi': price.nominal < 0
                          })}
                        >
                          <div>
                            <div>
                              {price.text}
                            </div>
                            {
                              price.id === 'VOUCHER' && (
                                <button
                                  type="button"
                                  className="text-[11px] text-black-multi font-semibold"
                                  onClick={removeVoucher}
                                >
                                  Remove
                                </button>
                              )
                            }
                          </div>
                          <div>
                            {
                              price.nominal < 0 ? `-${numberFormatter(Math.abs(price.nominal), 'Rp')}` : numberFormatter(price.nominal, 'Rp')
                            }
                          </div>
                        </div>
                      ))
                    }
                  </section>
                )
              }

              <section className="mb-4 lg:w-2/3">
                <div className="font-semibold mb-1.5">
                  Notes
                </div>
                <input
                  type="text"
                  placeholder="Enter your notes"
                  className="input-primary"
                  {...register('notes')}
                />
              </section>
            </section>

            {
              isDesktop && (
                <section className="sticky top-20 left-0 w-1/3 ml-auto self-start 2xl:w-1/4">
                  <section className="lg:border lg:border-gray-multi p-4 mb-4">
                    <div className="font-semibold text-lg">
                      Order Detail
                    </div>

                    <div className="my-6">
                      {
                        checkoutDetail?.prices?.map(price => (
                          <div
                            key={price.id}
                            className={classNames({
                              'text-sm flex justify-between my-1': true,
                              'text-green-multi': price.nominal < 0
                            })}
                          >
                            <div>
                              <div>
                                {price.text}
                              </div>
                              {
                                price.id === 'VOUCHER' && (
                                  <button
                                    type="button"
                                    className="text-[11px] text-black-multi font-semibold"
                                    onClick={removeVoucher}
                                  >
                                    Remove
                                  </button>
                                )
                              }
                            </div>
                            <div>
                              {
                                price.nominal < 0 ? `-${numberFormatter(Math.abs(price.nominal), 'Rp')}` : numberFormatter(price.nominal, 'Rp')
                              }
                            </div>
                          </div>
                        ))
                      }
                    </div>

                    <div className="font-semibold">
                      Total Price
                    </div>
                    <div className="text-red-multi font-semibold text-xl">
                      {numberFormatter(checkoutDetail.totalPrice, 'Rp')}
                    </div>

                    <button
                      type="submit"
                      className={classNames({
                        'button-primary mt-8 mb-3': true,
                        'disabled': disablePayNowButton()
                      })}
                      disabled={disablePayNowButton()}
                    >
                      Pay Now
                    </button>

                    <button
                      type="button"
                      className={classNames({
                        'button-secondary': true,
                        'disabled': !checkoutDetail.totalPrice
                      })}
                      onClick={() => setVisibleVoucherModal(true)}
                      disabled={!checkoutDetail.totalPrice}
                    >
                      Add Voucher
                    </button>
                  </section>

                  {
                    checkoutDetail.hasWaitingList && (
                      <section className="mb-4 p-3 bg-gray-multi-2 rounded-lg flex">
                        <div className="w-[10%] flex items-center">
                          <Checkbox
                            label=""
                            id="waiting-list-warning"
                            size="xs"
                            {...register('agreeToWaitingListPolicy')}
                          />
                        </div>
                        <div className="text-xs">
                          I understand Waiting List items may not always be available, and I agree to the <Link to={path.tnc} className="text-blue-800">refund policy</Link>.
                        </div>
                      </section>
                    )
                  }
                  {
                    !checkoutDetail.hasWaitingList && (
                      <section className="mb-4 p-3 bg-gray-multi-2 rounded-lg flex text-xs">
                        <div>
                          By proceeding, you agree to our  <Link to={path.tnc} className="text-blue-800">terms and conditions</Link>.
                        </div>
                      </section>
                    )
                  }
                </section>
              )
            }
          </section>
        </section>

        {
          !isDesktop && (
            <section className="sticky bottom-0 left-0 bg-white-multi px-4 py-3 border-t-2 border-black-multi">
              <div className="font-semibold text-sm">
                Total Price
              </div>
              <div className="flex justify-between items-center">
                <div className="text-red-multi font-semibold text-xl">
                  {numberFormatter(checkoutDetail.totalPrice, 'Rp')}
                </div>

                <button
                  type="button"
                  className={classNames({
                    'text-xs bg-white-multi border border-black-multi p-2 text-black-multi': true,
                    'text-gray-multi brightness-95': !checkoutDetail.totalPrice
                  })}
                  onClick={() => setVisibleVoucherModal(true)}
                  disabled={!checkoutDetail.totalPrice}
                >
                  Add Voucher
                </button>
              </div>

              <button
                type="submit"
                className={classNames({
                  'button-primary mt-2': true,
                  'disabled': disablePayNowButton()
                })}
                disabled={disablePayNowButton()}
              >
                Pay Now
              </button>
            </section>
          )
        }

        {
          isDesktop && <Footer />
        }
      </form>
    </>
  )
}

export default CheckoutPage