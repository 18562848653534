import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { trackShipping } from '@/store/slices/order'
import toast from 'react-hot-toast'
import useLoader from '@/hooks/loader'
import useAuthentication from '@/hooks/authentication'
import { snakeCaseToCapitalCase } from '@/utils/common'

import BottomSheet from '@/components/BottomSheet'
import { DateTime } from 'luxon'

const ShippingHistoryModal = ({ visible, onClose, shippingId }) => {
  const dispatch = useDispatch()

  const [, setIsLoading] = useLoader()
  const { unauthorized } = useAuthentication()

  const [shippingHistory, setShippingHistory] = useState({})

  useEffect(() => {
    if (!visible) {
      return
    }
    fetchShippingHistory()
  }, [visible])

  const fetchShippingHistory = async () => {
    try {
      setIsLoading(true)

      const { data } = await dispatch(trackShipping(shippingId)).unwrap()
      setShippingHistory(data)

      setIsLoading(false)
    } catch(error) {
      unauthorized(error, () => {
        setIsLoading(false)
        toast.error('Something went wrong, please try again')
      })
    }
  }

  const histories = () => {
    return (shippingHistory.courier?.history || [])
      .map(h => ({ ...h, millis: new Date(h.updatedAt) }))
      .sort((a, b) => b.millis - a.millis)
  }

  const toDate = dateString => {
    return DateTime.fromISO(dateString)
      .toFormat('dd MMMM yyyy, HH:mm')
  }

  return (
    <BottomSheet
      visible={visible}
      onClose={onClose}
    >
      <div className="text-lg font-semibold mb-3">
        Shipping History
      </div>

      <section>
        {
          histories().map((history, i, self) => (
            <div
              className="flex mb-0.5"
              key={i}
            >
              <div className="mr-3 flex flex-col items-center">
                <div className="w-5 h-5 rounded-full bg-yellow-multi border-4 border-gray-multi" />
                {
                  i !== self.length - 1 && (
                    <div className="h-20 border-dashed border-l-[3px] border-gray-multi" />
                  )
                }
              </div>

              <div className="flex flex-col">
                <div className="font-semibold">
                  {snakeCaseToCapitalCase(history.status)}
                </div>
                <div
                  className="font-light mt-1.5 mb-0.5 line-clamp-2"
                  title={history.note}
                >
                  {history.note}
                </div>
                <div className="font-light">
                  {toDate(history.updatedAt)}
                </div>
              </div>
            </div>
          ))
        }
      </section>
    </BottomSheet>
  )
}

export default ShippingHistoryModal