import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { subscribeNewsletter } from '@/store/slices/newsletter'
import logo from '@/assets/logo.png'
import { FaFacebookSquare, FaInstagram, FaWhatsapp } from 'react-icons/fa'
import config from '@/constant/config'
import useResponsive from '@/hooks/responsive'
import { isValidEmail } from '@/utils/common'
import toast from 'react-hot-toast'
import useLoader from '@/hooks/loader'

const { path } = config
const { whatsapp } = config.link

const Footer = ({ hideSubscribeInput = false }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { isDesktop } = useResponsive()
  const [, setIsLoading] = useLoader()

  const [email, setEmail] = useState('')

  const subscribe = async () => {
    try {
      if (!isValidEmail(email)) {
        toast.error('Invalid email')
        return
      }

      setIsLoading(true)

      await dispatch(subscribeNewsletter({
        email
      })).unwrap()
      toast.success('Subscribed!')
      setEmail('')

      setIsLoading(false)
    } catch(err) {
      setIsLoading(false)
      toast.error('Something went wrong, please try again')
    }
  }

  return (
    <section className="bg-black-multi py-6 px-4 grid grid-cols-1 lg:grid-cols-3 lg:py-10 lg:px-10">
      <img
        src={logo}
        alt="logo"
        className="w-20 lg:w-32"
        onClick={() => navigate(path.base)}
      />

      {
        !hideSubscribeInput ? (
          <div className="mt-8 lg:mt-0 lg:ml-0 lg:w-3/4">
            <div className="text-yellow-multi font-semibold mb-2">
              Subscribe for newsletter
            </div>
            <div className="flex items-center gap-x-2">
              <input
                type="text"
                className="p-3 border border-white-multi w-full bg-black-multi text-sm outline-none text-white-multi"
                placeholder="Email"
                onChange={e => setEmail(e.target.value)}
                value={email}
              />
              <div className="w-1/5">
                <button
                  className="button-primary dark"
                  onClick={subscribe}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        ) : <div />
      }

      <div className="text-sm mt-8 flex flex-col items-start lg:items-end lg:mt-0 lg:ml-0">
        <div className="text-yellow-multi font-semibold">
          Company
        </div>

        <button
          type="button"
          className="text-white-multi mt-2"
          onClick={() => navigate(path.aboutUs)}
        >
          About us
        </button>

        <button
          type="button"
          className="text-white-multi mt-2"
          onClick={() => navigate(path.tnc)}
        >
          Terms and Conditions
        </button>
      </div>

      <div className="flex text-yellow-multi text-xl w-full gap-x-6 my-10 lg:col-span-2">
        <a
          href="https://www.instagram.com/multitoys/?hl=en"
          target="_blank"
        >
          <FaInstagram  />
        </a>
        <a
          href="https://www.facebook.com/multitoys/?locale=id_ID&_rdc=2&_rdr"
          target="_blank"
        >
          <FaFacebookSquare />
        </a>
        <a
          href={whatsapp}
          target="_blank"
        >
          <FaWhatsapp />
        </a>
      </div>

      <div className="text-gray-multi text-[11px] lg:col-span-2">
        {
          isDesktop && <hr className="mb-2 border-gray-multi" />
        }
        &copy; 2001-2023 PT Multi Sarana Prima Nusantara
      </div>
    </section>
  )
}

export default Footer