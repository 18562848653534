import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { useNavigate, createSearchParams, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { userSelector, updateUser, loginInfo, setUser, setIsGettingUser } from '@/store/slices/user'
import { getAddresses } from '@/store/slices/address'
import { sendChangePasswordOtp, validateChangePasswordOtp, resendChangePasswordOtp } from '@/store/slices/otp'
import { createLink } from '@/store/slices/oneTimeLink'
import config from '@/constant/config'
import toast from 'react-hot-toast'
import { DateTime } from 'luxon'
import { HiPencilAlt, HiOutlinePlusSm } from 'react-icons/hi'
import { FaChevronRight } from 'react-icons/fa'
import useLoader from '@/hooks/loader'
import useAuthentication from '@/hooks/authentication'
import useResponsive from '@/hooks/responsive'

import GeneralHeader from '@/components/GeneralHeader'
import TitleHeader from '@/components/TitleHeader'
import RadioButton from '@/components/RadioButton'
import DatePicker from '@/components/DatePicker'
import NumberInput from '@/components/NumberInput'
import OtpModal from '@/components/OtpModal'
import Footer from '@/components/Footer'

const { path } = config

const PHONE_REGEX = /^(\+62|62|0)8[1-9][0-9]{6,10}$/

const validationSchema = yup.object().shape({
  name: yup.string().required(),
  dob: yup.date().required(),
  phone: yup.string()
    .matches(PHONE_REGEX)
    .required(),
  gender: yup.string().required()
})

const EditProfilePage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const { isDesktop } = useResponsive()
  const { user } = useSelector(userSelector)

  const { unauthorized } = useAuthentication()
  const [, setIsLoading] = useLoader()

  const [dob, setDob] = useState()
  const [addresses, setAddresses] = useState([])

  const [visibleChangePasswordModal, setVisibleChangePasswordModal] = useState(false)
  const [changePasswordOtpId, setChangePasswordOtpId] = useState('')

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    control,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  })

  useEffect(() => {
    if (!user) {
      navigate({
        pathname: path.login,
        search: createSearchParams({
          page: window.btoa(location.pathname)
        }).toString()
      })
      return
    }

    register('dob')

    reset({
      name: user.name,
      phone: user.phone,
      gender: user.gender
    })
    user.dob && setDob(new Date(user.dob))

    fetchAddresses()
  }, [])

  useEffect(() => {
    setValue('dob', dob)
  }, [dob])

  const fetchAddresses = async () => {
    try {
      setIsLoading(true)

      const { data } = await dispatch(getAddresses()).unwrap()
      setAddresses(data)

      setIsLoading(false)
    } catch(error) {
      setIsLoading(false)
      toast.error('Something went wrong, please try again')
    }
  }

  const getLoginInfo = async () => {
    try {
      const response = await dispatch(loginInfo()).unwrap()
      dispatch(setIsGettingUser(false))
      dispatch(setUser(response.data))
    } catch(err) {
      dispatch(setIsGettingUser(false))
    }
  }

  const doUpdate = async form => {
    try {
      setIsLoading(true)

      const requestBody = {
        email: user.email,
        name: form.name,
        phone: form.phone,
        dob: DateTime.fromJSDate(form.dob)
          .setZone('Asia/Jakarta')
          .toJSDate(),
        gender: form.gender
      }
      await dispatch(updateUser(requestBody)).unwrap()

      await getLoginInfo()

      setIsLoading(false)

      toast.success('Profile updated')

      navigate(path.profile)
    } catch(error) {
      unauthorized(error, () => {
        setIsLoading(false)
        toast.error('Something went wrong, please try again')
      })
    }
  }

  const requestChangePassword = async () => {
    try  {
      if (changePasswordOtpId) {
        setVisibleChangePasswordModal(true)
        return
      }

      setIsLoading(true)

      const { data } = await dispatch(sendChangePasswordOtp({
        email: user.email
      })).unwrap()
      setChangePasswordOtpId(data)
      setVisibleChangePasswordModal(true)

      setIsLoading(false)
    } catch(error) {
      unauthorized(error, () => {
        setIsLoading(false)
        toast.error('Something went wrong, please try again')
      })
    }
  }

  const verifyChangePasswordOtp = async (otp, otpId) => {
    await dispatch(validateChangePasswordOtp({
      token: otp,
      otpId
    })).unwrap()

    const { data: link } = await dispatch(createLink({ type: 'CHANGE_PASSWORD' })).unwrap()

    navigate(path.changePassword.replace(':linkId', link))
  }

  const resendOtp = async otpId => {
    await dispatch(resendChangePasswordOtp({ otpId })).unwrap()
  }

  if (!user) {
    return <div className="bg-white-multi h-screen" />
  }

  if (!isDesktop) {
    return (
      <>
        {
          changePasswordOtpId && (
            <OtpModal
              visible={visibleChangePasswordModal}
              onClose={() => setVisibleChangePasswordModal(false)}
              otpId={changePasswordOtpId}
              resend={resendOtp}
              verify={verifyChangePasswordOtp}
            />
          )
        }

        <div className="flex flex-col h-screen">
          <TitleHeader
            title="Edit Profile"
            backUrl={path.profile}
          />

          <section className="bg-white-multi grow">
            <form
              onSubmit={handleSubmit(doUpdate)}
              className="flex flex-col h-full"
            >
              <div className="p-5">
                <div className="mb-4">
                  <div className="font-semibold mb-1.5">
                    Email
                  </div>
                  <input
                    type="text"
                    placeholder="Enter your email address"
                    className="input-primary disabled"
                    value={user.email}
                    disabled
                  />
                </div>

                <div className="mb-4">
                  <div className="font-semibold mb-1.5">
                    Name
                  </div>
                  <input
                    type="text"
                    placeholder="Enter your full name"
                    className="input-primary"
                    {...register('name')}
                  />
                  {
                    errors.name && (
                      <small className="text-red-multi italic">
                        Name cannot be empty
                      </small>
                    )
                  }
                </div>

                <div className="mb-4">
                  <div className="font-semibold mb-1.5">
                    Date of Birth
                  </div>

                  <DatePicker
                    value={dob}
                    onChange={setDob}
                    placeholder="Select your date of birth"
                    maxDate={new Date()}
                  />
                  {
                    errors.dob && (
                      <small className="text-red-multi italic">
                        Date of Birth cannot be empty
                      </small>
                    )
                  }
                </div>

                <div className="mb-4">
                  <div className="font-semibold mb-1.5">
                    Phone Number
                  </div>
                  <NumberInput
                    name="phone"
                    control={control}
                    allowLeadingZeros
                    placeholder="Enter your phone number"
                    className="input-primary"
                  />
                  {
                    errors.phone && (
                      <small className="text-red-multi italic">
                        Invalid phone number
                      </small>
                    )
                  }
                </div>

                <div className="mb-4">
                  <div className="font-semibold mb-1.5">
                    Gender
                  </div>

                  <div className="flex mb-1">
                    <RadioButton
                      id="MALE"
                      text="Male"
                      value="MALE"
                      name="GENDER"
                      className="mr-6"
                      {...register('gender')}
                    />
                    <RadioButton
                      id="FEMALE"
                      text="Female"
                      value="FEMALE"
                      name="GENDER"
                      {...register('gender')}
                    />
                  </div>

                  {
                    errors.gender && (
                      <small className="text-red-multi italic">
                        Gender cannot be empty
                      </small>
                    )
                  }
                </div>

                {
                  user?.hasPassword && (
                    <button
                      type="button"
                      className="outline-none font-semibold my-8 flex items-center"
                      onClick={requestChangePassword}
                    >
                      Change Password
                      <FaChevronRight className="ml-2" />
                    </button>
                  )
                }
                {
                  !user?.hasPassword && (
                    <button
                      type="button"
                      className="outline-none font-semibold my-8 flex items-center"
                      onClick={() => navigate(path.createPassword)}
                    >
                      Create Password
                      <FaChevronRight className="ml-2" />
                    </button>
                  )
                }
              </div>

              <div className="w-full bg-black-multi mb-4" style={{ height: '1px' }} />

              <div className="px-5">
                <div className="font-semibold mb-3">
                  Address
                </div>

                {
                  addresses.map(address => (
                    <div
                      key={address.id}
                      className="mb-3"
                    >
                      <div className="flex items-center">
                        <div className="text-black-multi font-semibold text-sm">
                          {address.name}
                        </div>
                        {
                          address.primary && (
                            <div className="ml-2 px-2.5 py-0.5 text-[10px] rounded-full bg-black-multi text-yellow-multi">
                              Primary
                            </div>
                          )
                        }
                        <button
                          type="button"
                          className="ml-auto"
                          onClick={() => navigate(path.editAddress.replace(':addressId', address.id))}
                        >
                          <HiPencilAlt />
                        </button>
                      </div>

                      <div className="text-black-multi text-sm">
                        {address.detail}
                      </div>
                    </div>
                  ))
                }

                {
                  addresses.length < 3 && (
                    <div className="my-6">
                      <button
                        type="button"
                        className="text-sm flex items-center font-semibold"
                        onClick={() => navigate(path.address)}
                      >
                        <HiOutlinePlusSm />
                        Add Address
                      </button>
                    </div>
                  )
                }
              </div>

              <div className="mt-auto">
                <div className="w-full bg-black-multi mb-4" style={{ height: '0.5px' }} />

                <div className="px-5 pb-5">
                  <button
                    type="submit"
                    className="button-primary"
                  >
                    Update Profile
                  </button>
                </div>
              </div>
            </form>
          </section>
        </div>
      </>
    )
  }

  return (
    <>
      {
        changePasswordOtpId && (
          <OtpModal
            visible={visibleChangePasswordModal}
            onClose={() => setVisibleChangePasswordModal(false)}
            otpId={changePasswordOtpId}
            resend={resendOtp}
            verify={verifyChangePasswordOtp}
          />
        )
      }
      <section className="flex flex-col h-screen">
        <GeneralHeader />

        <section className="bg-white-multi grow py-8 px-20">
          <div className="font-semibold text-xl mb-4">
            Edit Profile
          </div>

          <form
            onSubmit={handleSubmit(doUpdate)}
            className="mt-8 grid grid-cols-2 gap-y-5 gap-x-12 xl:gap-x-20"
          >
            <div>
              <div className="font-semibold mb-1.5">
                Email
              </div>
              <input
                type="text"
                placeholder="Enter your email address"
                className="input-primary disabled"
                value={user.email}
                disabled
              />
            </div>

            <div>
              <div className="font-semibold mb-1.5">
                Name
              </div>
              <input
                type="text"
                placeholder="Enter your full name"
                className="input-primary"
                {...register('name')}
              />
              {
                errors.name && (
                  <small className="text-red-multi italic">
                    Name cannot be empty
                  </small>
                )
              }
            </div>

            <div>
              <div className="font-semibold mb-1.5">
                Date of Birth
              </div>

              <DatePicker
                value={dob}
                onChange={setDob}
                placeholder="Select your date of birth"
                maxDate={new Date()}
                className="w-full"
              />
              {
                errors.dob && (
                  <small className="text-red-multi italic">
                    Date of Birth cannot be empty
                  </small>
                )
              }
            </div>

            {
              user?.hasPassword && (
                <button
                  type="button"
                  className="outline-none font-semibold flex items-center"
                  onClick={requestChangePassword}
                >
                  Change Password
                  <FaChevronRight className="ml-2" />
                </button>
              )
            }
            {
              !user?.hasPassword && (
                <button
                  type="button"
                  className="outline-none font-semibold flex items-center"
                  onClick={() => navigate(path.createPassword)}
                >
                  Create Password
                  <FaChevronRight className="ml-2" />
                </button>
              )
            }

            <div>
              <div className="font-semibold mb-1.5">
                Phone Number
              </div>
              <NumberInput
                name="phone"
                control={control}
                allowLeadingZeros
                placeholder="Enter your phone number"
                className="input-primary"
              />
              {
                errors.phone && (
                  <small className="text-red-multi italic">
                    Invalid phone number
                  </small>
                )
              }
            </div>

            <div className="row-span-2">
              {
                addresses.map(address => (
                  <div
                    key={address.id}
                    className="mb-3"
                  >
                    <div className="flex items-center">
                      <div className="text-black-multi font-semibold">
                        {address.name}
                      </div>
                      {
                        address.primary && (
                          <div className="ml-2 px-2.5 py-0.5 text-[11px] rounded-full bg-black-multi text-yellow-multi">
                            Primary
                          </div>
                        )
                      }
                      <button
                        type="button"
                        className="ml-auto"
                        onClick={() => navigate(path.editAddress.replace(':addressId', address.id))}
                      >
                        <HiPencilAlt />
                      </button>
                    </div>

                    <div className="text-black-multi">
                      {address.detail}
                    </div>
                  </div>
                ))
              }

              {
                addresses.length < 3 && (
                  <div className="mt-6">
                    <button
                      type="button"
                      className="flex items-center font-semibold gap-x-3"
                      onClick={() => navigate(path.address)}
                    >
                      <HiOutlinePlusSm />
                      Add Address
                    </button>
                  </div>
                )
              }
            </div>

            <div>
              <div className="font-semibold mb-1.5">
                Gender
              </div>

              <div className="flex">
                <RadioButton
                  id="MALE"
                  text="Male"
                  value="MALE"
                  name="GENDER"
                  className="mr-6"
                  {...register('gender')}
                />
                <RadioButton
                  id="FEMALE"
                  text="Female"
                  value="FEMALE"
                  name="GENDER"
                  {...register('gender')}
                />
              </div>

              {
                errors.gender && (
                  <small className="text-red-multi italic">
                    Gender cannot be empty
                  </small>
                )
              }
            </div>

            <div className="flex gap-x-4 mt-10 w-2/3 xl:w-1/2">
              <button
                type="submit"
                className="button-primary"
              >
                Update Profile
              </button>
              <button
                type="button"
                className="button-primary dark"
                onClick={() => navigate(path.profile)}
              >
                Cancel
              </button>
            </div>
          </form>
        </section>

        <Footer />
      </section>
    </>
  )
}

export default EditProfilePage