import React from 'react'
import ReactDOM from 'react-dom/client'
import store from '@/store'
import App from '@/App'
import { Provider } from 'react-redux'

import './index.scss'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import '../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <Provider store={store}>
    <App />
  </Provider>
)
