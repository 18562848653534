import React from 'react'
import { toFormattedDate, numberFormatter } from '@/utils/common'
import config from '@/constant/config'
import { useNavigate } from 'react-router-dom'

const { path } = config

import OrderStatusLabel from '@/components/OrderStatusLabel'

const OrderListCard = ({
  id,
  status,
  date,
  items
}) => {
  const navigate = useNavigate()

  return (
    <div
      className="border border-black-multi p-3 text-sm"
      onClick={() => navigate(path.orderDetail.replace(':id', id))}
    >
      <div className="flex items-center justify-between">
        <div className="text-sm mb-1.5 font-semibold">
          Order Id: {id}
        </div>
        <OrderStatusLabel status={status} />
      </div>

      <div className="text-gray-multi">
        {toFormattedDate(date)}
      </div>

      <div className="mt-3">
        {
          items.map((item, index) => (
            <div
              key={item.id}
              className="mb-4"
            >
              <div className="font-semibold mb-1.5">
                Order {index + 1}
              </div>
              <div
                className="flex border border-black-multi"
              >
                <img
                  src={item.product.image}
                  alt="product image"
                  className="w-24 aspect-square object-contain"
                />

                <div className="flex flex-col justify-between p-3">
                  <div
                    className="text-xs line-clamp-2"
                    title={item.product.name}
                  >
                    {item.product.name}
                  </div>

                  <div>
                    {
                      item.product.downPayment && (
                        <div className="text-xs text-gray-multi">
                          50% Down Payment
                        </div>
                      )
                    }

                    <div className="text-xs text-gray-multi">
                      {item.product.quantity}x &nbsp; {numberFormatter(item.product.price, 'Rp')}
                    </div>
                  </div>
                </div>
              </div>

              {
                item.totalProducts > 1 && (
                  <div className="text-sm text-gray-multi mt-1.5">
                    + {item.totalProducts - 1} &nbsp; more products
                  </div>
                )
              }
            </div>
          ))
        }
      </div>
    </div>
  )
}

export default OrderListCard