import React, { useEffect, useState } from 'react'
import OtpInput from 'react-otp-input'
import Countdown from 'react-countdown'
import { useDispatch } from 'react-redux'
import { getOtpDetail } from '@/store/slices/otp'
import toast from 'react-hot-toast'
import classNames from 'classnames'
import { DateTime } from 'luxon'
import useLoader from '@/hooks/loader'

import BottomSheet from '@/components/BottomSheet'

const OtpModal = ({
  visible,
  onClose,
  otpId,
  resend,
  verify
}) => {
  const dispatch = useDispatch()

  const [otp, setOtp] = useState(false)
  const [otpDetail, setOtpDetail] = useState()
  const [otpErrorMessage, setOtpErrorMessage] = useState('')
  const [isExpiredOtp, setIsExpiredOtp] = useState(false)
  const [disabled, setDisabled] = useState(false)
  const [, setIsLoading] = useLoader()

  useEffect(() => {
    if (visible) {
      fetchOtpDetail()
    }

    return () => {
      setDisabled(false)
      setIsExpiredOtp(false)
      setOtpErrorMessage('')
      setOtpDetail(undefined)
      setOtp('')
    }
  }, [visible])

  const fetchOtpDetail = async () => {
    try {
      setIsLoading(true)

      const { data } = await dispatch(getOtpDetail(otpId)).unwrap()
      setOtpDetail(data)

      data.lockedUntil && DateTime.fromMillis(data.lockedUntil).diffNow() > 0 && setDisabled(true)

      setIsLoading(false)
    } catch(error) {
      setIsLoading(false)
      toast.error('Something went wrong, please try again')
    }
  }

  const countdownRenderer = ({ minutes, seconds, completed }) => {
    if (completed || otpDetail.lockedUntil) {
      return <div />
    }
    return (
      <span>
        {('0' + minutes).slice(-2)}:{('0' + seconds).slice(-2)}
      </span>
    )
  }

  const lockedCountdownRenderer = ({ minutes, seconds, completed }) => {
    if (completed) {
      return <div />
    }
    return (
      <span>
        {('0' + minutes).slice(-2)}:{('0' + seconds).slice(-2)}
      </span>
    )
  }

  const resendOtp = async () => {
    try {
      setIsLoading(true)

      await resend(otpDetail.id)

      setDisabled(false)
      setIsLoading(false)
      setIsExpiredOtp(false)
      fetchOtpDetail()
    } catch(error) {
      setIsLoading(false)
      setOtpErrorMessage('')
      setOtp('')

      toast.error('Something went wrong, please try again')
    }
  }

  const verifyOtp = async () => {
    try {
      if (otp.length < 6) {
        setOtpErrorMessage('Please input OTP code')
        return
      }

      setIsLoading(true)

      await verify(otp, otpDetail.id)

      setIsLoading(false)
    } catch(error) {
      setIsLoading(false)
      setOtpErrorMessage('')
      setOtp('')

      if (error.data && error.data.error === 'INVALID_OTP') {
        toast.error('Invalid OTP')
        return
      }

      if (error.data && error.data.error === 'OTP_EXPIRED') {
        toast.error('Please re-send OTP code')
        return
      }

      if (error.data && (error.data.error === 'OTP_LOCKED' || error.data.error === 'MAXIMUM_ATTEMPT')) {
        setDisabled(true)
        if (!otpDetail.lockedUntil) {
          fetchOtpDetail()
        }
        return
      }

      toast.error('Something went wrong, please try again')
    }
  }

  return (
    <BottomSheet
      visible={visible}
      onClose={onClose}
    >
      <div className="flex flex-col items-center justify-center text-sm">
        <div className="font-semibold text-2xl">
          Verification
        </div>

        <div className="my-3">
          You'll get an OTP code via Email
        </div>

        {
          !!otpDetail && (
            <Countdown
              key={otpDetail.expiration}
              date={otpDetail.expiration}
              renderer={countdownRenderer}
              onComplete={() => setIsExpiredOtp(true)}
            />
          )
        }

        <div className="my-6">
          <OtpInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderSeparator={<span>&nbsp;&nbsp;</span>}
            renderInput={props => <input {...props} disabled={disabled} className="text-2xl bg-white-multi border border-black-multi text-center !w-12 h-12" />}
            containerStyle="flex justify-center w-full mb-2"
          />
          {
            otpErrorMessage && (
              <div className="text-red-multi italic">
                {otpErrorMessage}
              </div>
            )
          }
        </div>

        {
          disabled && !!otpDetail.lockedUntil && (
            <div className="flex text-red-multi">
              <div className="mr-1">
                Locked until: 
              </div>
              <Countdown
                key={otpDetail.lockedUntil}
                date={otpDetail.lockedUntil}
                renderer={lockedCountdownRenderer}
                onComplete={() => setDisabled(false)}
              />
            </div>
          )
        }

        {
          isExpiredOtp && !disabled && (
            <div className="flex">
              Didn't receive a code?
              <button
                type="button"
                className="underline ml-1"
                onClick={resendOtp}
              >
                Click here
              </button>
            </div>
          )
        }

        <button
          type="button"
          className={classNames({
            'button-primary text-base mt-8': true,
            'disabled': disabled,
          })}
          onClick={verifyOtp}
          disabled={disabled}
        >
          Verify
        </button>
      </div>
    </BottomSheet>
  )
}

export default OtpModal