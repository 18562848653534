import React, { useEffect, useState } from 'react'
import useResponsive from '@/hooks/responsive'
import { useParams, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { getOrderDetail } from '@/store/slices/order'
import { TfiHeadphoneAlt } from 'react-icons/tfi'
import { FaChevronLeft } from 'react-icons/fa'
import { HiDocumentDuplicate } from 'react-icons/hi';
import useAuthentication from '@/hooks/authentication'
import useLoader from '@/hooks/loader'
import toast from 'react-hot-toast'
import config from '@/constant/config'
import { toFormattedDate, toCapitalCase, numberFormatter } from '@/utils/common'

import GeneralHeader from '@/components/GeneralHeader'
import TitleHeader from '@/components/TitleHeader'
import Footer from '@/components/Footer'
import OrderStatusLabel from '@/components/OrderStatusLabel'
import OrderDetailItem from '@/components/OrderDetailItem'
import OrangeTicker from '@/components/OrangeTicker'
import OrderInvoice from '@/components/OrderInvoice';

const { whatsapp } = config.link
const { path } = config

const InformationParagraph = ({ title, value, className = '' }) => (
  <div className={'flex w-full gap-x-2 mb-1.5 ' + className}>
    <div className="w-1/4 text-gray-multi-3">
      {title}
    </div>
    <div
      title={value}
      className="line-clamp-2 w-3/4 text-right"
    >
      {value}
    </div>
  </div>
)

const Section = ({ className = '', title, children }) => (
  <section className={'text-sm border border-black-multi p-3 flex flex-col items-start ' + className}>
    <div className="font-semibold">
      {title}
    </div>

    <hr className="my-3 border-black-multi w-full" />

    {children}
  </section>
)

const OrderDetailPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { id: orderId } = useParams()
  const { isDesktop } = useResponsive()
  const { unauthorized } = useAuthentication()

  const [, setIsLoading] = useLoader()
  const [orderDetail, setOrderDetail] = useState()
  const [visibleOrderInvoice, setVisibleOrderInvoice] = useState(false)

  useEffect(() => {
    fetchOrderDetail()
  }, [])

  const fetchOrderDetail = async () => {
    try {
      setIsLoading(true)

      const { data } = await dispatch(getOrderDetail(orderId)).unwrap()
      setOrderDetail(data)

      setIsLoading(false)
    } catch(error) {
      unauthorized(error, () => {
        setIsLoading(false)

        if (error.data?.error === 'ORDER_NOT_FOUND') {
          toast.error('Order not found')
          navigate(path.order)
          return
        }

        toast.error('Something went wrong, please try again')
      })
    }
  }

  const addressText = address => `${toCapitalCase(address.province?.name)}, ${toCapitalCase(address.regency?.name)}, ${toCapitalCase(address.district?.name)}, ${toCapitalCase(address.village?.name)}, ${address.postalCode}`

  const whatsappLink = () => {
    const message = 'Halo Admin, saya ingin bertanya mengenai order dengan id ' + orderId
    return whatsapp + encodeURIComponent(message)
  }

  const firstPaymentInvoice = () => orderDetail.payment?.find(p => p.description === 'FIRST_PAYMENT') || {}

  const priceSummaries = () => {
    const grouped = orderDetail.prices.reduce((acc, price) => {
      return {
        ...acc,
        [price.id]: {
          ...price,
          nominal: acc[price.id] ? acc[price.id].nominal + price.nominal : price.nominal,
          id: price.id
        }
      }
    }, {})

    return Object.values(grouped)
  }

  const visibleInvoiceButton = () => orderDetail.status !== 'PENDING'

  return orderDetail && (
    <>
      {
        visibleOrderInvoice && (
          <OrderInvoice
            orderDetail={orderDetail}
            visible={visibleOrderInvoice}
            onClose={() => setVisibleOrderInvoice(false)}
          />
        )
      }

      <div className="flex flex-col min-h-screen">
        {
          isDesktop ? (
            <GeneralHeader />
          ) : (
            <TitleHeader
              title="Order Detail"
              backUrl={path.order}
              slot={() => (
                <a
                  target="_blank"
                  href={whatsappLink()}
                  className="ml-auto mr-2"
                >
                  <TfiHeadphoneAlt className="text-xl" />
                </a>
              )}
            />
          )
        }

        <section className="bg-white-multi grow p-5 relative lg:py-8 flex justify-center">
          <section className="flex w-full lg:gap-x-8 lg:flex-row lg:w-[90%] 2xl:w-[70%] lg:items-start">
            {
              isDesktop && (
                <div className="flex items-center font-bold gap-x-3 w-1/3">
                  <button
                    type="button"
                    onClick={() => navigate(path.order)}
                  >
                    <FaChevronLeft className="text-xl" />
                  </button>
                  <div className="text-3xl">
                    Order Detail
                  </div>

                  <a
                    target="_blank"
                    href={whatsappLink()}
                    className="ml-auto mt-1"
                  >
                    <TfiHeadphoneAlt className="text-xl" />
                  </a>
                </div>
              )
            }

            <section className="flex flex-col gap-y-5 w-full lg:w-2/3">
              {
                orderDetail.status === 'PENDING' && firstPaymentInvoice().status === 'PENDING' && (
                  <OrangeTicker>
                    Please complete your payment
                    <a
                      href={firstPaymentInvoice().invoiceUrl}
                      className="ml-1 text-blue-400"
                    >
                      here
                    </a>
                  </OrangeTicker>
                )
              }

              <section className="flex lg:gap-x-3">
                <Section
                  title="Information"
                  className="w-full lg:w-1/2"
                >
                  <div className="mb-2">
                    <OrderStatusLabel status={orderDetail.status} />
                  </div>
                  <InformationParagraph
                    title="Order Id"
                    value={orderDetail.id}
                  />
                  <InformationParagraph
                    title="Order Date"
                    value={toFormattedDate(orderDetail.date)}
                    className="whitespace-nowrap"
                  />
                  <InformationParagraph
                    title="Notes"
                    value={orderDetail.notes || '-'}
                  />

                  {
                    visibleInvoiceButton() && (
                      <div className="self-end flex items-center">
                        <HiDocumentDuplicate className="text-blue-400" />
                        <button
                          type="button"
                          className="text-blue-400 ml-1"
                          onClick={() => setVisibleOrderInvoice(true)}
                        >
                          View Invoice
                        </button>
                      </div>
                    )
                  }
                </Section>

                {
                  isDesktop && (
                    <Section title="Shipping Address" className="w-1/2">
                      <div className="font-semibold">
                        {orderDetail.address?.name}
                      </div>
                      <div>
                        {orderDetail.address?.recipient?.name} - {orderDetail.address?.recipient?.phone}
                      </div>
                      <div className="mt-1.5">
                        {orderDetail.address?.detail}
                      </div>
                      <div>
                        {addressText(orderDetail.address)}
                      </div>
                      {
                        orderDetail.address?.notes && (
                          <div className="italic mt-1.5">
                            {orderDetail.address?.notes}
                          </div>
                        )
                      }
                    </Section>
                  )
                }
              </section>

              {/* Order Items */}
              <section className="text-sm lg:text-sm">
                <div className="font-semibold text-lg">
                  Items
                </div>

                <hr className="my-3 border-black-multi w-full" />

                {
                  orderDetail.items?.map((item, index) => (
                    <OrderDetailItem
                      key={item.id}
                      orderId={orderDetail.id}
                      id={item.id}
                      index={index}
                      status={item.status}
                      products={item.products}
                      shipping={item.shipping}
                      subtotal={item.subtotal}
                      invoices={orderDetail.payment}
                      visibleAdjustmentTicker={item.visibleAdjustmentTicker}
                      visiblePendingAdjustmentTicker={item.visiblePendingAdjustmentTicker}
                    />
                  ))
                }
              </section>

              {
                !isDesktop && (
                  <Section title="Shipping Address">
                    <div className="font-semibold">
                      {orderDetail.address?.name}
                    </div>
                    <div>
                      {orderDetail.address?.recipient?.name} - {orderDetail.address?.recipient?.phone}
                    </div>
                    <div className="mt-1.5">
                      {orderDetail.address?.detail}
                    </div>
                    <div>
                      {addressText(orderDetail.address)}
                    </div>
                    {
                      orderDetail.address?.notes && (
                        <div className="italic mt-1.5">
                          {orderDetail.address?.notes}
                        </div>
                      )
                    }
                  </Section>
                )
              }

              <Section title="Payment Summary">
                {
                  priceSummaries().map(price => (
                    <InformationParagraph
                      key={price.id}
                      title={price.text}
                      value={numberFormatter(price.nominal, 'Rp')}
                    />
                  ))
                }
                <InformationParagraph
                  className="font-semibold"
                  title="Total price"
                  value={numberFormatter(orderDetail.totalPrice, 'Rp')}
                />
              </Section>
            </section>
          </section>
        </section>

        {isDesktop && <Footer />}
      </div>
    </>
  )
}

export default OrderDetailPage