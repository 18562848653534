import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import axios from '@/axios'
import config from '@/constant/config'

const { api } = config

export const getProvinces = createAsyncThunk(
  'region/getProvinces',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(api.region.provinces())
      return data
    } catch(error) {
      return rejectWithValue(error)
    }
  }
)

export const getProvince = createAsyncThunk(
  'region/getProvince',
  async (provinceId, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(api.region.province(provinceId))
      return data
    } catch(error) {
      return rejectWithValue(error)
    }
  }
)

export const getRegencies = createAsyncThunk(
  'region/getRegencies',
  async (provinceId, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(api.region.regencies(provinceId))
      return data
    } catch(error) {
      return rejectWithValue(error)
    }
  }
)

export const getRegency = createAsyncThunk(
  'region/getRegency',
  async (regencyId, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(api.region.regency(regencyId))
      return data
    } catch(error) {
      return rejectWithValue(error)
    }
  }
)

export const getDistricts = createAsyncThunk(
  'region/getDistricts',
  async (regencyId, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(api.region.districts(regencyId))
      return data
    } catch(error) {
      return rejectWithValue(error)
    }
  }
)

export const getDistrict = createAsyncThunk(
  'region/getDistrict',
  async (districtId, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(api.region.district(districtId))
      return data
    } catch(error) {
      return rejectWithValue(error)
    }
  }
)

export const getVillages = createAsyncThunk(
  'region/getVillages',
  async (districtId, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(api.region.villages(districtId))
      return data
    } catch(error) {
      return rejectWithValue(error)
    }
  }
)

export const getVillage = createAsyncThunk(
  'region/getVillage',
  async (villageId, { rejectWithValue }) => {
    try {
      const { data } = await axios.get(api.region.village(villageId))
      return data
    } catch(error) {
      return rejectWithValue(error)
    }
  }
)

export const regionSlice = createSlice({
  name: 'region',
  initialState: {},
  reducers: {}
})

export default regionSlice.reducer