import React from 'react'
import { useNavigate, createSearchParams } from 'react-router-dom'
import useResponsive from '@/hooks/responsive'
import { toCapitalCase } from '@/utils/common'
import config from '@/constant/config'

import TitleHeader from '@/components/TitleHeader'
import RadioButton from '@/components/RadioButton'
import BottomSheet from '@/components/BottomSheet'

const { path } = config

const CheckoutAddressModal = ({ onClose, addresses, selectedAddress, register, visible, onAddressChange }) => {
  const navigate = useNavigate()

  const { isDesktop } = useResponsive()

  const addressText = address => `${toCapitalCase(address.province?.name)}, ${toCapitalCase(address.regency?.name)}, ${toCapitalCase(address.district?.name)}, ${toCapitalCase(address.village?.name)}, ${address.postalCode}`

  const getAddressLabel = address => (
    <label
      className="ml-1"
      htmlFor={address.id}
    >
      <div className="flex items-center">
        <div className="font-semibold">
          {address.name}
        </div>

        {
          address.primary && (
            <div>
              <div className="ml-2 px-2.5 py-0.5 text-[10px] rounded-full bg-black-multi text-yellow-multi">
                Primary
              </div>
            </div>
          )
        }
      </div>

      <div className="text-sm">
        <div>
          {address.detail}
        </div>
        <div>
          {addressText(address)}
        </div>
      </div>

      <hr className="border-gray-multi-4 mt-4" />
    </label>
  )

  if (isDesktop) {
    return (
      <BottomSheet
        visible={visible}
        onClose={onClose}
      >
        <div className="p-4">
          <div className="text-xl font-semibold text-center">
            Choose Address
          </div>

          <section className="my-10">
            {
              addresses.map(address => (
                <div
                  key={address.id}
                  className="mb-4 flex items-start"
                >
                    <RadioButton
                      id={address.id}
                      value={address.id}
                      className="!items-start"
                      radioClassName="mt-1 mr-1"
                      name="ADDRESS"
                      {...register('addressId', {
                        onChange: () => {
                          onClose()
                          onAddressChange()
                        }
                      })}
                      checked={address.id === selectedAddress}
                      label={() => getAddressLabel(address)}
                    />
                </div>
              ))
            }
          </section>

          {
            addresses.length < 3 && (
              <button
                type="button"
                className="mt-auto button-primary"
                onClick={() => navigate({
                  pathname: path.address,
                  search: createSearchParams({
                    page: window.btoa(path.checkout)
                  }).toString()
                })}
              >
                Add New Address
              </button>
            )
          }
        </div>
      </BottomSheet>
    )
  }

  return (
    <div className="fixed top-0 left-0 z-30 flex flex-col min-h-screen">
      <TitleHeader
        title="Choose Address"
        onBackClick={onClose}
      />

      <section className="bg-white-multi grow p-5 relative flex flex-col">
        {
          addresses.map(address => (
            <div
              key={address.id}
              className="mb-4 flex items-start"
            >
                <RadioButton
                  id={address.id}
                  value={address.id}
                  className="!items-start"
                  radioClassName="mt-1 mr-1"
                  name="ADDRESS"
                  {...register('addressId', {
                    onChange: onClose
                  })}
                  checked={address.id === selectedAddress}
                  label={() => getAddressLabel(address)}
                />
            </div>
          ))
        }

        {
          addresses.length < 3 && (
            <button
              type="button"
              className="mt-auto button-primary"
              onClick={() => navigate({
                pathname: path.address,
                search: createSearchParams({
                  page: window.btoa(path.checkout)
                }).toString()
              })}
            >
              Add New Address
            </button>
          )
        }
      </section>
    </div>
  )
}

export default CheckoutAddressModal