import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import config from '@/constant/config'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { userSelector, createPassword, loginInfo, setUser, setIsGettingUser } from '@/store/slices/user'
import toast from 'react-hot-toast'
import useLoader from '@/hooks/loader'
import useAuthentication from '@/hooks/authentication'
import useResponsive from '@/hooks/responsive'

import GeneralHeader from '@/components/GeneralHeader'
import TitleHeader from '@/components/TitleHeader'
import Footer from '@/components/Footer'

const { path } = config

const validationSchema = yup.object().shape({
  password: yup.string()
    .min(8)
    .matches(/[A-Z]/)
    .required(),
  confirmPassword: yup.string()
    .test('validPassword', 'Password is different', function(confirmPassword) {
      return confirmPassword === this.parent.password
    })
    .required('Confirm password must not empty')
})

const CreatePasswordPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { isDesktop } = useResponsive()

  const { unauthorized } = useAuthentication()
  const { user } = useSelector(userSelector)

  const [, setIsLoading] = useLoader()

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(validationSchema)
  })

  useEffect(() => {
    if (user?.hasPassword) {
      navigate(path.base)
    }
  }, [])

  const getLoginInfo = async () => {
    try {
      const response = await dispatch(loginInfo()).unwrap()
      dispatch(setIsGettingUser(false))
      dispatch(setUser(response.data))
    } catch(err) {
      dispatch(setIsGettingUser(false))
    }
  }

  const doCreatePassword = async form => {
    try {
      setIsLoading(true)

      await dispatch(createPassword({
        email: user?.email,
        password: form.password
      })).unwrap()
      await getLoginInfo()

      setIsLoading(false)
      toast.success('Your password has been successfully created!')
      navigate(path.profile)
    } catch(error) {
      unauthorized(error, () => {
        setIsLoading(false)
        toast.error('Something went wrong, please try again')
      })
    }
  }

  return (
    <div className="flex flex-col h-screen">
      {
        isDesktop ? (
          <GeneralHeader />
        ) : (
          <TitleHeader
            title="Create Password"
            backUrl={path.editProfile}
          />
        )
      }

      <section className="bg-white-multi grow">
        <form
          onSubmit={handleSubmit(doCreatePassword)}
          className="p-5 flex flex-col h-full lg:py-8 lg:px-20"
        >
          {
            isDesktop && (
              <div className="lg:text-2xl lg:font-semibold lg:my-8">
                Create Password
              </div>
            )
          }

          <div className="mb-4 lg:w-1/2">
            <div className="font-semibold mb-1.5">
              Password
            </div>
            <input
              type="password"
              placeholder="Enter your password"
              className="input-primary"
              {...register('password')}
            />
            {
              errors.password && (
                <small className="text-red-multi italic">
                  Password must contain minimal 8 characters and 1 uppercase letter
                </small>
              )
            }
          </div>

          <div className="lg:w-1/2">
            <div className="font-semibold mb-1.5">
              Confirm Password
            </div>
            <input
              type="password"
              placeholder="Confirm your password"
              className="input-primary"
              {...register('confirmPassword')}
            />
            {
              errors.confirmPassword && (
                <small className="text-red-multi italic">
                  Password must match the password you've entered
                </small>
              )
            }
          </div>

          <div className="mt-auto w-full lg:w-[unset] lg:my-8 lg:self-start">
            <button
              type="submit"
              className="button-primary px-8"
            >
              Create Password
            </button>
          </div>
        </form>
      </section>

      {
        isDesktop && <Footer />
      }
    </div>
  )
}

export default CreatePasswordPage