import { configureStore } from '@reduxjs/toolkit'

import userReducer from './slices/user'
import otpReducer from './slices/otp'
import oneTimeLinkReducer from './slices/oneTimeLink'
import bannerReducer from './slices/banner'
import productReducer from './slices/product'
import addressReducer from './slices/address'
import regionReducer from './slices/region'
import categoryReducer from './slices/category'
import brandReducer from './slices/brand'
import seriesReducer from './slices/series'
import characterReducer from './slices/character'
import wishlistReducer from './slices/wishlist'
import loaderReducer from './slices/loader'
import cartReducer from './slices/cart'
import checkoutReducer from './slices/checkout'
import responsiveReducer from './slices/responsive'
import orderReducer from './slices/order'
import configReducer from './slices/config'
import newsletterReducer from './slices/newsletter'
import featuredProductReducer from './slices/featured-product'
import storeLocationReducer from './slices/store-location'
import customLayoutReducer from './slices/custom-layout'

const store = configureStore({
  reducer: {
    user: userReducer,
    otp: otpReducer,
    oneTimeLink: oneTimeLinkReducer,
    banner: bannerReducer,
    product: productReducer,
    address: addressReducer,
    region: regionReducer,
    category: categoryReducer,
    brand: brandReducer,
    series: seriesReducer,
    character: characterReducer,
    wishlist: wishlistReducer,
    loader: loaderReducer,
    cart: cartReducer,
    checkout: checkoutReducer,
    responsive: responsiveReducer,
    order: orderReducer,
    config: configReducer,
    newsletter: newsletterReducer,
    featuredProduct: featuredProductReducer,
    storeLocation: storeLocationReducer,
    customLayout: customLayoutReducer
  }
})

export default store
