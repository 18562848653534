import resolveConfig from 'tailwindcss/resolveConfig'
import tailwindConfig from '../tailwind'

const config = resolveConfig(tailwindConfig)

const parse = screen => +screen.slice(0, screen.length - 2)

const { theme: { screens } } = config

const SMALL_BREAKPOINT = parse(screens.sm)
const MEDIUM_BREAKPOINT = parse(screens.md)
const LARGE_BREAKPOINT = parse(screens.lg)
const XTRA_LARGE_BREAKPOINT = parse(screens.xl)
const XTRA2_LARGE_BREAKPOINT = parse(screens['2xl'])

export const isMobile = () => {
  const { clientWidth } = window.document.documentElement
  return clientWidth < SMALL_BREAKPOINT
}

export const isTablet = () => {
  const { clientWidth } = window.document.documentElement
  return clientWidth >= SMALL_BREAKPOINT && clientWidth < LARGE_BREAKPOINT
}

export const isDesktop = () => {
  const { clientWidth } = window.document.documentElement
  return clientWidth >= LARGE_BREAKPOINT
}

export const isLargeDesktop = () => {
  const { clientWidth } = window.document.documentElement
  return clientWidth >= XTRA_LARGE_BREAKPOINT
}
