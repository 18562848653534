import React from 'react'
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai'
import { ensureTwoDigits } from '@/utils/common'
import classNames from 'classnames'

const ProductQuantityCounter = ({
  maxQuantityPerOrder,
  stock,
  quantityState,
  disabled,
  onAddQuantity,
  onSubtractQuantity
}) => {

  const [qty, setQty] = quantityState

  const subtractQty = async () => {
    if (qty <= 1) {
      return
    }

    onSubtractQuantity && await onSubtractQuantity()

    setQty(prev => prev - 1)
  }

  const isMaximumQuantityPerOrder = () => maxQuantityPerOrder && qty >= maxQuantityPerOrder

  const isMaximumStock = () => stock && qty >= stock

  const addQty = async () => {
    if (isMaximumQuantityPerOrder()) {
      return
    }
    if (isMaximumStock()) {
      return
    }

    onAddQuantity && await onAddQuantity()

    setQty(prev => prev + 1)
  }

  return (
    <div className="flex items-center">
      <button
        type="button"
        className={classNames({
          'flex justify-center items-center text-xs rounded-full p-1.5': true,
          'bg-yellow-multi': !disabled && qty > 1,
          'bg-gray-multi-2': disabled || isMaximumQuantityPerOrder() || isMaximumStock() || qty <= 1
        })}
        onClick={subtractQty}
        disabled={disabled}
      >
        <AiOutlineMinus />
      </button>
      <div className="text-center text-sm mx-3">
        {ensureTwoDigits(qty)}
      </div>
      <button
        type="button"
        className={classNames({
          'flex justify-center items-center text-xs rounded-full p-1.5': true,
          'bg-yellow-multi': !disabled && !isMaximumQuantityPerOrder() && !isMaximumStock(),
          'bg-gray-multi-2': disabled || isMaximumQuantityPerOrder() || isMaximumStock()
        })}
        onClick={addQty}
        disabled={disabled}
      >
        <AiOutlinePlus />
      </button>
    </div>
  )
}

export default ProductQuantityCounter