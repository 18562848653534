import { createSlice } from '@reduxjs/toolkit'

export const responsiveSlice = createSlice({
  name: 'responsive',
  initialState: {
    isMobile: false,
    isTablet: false,
    isDesktop: false,
    isLargeDesktop: false
  },
  reducers: {
    setIsMobile(state, actions) {
      state.isMobile = actions.payload
    },
    setIsDesktop(state, actions) {
      state.isDesktop = actions.payload
    },
    setIsTablet(state, actions) {
      state.isTablet = actions.payload
    },
    setIsLargeDesktop(state, actions) {
      state.isLargeDesktop = actions.payload
    }
  }
})

export const isMobileSelector = state => state.responsive.isMobile
export const isDesktopSelector = state => state.responsive.isDesktop
export const isTabletSelector = state => state.responsive.isTablet

export const { setIsMobile, setIsDesktop, setIsTablet, setIsLargeDesktop } = responsiveSlice.actions

export default responsiveSlice.reducer