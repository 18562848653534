import React from 'react'
import { motion, AnimatePresence } from 'framer-motion'
import ScrollLock from '@/components/ScrollLock'
import useResponsive from '@/hooks/responsive'
import classNames from 'classnames'

const SideNavigation = ({ visible, direction = -1, onClose, children, ...props }) => {
  const { isDesktop } = useResponsive()

  return (
    <>
      <AnimatePresence mode='wait'>
        {
          visible && (
            <>
              {
                isDesktop && (
                  <div
                    className="fixed top-0 left-0 h-screen w-screen z-40 bg-opacity-50 bg-black-multi"
                    onClick={onClose}
                  />
                )
              }

              <motion.nav
                {...props}
                className={classNames({
                  'fixed top-0 h-screen w-screen overflow-y-scroll bg-black-multi p-5 flex flex-col z-50 lg:w-[50vw] scrollbar-none': true,
                  'right-0': direction === 1,
                  'left-0': direction === -1
                })}
                animate="open"
                initial="closed"
                exit="closed"
                variants={{
                  open: {
                    x: '0%',
                    transition: {
                      duration: 0.2,
                      ease: 'easeOut'
                    }
                  },
                  closed: {
                    x: direction === -1 ? '-100%' : '100%',
                    transition: {
                      duration: 0.2,
                      ease: 'easeOut'
                    }
                  }
                }}
              >
                {children}
              </motion.nav>

              <ScrollLock isLocked={visible} />
            </>
          )
        }
      </AnimatePresence>
    </>
  )
}

export default SideNavigation