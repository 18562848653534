import React, { useEffect, useState } from 'react'
import { FaChevronLeft } from 'react-icons/fa'
import { PiHeart, PiHeartFill } from 'react-icons/pi'
import { BiInfoCircle } from 'react-icons/bi'
import { MdSearchOff } from 'react-icons/md'
import { useNavigate, useParams, createSearchParams } from 'react-router-dom'
import { getProduct } from '@/store/slices/product'
import { saveWishlist, checkWishlist, deleteWishlist } from '@/store/slices/wishlist'
import { addToCart, countCartItems } from '@/store/slices/cart'
import { useDispatch } from 'react-redux'
import { DateTime } from 'luxon'
import { getQuarter, numberFormatter } from '@/utils/common'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Pagination } from 'swiper/modules'
import toast from 'react-hot-toast'
import config from '@/constant/config'
import classNames from 'classnames'
import { motion, AnimatePresence } from 'framer-motion'
import useLoader from '@/hooks/loader'
import useResponsive from '@/hooks/responsive'
import useAuthentication from '@/hooks/authentication'

import BaseHeader from '@/components/BaseHeader'
import GeneralHeader from '@/components/GeneralHeader'
import Ticker from '@/components/Ticker'
import Footer from '@/components/Footer'
import ProductQuantityCounter from '@/components/ProductQuantityCounter'

const { path } = config
const { availabilities } = config.product

const AVAILABILITY_STYLES = {
  WAITING_LIST: {
    text: 'Waiting List',
    background: 'bg-blue-multi'
  },
  READY_STOCK: {
    text: 'Ready Stock',
    background: 'bg-green-multi'
  },
  PREORDER: {
    text: 'Pre-order',
    background: 'bg-orange-multi'
  },
  LATE_PREORDER: {
    text: 'Late Pre-order',
    background: 'bg-red-multi'
  }
}

const Line = ({ className }) => <div className={'w-full border-t-[1px] border-gray-multi my-3 ' + className} />

const ProductDetailPage = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { sku } = useParams()

  const { isDesktop } = useResponsive()
  const { unauthorized } = useAuthentication()

  const [isFetchingProduct, setIsFetchingProduct] = useState(false)
  const [, setIsLoading] = useLoader()
  const [product, setProduct] = useState()
  const [qty, setQty] = useState(1)
  const [isWishlisted, setIsWishlisted] = useState(false)
  const [selectedDesktopImage, setSelectedDesktopImage] = useState('')

  useEffect(() => {
    if (!sku) {
      navigate(path.base)
    }

    doCheckWishlist()
    fetchProduct()
  }, [])

  const doCheckWishlist = async () => {
    try {
      const { data } = await dispatch(checkWishlist(sku)).unwrap()
      setIsWishlisted(data)
    } catch(error) {
    }
  }

  const addToWishlist = async () => {
    try {
      setIsLoading(true)
      await dispatch(saveWishlist(sku)).unwrap()
      setIsLoading(false)

      setIsWishlisted(true)
      toast.success('Product added to wishlist')
    } catch(error) {
      unauthorized(error, () => {
        setIsLoading(false)
        toast.error('Something went wrong, please try again')
      })
    }
  }

  const removeFromWishlist = async () => {
    try {
      setIsLoading(true)
      await dispatch(deleteWishlist([sku])).unwrap()
      setIsLoading(false)

      setIsWishlisted(false)
      toast.success('Product removed from wishlist')
    } catch(error) {
      unauthorized(error, () => {
        setIsLoading(false)
        toast.error('Something went wrong, please try again')
      })
    }
  }

  const onWishlistClick = async () => {
    if (isWishlisted) {
      await removeFromWishlist()
    } else {
      await addToWishlist()
    }
  }

  const fetchProduct = async () => {
    try {
      setIsFetchingProduct(true)

      const { data } = await dispatch(getProduct(sku)).unwrap()
      setProduct(data)
      isDesktop && data.images?.length && setSelectedDesktopImage(data.images[0])

      setIsFetchingProduct(false)
    } catch(error) {
      setIsFetchingProduct(false)
      if (error.data.error === 'PRODUCT_NOT_EXIST') {
        setProduct(null)
        return
      }
      toast.error('Something went wrong, please try again')
    }
  }

  const isOutOfStock = () => product && product.stock !== undefined && product.stock <= 0

  const isHidden = () => product && product.hidden

  const getReleaseDate = () => {
    const date = DateTime.fromMillis(product.estimatedArrivalDate)
    const { month, year } = date

    const [quarter, monthRange] = getQuarter(month)

    return `Est. arrival ${quarter} ${year} (${monthRange})`
  }

  const getTickerText = () => {
    const availability = availabilities.find(av => product.availability === av.key)
    const endDateString = DateTime.fromMillis(product.endDate)
      .toFormat('dd MMMM yyyy')
    return `${availability.value} ends at ${endDateString}`
  }

  const doAddToCart = async () => {
    try {
      setIsLoading(true)

      await dispatch(addToCart({
        items: [
          {
            sku: product.sku,
            quantity: qty
          }
        ]
      })).unwrap()
      toast.success('Product added to cart')

      setIsLoading(false)

      await dispatch(countCartItems()).unwrap()
    } catch(error) {
      unauthorized(error, () => {
        setIsLoading(false)
        console.log(error)
        toast.error('Something went wrong, please try again')
      })
    }
  }

  const toCheckoutPage = () => {
    navigate({
      pathname: path.checkout,
      search: createSearchParams({
        sku: product.sku,
        quantity: qty
      }).toString()
    })
  }

  const getProductStatus = () => {
    if (isOutOfStock()) {
      return (
        <div className="text-white-primary py-1.5 px-2 font-semibold text-[0.65rem] bg-gray-multi">
          Out of Stock
        </div>
      )
    }

    if (isHidden()) {
      return (
        <div className="text-white-primary py-1.5 px-2 font-semibold text-[0.65rem] bg-gray-multi">
          Product Unavailable
        </div>
      )
    }

    return (
      <div className={`text-white-primary p-2 font-semibold text-xs ${AVAILABILITY_STYLES[product.availability].background}`}>
        {AVAILABILITY_STYLES[product.availability].text}
      </div>
    )
  }

  const wishlistButton = () => (
    <button
      type="button"
      className="button-secondary flex items-center justify-center relative"
      onClick={onWishlistClick}
    >
      <AnimatePresence mode="wait">
        {
          !isWishlisted && (
            <motion.div
              animate={{
                scale: 1,
                translateY: '-50%'
              }}
              initial={{
                scale: 0,
                translateY: '-50%'
              }}
              exit={{
                scale: 0,
                translateY: '-50%'
              }}
              className="absolute top-1/2 left-0 ml-2"
              transition={{
                delay: 0.2,
                duration: 0.2
              }}
            >
              <PiHeart className="text-lg" />
            </motion.div>
          )
        }
      </AnimatePresence>
      <AnimatePresence mode="wait">
        {
          isWishlisted && (
            <motion.div
              animate={{
                scale: 1,
                translateY: '-50%'
              }}
              initial={{
                scale: 0,
                translateY: '-50%'
              }}
              exit={{
                scale: 0,
                translateY: '-50%'
              }}
              transition={{
                duration: 0.2
              }}
              className="absolute top-1/2 left-0 ml-2"
            >
              <PiHeartFill className="text-lg text-red-multi" />
            </motion.div>
          )
        }
      </AnimatePresence>
      <div className="ml-1 text-xs">
        Add to Wishlist
      </div>
    </button>
  )

  const mobileContent = () => (
    <section className="relative bg-white-multi grow flex flex-col">
      {
        isFetchingProduct && !product && (
          <div>
            <div className="w-full aspect-square bg-gray-multi-2 animate-pulse" />

            <div className="mx-2.5 mt-5 animate-pulse">
              <div className="w-3/4 h-5 bg-gray-multi-2 mb-2" />
              <div className="w-3/4 h-5 bg-gray-multi-2" />
            </div>

            <div className="mt-5 p-2.5 w-full h-24 bg-black-multi">
              <div className="w-full h-5 bg-gray-multi-2 mt-3 animate-pulse" />
            </div>
          </div>
        )
      }
      {
        !isFetchingProduct && !product && (
          <div className="text-gray-multi w-full flex flex-col items-center grow justify-center">
            <MdSearchOff className="text-[8rem]" />
            <div className="text-sm">
              Product does not exist
            </div>
          </div>
        )
      }
      {
        !isFetchingProduct && !!product && (
          <>
            {
              product.endDate && (
                <Ticker>
                  <div className="text-[11px] text-gray-multi font-semibold">
                    {getTickerText()}
                  </div>
                </Ticker>
              )
            }

            <div className="relative">
              <Swiper
                slidesPerView={1}
                centeredSlides
                initialSlide={0}
                modules={[Pagination]}
                pagination={{
                  el: '.banner-pagination',
                  clickable: true,
                }}
                rewind
              >
                {
                  product.images.map((image, i) => (
                    <SwiperSlide key={i}>
                      <img
                        src={image}
                        alt="product image"
                        loading="lazy"
                        className="object-contain w-full aspect-square"
                      />
                      {
                        (isOutOfStock() || isHidden()) && (
                          <div className="absolute inset-0 bg-white-primary opacity-50" />
                        )
                      }
                    </SwiperSlide>
                  ))
                }
              </Swiper>
            </div>

            {getProductStatus()}

            {
              product.images && product.images.length > 1 && (
                <div className="banner-pagination" />
              )
            }

            <section className="p-2.5 mt-3">
              <div className="font-semibold text-lg">
                {product.displayName}
              </div>

              {
                product.estimatedArrivalDate && (
                  <div className="text-xs text-gray-multi mt-2">
                    {getReleaseDate()}
                  </div>
                )
              }

              <div className="!w-36 mt-4">
                {wishlistButton()}
              </div>
            </section>

            <section className="p-2.5 mb-2">
              <div className="text-black-primary font-semibold text-base">
                About Product
              </div>

              <div
                dangerouslySetInnerHTML={{ __html: product.description }}
                className="!text-black-primary text-sm leading-loose"
              />
            </section>

            <section className="bg-black-multi px-5 py-5">
              <section className="grid grid-cols-3 justify-between text-white-primary text-sm">
                <div>
                  JAN Code
                </div>
                <div className="col-span-2">
                  {product.janCode}
                </div>

                <Line className="col-span-3" />
                <div>
                  Brand
                </div>
                <div className="col-span-2">
                  {product.brand?.name}
                </div>

                <Line className="col-span-3" />
                <div>
                  Category
                </div>
                <div className="col-span-2">
                  {product.category?.name}
                </div>

                <Line className="col-span-3" />
                <div>
                  Series
                </div>
                <div className="col-span-2">
                  {product.series?.name}
                </div>

                <Line className="col-span-3" />
                <div>
                  Character
                </div>
                <div className="col-span-2">
                  {product.character?.name}
                </div>
              </section>
            </section>

            <section className="sticky bottom-0 left-0 bg-white-multi px-4 py-3 grid grid-cols-2 gap-y-4 gap-x-2 items-center">
              <div className="col-span-2 flex items-center justify-between">
                {
                  isOutOfStock() && (
                    <div className="text-red-multi font-semibold text-xl">
                      Out of stock
                    </div>
                  )
                }
                {
                  !isOutOfStock() && !isHidden() && (
                    <div>
                      <div className="text-red-multi font-semibold text-xl">
                        {numberFormatter(product.price.offer, 'Rp')}
                      </div>
                      {
                        product.price.original > product.price.offer && (
                          <div className="text-gray-multi-3 text-sm line-through">
                            {numberFormatter(product.price.original, 'Rp')}
                          </div>
                        )
                      }
                    </div>
                  )
                }

                {
                  !isOutOfStock() && !isHidden() && (
                    <div className="flex items-center">
                      <ProductQuantityCounter
                        quantityState={[qty, setQty]}
                        maxQuantityPerOrder={product.maxQuantityPerOrder}
                        stock={product.stock}
                      />
                    </div>
                  )
                }
              </div>

              <button
                type="button"
                className={classNames({
                  'button-primary': true,
                  'disabled': isOutOfStock() || isHidden()
                })}
                disabled={isOutOfStock() || isHidden()}
                onClick={toCheckoutPage}
              >
                Buy Now
              </button>

              <button
                type="button"
                className={classNames({
                  'button-primary dark': true,
                  'disabled': isOutOfStock() || isHidden()
                })}
                disabled={isOutOfStock() || isHidden()}
                onClick={doAddToCart}
              >
                Add to Cart
              </button>
            </section>
          </>
        )
      }
    </section>
  )

  const desktopContent = () => (
    <section className="relative bg-white-multi grow">
      <section className="w-11/12 xl:w-10/12 mx-auto my-12">
        {
          isFetchingProduct && !product && (
            <div className="flex gap-x-6">
              <div className="w-[30%]">
                <div className="w-full h-full animate-pulse bg-gray-multi-2 ml-auto" />
              </div>

              <div className="w-[45%] animate-pulse">
                <div className="w-1/4 h-6 bg-gray-multi-2 mb-4" />
                <div className="w-3/4 h-6 bg-gray-multi-2" />

                <div className="w-1/2 h-6 bg-gray-multi-2 my-12" />

                <div className="w-full h-6 bg-gray-multi-2 mb-2" />
                <div className="w-full h-6 bg-gray-multi-2 mb-2" />
                <div className="w-full h-6 bg-gray-multi-2" />

                <div className="w-full h-6 bg-gray-multi-2 mt-12 mb-2" />
                <div className="w-full h-6 bg-gray-multi-2 mb-2" />
                <div className="w-full h-6 bg-gray-multi-2" />
              </div>

              <div className="w-[25%]">
                <div className="w-full h-6 bg-gray-multi-2 mt-3 animate-pulse" />
                <div className="w-1/2 h-6 bg-gray-multi-2 my-12 animate-pulse" />
                <div className="w-full h-6 bg-gray-multi-2 mb-3 animate-pulse" />
                <div className="w-full h-6 bg-gray-multi-2 mb-3 animate-pulse" />
                <div className="w-full h-6 bg-gray-multi-2 mb-3 animate-pulse" />
              </div>
            </div>
          )
        }
        {
          !isFetchingProduct && !product && (
            <div className="text-gray-multi w-full flex flex-col items-center grow justify-center">
              <MdSearchOff className="text-[8rem]" />
              <div className="text-base">
                Product does not exist
              </div>
            </div>
          )
        }
        {
          !isFetchingProduct && !!product && (
            <>
              <div className="flex gap-x-6 items-start relative">
                <div className="sticky top-24 w-[30%] flex flex-col">
                  <img
                    src={selectedDesktopImage}
                    alt=""
                    className="w-full object-contain aspect-square"
                  />

                  <div className="flex gap-x-2 mt-4 overflow-x-scroll scrollbar-thin scrollbar-thumb-gray-multi scrollbar-track-white-multi">
                    {
                      product.images?.map((image, i) => (
                        <img
                          key={i}
                          src={image}
                          alt="product-image"
                          className="w-16 object-contain aspect-square border border-gray-multi cursor-pointer"
                          onClick={() => setSelectedDesktopImage(image)}
                        />
                      ))
                    }
                  </div>
                </div>

                <div className="w-[45%] flex flex-col">
                  <div className="self-start">
                    {getProductStatus()}
                  </div>

                  <div className="font-semibold text-xl xl:text-2xl mt-3 mb-5">
                    {product.displayName}
                  </div>

                  <div className="text-red-multi font-bold text-xl">
                    {numberFormatter(product.price.offer, 'Rp')}
                  </div>
                  {
                    product.price.original > product.price.offer && (
                      <div className="text-gray-multi-3 text-sm line-through">
                        {numberFormatter(product.price.original, 'Rp')}
                      </div>
                    )
                  }

                  <div className="font-semibold mt-5">
                    About Product
                  </div>
                  <div
                    dangerouslySetInnerHTML={{ __html: product.description }}
                    className="mt-2 mb-8"
                  />

                  <section className="w-10/12 grid grid-cols-3 justify-between text-sm">
                    <Line className="col-span-3" />
                    <div>
                      JAN Code
                    </div>
                    <div className="col-span-2">
                      {product.janCode}
                    </div>

                    <Line className="col-span-3" />
                    <div>
                      Brand
                    </div>
                    <div className="col-span-2">
                      {product.brand?.name}
                    </div>

                    <Line className="col-span-3" />
                    <div>
                      Category
                    </div>
                    <div className="col-span-2">
                      {product.category?.name}
                    </div>

                    <Line className="col-span-3" />
                    <div>
                      Series
                    </div>
                    <div className="col-span-2">
                      {product.series?.name}
                    </div>

                    <Line className="col-span-3" />
                    <div>
                      Character
                    </div>
                    <div className="col-span-2">
                      {product.character?.name}
                    </div>
                  </section>
                </div>

                <div className="sticky top-24 w-[25%] flex flex-col border border-gray-multi p-3">
                  <div className="font-semibold text-lg">
                    Order Now
                  </div>

                  {
                    product.estimatedArrivalDate && (
                      <div className="text-sm text-gray-multi mt-2">
                        {getReleaseDate()}
                      </div>
                    )
                  }

                  <div className="flex items-center my-8">
                    <ProductQuantityCounter
                      quantityState={[qty, setQty]}
                      maxQuantityPerOrder={product.maxQuantityPerOrder}
                      stock={product.stock}
                    />
                  </div>

                  {
                    product.endDate && (
                      <div className="font-semibold text-sm mb-2.5 flex">
                        <BiInfoCircle className="mr-1 text-lg" />
                        {getTickerText()}
                      </div>
                    )
                  }

                  <button
                    type="button"
                    className={classNames({
                      'button-primary': true,
                      'disabled': isOutOfStock() || isHidden()
                    })}
                    disabled={isOutOfStock() || isHidden()}
                    onClick={toCheckoutPage}
                  >
                    Buy Now
                  </button>

                  <button
                    type="button"
                    className={classNames({
                      'button-primary dark my-3': true,
                      'disabled': isOutOfStock() || isHidden()
                    })}
                    disabled={isOutOfStock() || isHidden()}
                    onClick={doAddToCart}
                  >
                    Add to Cart
                  </button>

                  {wishlistButton()}
                </div>
              </div>
            </>
          )
        }
      </section>
    </section>
  )

  return (
    <div className="flex flex-col min-h-screen">

      {
        isDesktop ? (
          <GeneralHeader />
        ) : (
          <BaseHeader>
            <button
              type="button"
              onClick={() => navigate(-1)}
            >
              <FaChevronLeft className="text-lg" />
            </button>
          </BaseHeader>
        )
      }

      {isDesktop ? desktopContent() : mobileContent()}

      <Footer />
    </div>
  )
}

export default ProductDetailPage