import React from 'react'
import useResponsive from '@/hooks/responsive'

import TitleHeader from '@/components/TitleHeader'
import GeneralHeader from '@/components/GeneralHeader'
import Footer from '@/components/Footer'

const TermsConditionPage = () => {
  const { isDesktop } = useResponsive()

  return (
    <div className="flex flex-col h-screen">
      {
        isDesktop ? (
          <GeneralHeader />
        ) : (
          <TitleHeader title="Terms and Conditions"/>
        )
      }

      <section className="bg-white-multi grow p-5 relative lg:py-8 flex justify-center">
        <section className="flex flex-col w-full text-sm lg:text-base lg:w-[90%] 2xl:w-[70%] lg:items-start">
          {
            isDesktop && (
              <div className="text-xl font-semibold mb-2">
                Terms and Conditions
              </div>
            )
          }

          <div>
            <div className="font-semibold">
              1. Acceptance of Terms
            </div>

            <p className="mb-2">
              By accessing or using Multitoys,id ("the Website"), you agree to comply with and be bound by these terms and conditions of use ("Terms"). We are registered in Indonesia and have our registered office at JL. Boulvard arta gading komplek ruko gading bukit indah Blok P19/20. If you do not agree with any part of these Terms, please do not use the Website and discontinue immediately.
              <br /> Your use of the Website is contingent upon your compliance with these Terms. You accept responsibility for all activities that occur under your account or password, and you agree not to share your account credentials or allow others to access the Website using your account information.
              <br /> If you are under the age of 13, you may only use the Website with the involvement and consent of a parent or guardian. By using the Website, you affirm that you have obtained the necessary parental or guardian consent.
            </p>

            <div className="font-semibold">
              2. Privacy Policy
            </div>

            <p className="mb-2">
              Your use of the Website is also governed by our Privacy Policy, which can be found on the privacy policy section.
            </p>

            <div className="font-semibold">
              3. Online Shopping
            </div>

            <p className="mb-2">
              a. Product Information: We strive to provide accurate and up-to-date information about our products. However, we do not guarantee the accuracy, completeness, or reliability of any product descriptions, pricing, or other content on the Website.
              <br /> b. Order Acceptance: All orders placed through the Website are subject to our acceptance. We reserve the right to refuse or cancel any order for any reason, including but not limited to product availability, errors in pricing, or suspected fraud.
              <br /> c. Pricing: Prices for products are subject to change without notice. All prices are in Rupiah (IDR) and are exclusive of applicable taxes and shipping fees.
            </p>

            <div className="font-semibold">
              4. Payment and Billing Information
            </div>

            <p className="mb-2">
              a. Payment Methods: We accept Bank (BRI, Mandiri, BNI, Permata, BCA, CIMB Niaga, Bjb, & BSI), eWallets(OVO, DANA,& AstraPay)& QR Codes(QRIS) . By providing payment information, you represent and warrant that you have the legal right to use any credit card or other payment method used in connection with any transaction.
              <br /> b. Billing Information: You agree to provide current, complete, and accurate billing and account information. Changes to billing information should be promptly updated on the Website.
            </p>

            <div className="font-semibold">
              5. Shipping and Delivery
            </div>

            <p className="mb-2">
              a. Shipping Policy: Information about our shipping and delivery practices can be found in our Shipping Policy tab.
              <br /> b. Delivery Dates: While we make every effort to meet estimated delivery dates, delays may occur due to factors beyond our control. We are not responsible for any delays in delivery.
            </p>

            <div className="font-semibold">
              6. Returns and Refunds
            </div>

            <p className="mb-2">
              a. Return Policy: Details about our return and refund policies can be found in our Return Policy, available in our Return Policy section.
              <br /> b. Return Authorization: Prior authorization is required for all returns. Please refer to our Return Policy for instructions on how to initiate a return.
            </p>

            <div className="font-semibold">
              7. Intellectual Property
            </div>

            <p className="mb-2">
              a. Copyright: All content on the Website, including text, graphics, logos, images, and software, is the property of Pt. Multi Sarana Prima Nusantara and is protected by copyright laws.
              <br /> b. Trademarks: All trademarks, service marks, and trade names used on the Website are the property of Pt. Multi Sarana Prima Nusantara or their respective owners.
            </p>

            <div className="font-semibold">
              8. Limitation of Liability
            </div>

            <p className="mb-2">
              In no event shall PT. Multi Sarana Prima Nusantara be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in any way connected with the use of the Website or the products purchased through the Website.
            </p>

            <div className="font-semibold">
              9. Changes to Terms and Conditions
            </div>

            <p className="mb-2">
              We reserve the right to modify or replace these Terms at any time. It is your responsibility to check these Terms periodically for changes. Your continued use of the Website after the posting of any changes constitutes acceptance of those changes.
            </p>
          </div>

          <div className="text-xl font-semibold mt-4 mb-2">
            Privacy Policy
          </div>

          <div>
            <div className="font-semibold">
              User Information
            </div>
            <p className="mb-2">
              By using the Multitoys.id website, customers agree to the use of data to improve the process and operations of using the Multitoys.id site. With user information, customers can process orders for the desired products and Multi Toys can fulfill obligations according to customer requests.
              When a customer registers on the Multitoys.id website, Multi Toys will ask for some user information such as full name, e-mail address and date of birth. Customers with full awareness provide this information without any coercion from Multi Toys.
              When a customer contacts Multi Toys, data such as telephone number or email will be stored by Multi Toys to provide assistance required by the customer. Multi Toys will not be responsible if the password or user ID is lost.
            </p>

            <div className="font-semibold">
              Use of Information
            </div>
            <p className="mb-2">
              All user information/data will only be used by Multi Toys for the purpose of improving products and services as well as developing Multi Toys' internal systems. All external promotions will be based on data information obtained from customer personal data.
            </p>

            <div className="font-semibold">
              Deletion of User Data
            </div>
            <p className="mb-2">
              Multi Toys will only delete customer data if the following conditions are met:
              <div>
                (i) Customer personal data is no longer active
              </div>
              <div>
                (ii) Adjustment of data in accordance with legislation
              </div>
              <div>
                (iii) The customer's personal data is no longer used
              </div>
              Users can request data deletion at any time by sending an email to admin web@multitoys.id
            </p>
          </div>

          <div className="text-xl font-semibold mt-4 mb-2">
            Refund Policy
          </div>

          <div>
            <div className="font-semibold">
              Return:
            </div>
            <p className="mb-2">
              The goods you have purchased will be sent by Multi Toys according to the address you have provided via the multitoys.id website after payment has been confirmed, however, Multi Toys cannot guarantee that the goods will arrive on time due to operational logistics constraints. Therefore, Multi Toys uses an estimate of the quarter the goods will arrive and be sent to you
              <br /> Multi Toys will provide updates regarding the status of the goods, but Multi Toys will not be responsible for material losses that occur to your order during the delivery process or delays in delivery.
              <br /> Multi Toys can provide special packaging with additional information that can be added by the customer in the note column before the order is ordered by you.
              <br /> Multi Toys will not provide any refunds unless the goods you have ordered cannot be fulfilled by Multi Toys because the goods you ordered are not available or have run out.
            </p>

            <div className="font-semibold">
              Goods Return Procedure:
            </div>

            <p>
              <div>
                Some conditions for returning items that can be returned are as follows:
              </div>
              <div>
                - the goods purchased do not match the order
              </div>
              <div>
                - the goods are seriously damaged
              </div>
              <div>
                If the item meets one of the conditions above then you can follow the following steps:
              </div>
              <div>
                A. Contact the WhatsApp contact at the bottom of the multitoys.id website within 7 days after receiving the goods
              </div>
              <div>
                B. Admin will provide a form for items to be returned
              </div>
              <div>
                C. Fill in the form according to the Order ID, ID Name, reason for return, attached photo of evidence of damage/non-conformity with the order
              </div>
              <div>
                D. When the form has been analyzed by Multi Toys and it is indicated that the reason is valid, the admin will contact the customer to send the product to the address indicated no later than 7 days from the admin's confirmation.
              </div>
              <div>
                E. The refund of shipping costs that can be returned by Multi Toys is IDR 20,000
              </div>
            </p>
          </div>
        </section>
      </section>

      {
        isDesktop && <Footer />
      }
    </div>
  )
}

export default TermsConditionPage