import React, { useState } from 'react'
import { FaSearch, FaChevronDown, FaTimes } from 'react-icons/fa'
import PropTypes from 'prop-types'
import { InView } from 'react-intersection-observer'
import classNames from 'classnames'
import { motion, AnimatePresence } from 'framer-motion'

const Loader = () => (
  <>
    <div className="animate-pulse my-4 h-4 w-full rounded-xl bg-gray-multi-2" />
    <div className="animate-pulse my-4 h-4 w-full rounded-xl bg-gray-multi-2" />
    <div className="animate-pulse my-4 h-4 w-full rounded-xl bg-gray-multi-2" />
  </>
)

const Dropdown = ({
  name,
  items,
  emptyMessage,
  searchState = [],
  isLoading,
  onIntersecting,
  onDropdownOpen,
  onItemChange,
  error,
  value,
  disabled,
  disposable
}) => {
  const [visibleDropdown, setVisibleDropdown] = useState(false)
  const [search, setSearch] = searchState

  const handleSearch = e => {
    setSearch(e.target.value)
  }

  const openDropdown = () => {
    onDropdownOpen && onDropdownOpen()
    setVisibleDropdown(true)
  }

  const selectItem = item => {
    onItemChange && onItemChange(item)
    setVisibleDropdown(false)
  }

  const getItems = () => items.map((item, i) => (
    <motion.div
      key={i}
      className="py-2 border-black-multi cursor-pointer"
      style={{ borderBottomWidth: '1px' }}
      onClick={() => selectItem(item)}
      animate={{
        y: 0,
        opacity: 1
      }}
      initial={{
        y: '-20%',
        opacity: 1
      }}
      exit={{
        y: '-10%',
        opacity: 0,
        transition: {
          duration: 0.1
        }
      }}
      transition={{
        duration: 0.2
      }}
    >
      {item.value} 
    </motion.div>
  ))

  const clearSelectedItem = e => {
    e && e.stopPropagation()
    onItemChange && onItemChange({})
  }

  return (
    <div className="relative">
      <button
        type="button"
        className={classNames({
          'p-3 cursor-pointer text-left w-full border border-black-multi flex items-center': true,
          'bg-white': !disabled,
          'border-red-500': !!error,
          'brightness-95 cursor-auto': disabled
        })}
        onClick={openDropdown}
        disabled={disabled}
      >
        {
          disposable && value?.value && (
            <FaTimes
              className="text-black-multi mr-1.5"
              onClick={e => clearSelectedItem(e)}
            />
          )
        }

        {
          !value?.value && (
            <div className="text-gray-400 text-sm">
              {name}
            </div>
          )
        }
        {
          !!value?.value && (
            <div>
              {value.value}
            </div>
          )
        }

        <FaChevronDown className="ml-auto text-black-multi" />
      </button>

      <AnimatePresence mode="wait">
        {
          visibleDropdown && (
            <>
              <div
                onClick={() => setVisibleDropdown(false)}
                className="fixed top-0 left-0 h-screen w-screen z-10"
              />

              <motion.div
                className="absolute left-0 mt-2 max-h-60 overflow-y-auto shadow-lg rounded-xl w-full bg-white-multi z-20 scrollbar-thin scrollbar-thumb-gray-400 scrollbar-track-gray-100"
                animate={{
                  y: 0,
                  opacity: 1,
                }}
                initial={{
                  y: '-20%',
                  opacity: 1
                }}
                exit={{
                  y: '-10%',
                  opacity: 0,
                  transition: {
                    duration: 0.1,
                    delay: 0.15
                  }
                }}
                transition={{
                  duration: 0.2
                }}
              >
                {
                  !!searchState.length && (
                    <div className="my-2 mx-4 p-2 mb-2 border border-gray-300 rounded-lg flex items-center sticky top-0 left-0 bg-white">
                      <FaSearch className="text-gray-400 mr-1.5" />
                      <input
                        type="text"
                        className="w-11/12 text-sm outline-none"
                        onChange={handleSearch}
                        value={search || ''}
                      />
                    </div>
                  )
                }

                <div className="py-3 px-4">
                  {
                    !!items.length && (
                      <>
                        {getItems()}
                        <InView as="div" onChange={inView => inView && onIntersecting && onIntersecting()}>
                          <div />
                        </InView>
                      </>
                    )
                  }
                  {
                    isLoading && <Loader />
                  }
                  {
                    !items.length && !isLoading && (
                      <div className="text-center my-4">
                        {emptyMessage}
                      </div>
                    )
                  }
                </div>
              </motion.div>
            </>
          )
        }
      </AnimatePresence>
    </div>
  )
}

Dropdown.propTypes = {
  name: PropTypes.string,
  items: PropTypes.array,
  emptyMessage: PropTypes.string,
  searchState: PropTypes.array,
  isLoading: PropTypes.bool,
  onDropdownOpen: PropTypes.func,
  onItemChange: PropTypes.func,
  error: PropTypes.bool,
  value: PropTypes.object,
  disabled: PropTypes.bool,
  disposable: PropTypes.bool
}

export default Dropdown